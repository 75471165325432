import { useTranslation } from "react-i18next";

import { Card, HorizontalLine } from "@vapaus/ui-v2";

import { useGetNotificationPreferences } from "../../../api/notificationPreferences";
import { NotificationSwitch } from "./NotificationSwitch";

export function AccountNotificationsCard() {
  const { t } = useTranslation();
  const { data: notificationPreferences } = useGetNotificationPreferences();

  const currentOptedOutCategories = (notificationPreferences ?? [])
    .filter((category) => !category.optedIn)
    .map((category) => category.notificationCategory);

  if (!notificationPreferences) return null;

  return (
    <Card
      title={t("admin:settingsPage.notifications.title")}
      subtitle={t("admin:settingsPage.notifications.subtitle")}
    >
      <NotificationSwitch
        title={t("admin:settingsPage.notifications.accessRequest.title")}
        description={t(
          "admin:settingsPage.notifications.accessRequest.description",
        )}
        category="access_requests"
        currentOptedOutCategories={currentOptedOutCategories}
      />
      <HorizontalLine marginBottom="lg" marginTop="lg" />
      <NotificationSwitch
        title={t("admin:settingsPage.notifications.contractUpdates.title")}
        description={t(
          "admin:settingsPage.notifications.contractUpdates.description",
        )}
        category="contract_updates"
        currentOptedOutCategories={currentOptedOutCategories}
      />
    </Card>
  );
}
