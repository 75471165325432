import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { BikeBenefitContractState } from "@vapaus/api-codegen";
import { Tag, TagProps, TagVariant } from "@vapaus/ui-v2";

export interface ContractStatusProps extends Pick<TagProps, "size"> {
  status: BikeBenefitContractState;
}

export function ContractStatus({ status, size }: ContractStatusProps) {
  const { t } = useTranslation();
  const { variant, text } = mapStatusToTagProps(status, t);
  if (!variant || !text) {
    return null;
  }
  return <Tag variant={variant} text={text} size={size} />;
}

function mapStatusToTagProps(
  status: BikeBenefitContractState,
  t: TFunction<"translation">,
): { variant: TagVariant; text: string } {
  switch (status) {
    case "active":
      return {
        variant: "accepted",
        text: t("admin:home.contracts.status.active"),
      };
    case "cancelled":
      return {
        variant: "cancelled",
        text: t("admin:home.contracts.status.cancelled"),
      };
    case "ended":
      return {
        variant: "done",
        text: t("admin:home.contracts.status.ended"),
      };
    case "invalidated":
      return {
        variant: "neutral",
        text: t("admin:home.contracts.status.invalidated"),
      };
    case "scheduled":
      return {
        variant: "pending",
        text: t("admin:home.contracts.status.scheduled"),
      };
    case "active_pending_revision":
      return {
        variant: "pending",
        text: t("admin:home.contracts.status.activePendingRevision"),
      };
    case "active_pending_correction":
      return {
        variant: "pending",
        text: t("admin:home.contracts.status.activePendingCorrection"),
      };
    case "active_pending_redemption":
      return {
        variant: "pending",
        text: t("admin:home.contracts.status.activePendingRedemption"),
      };
    case "active_pending_return":
      return {
        variant: "pending",
        text: t("admin:home.contracts.status.activePendingReturn"),
      };
    case "cancelled_pending_payment":
      return {
        variant: "cancelled",
        text: t("admin:home.contracts.status.cancelledPendingPayment"),
      };
    case "ended_pending_payment":
      return {
        variant: "cancelled",
        text: t("admin:home.contracts.status.endedPendingPayment"),
      };
  }
}
