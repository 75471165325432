import { now } from "lodash-es";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { getDateDifferenceInDays } from "@vapaus/common-v2";
import { Flex, Separator } from "@vapaus/ui-v2";

import { DAYS_TO_ACCEPT_REQUEST } from "../../../../../../constants";
import { CommonRows } from "./components/CommonRows";
import {
  RequestModal,
  RequestModalCommonProps,
} from "./components/RequestModal";

export function RequestExpiredModal({
  isOpen,
  onClose,
  request,
}: RequestModalCommonProps) {
  const { t } = useTranslation();

  if (!request || !request.createdAt) {
    return null;
  }

  const expiredAt = DateTime.fromJSDate(request.createdAt)
    .plus({ days: DAYS_TO_ACCEPT_REQUEST })
    .toJSDate();
  const daysAgo = getDateDifferenceInDays(expiredAt, new Date(now()));
  const description = t("admin:home.requests.expiredModal.description", {
    daysAgo,
  });

  return (
    <RequestModal
      isOpen={isOpen}
      onClose={onClose}
      request={request}
      description={description}
    >
      <Separator />
      <Flex fullWidth direction="column">
        <CommonRows request={request} />
      </Flex>
    </RequestModal>
  );
}
