/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Accessory } from "./Accessory";
import {
  AccessoryFromJSON,
  AccessoryFromJSONTyped,
  AccessoryToJSON,
} from "./Accessory";
import type { AdditionalService } from "./AdditionalService";
import {
  AdditionalServiceFromJSON,
  AdditionalServiceFromJSONTyped,
  AdditionalServiceToJSON,
} from "./AdditionalService";
import type { BenefitCancellationCoverage } from "./BenefitCancellationCoverage";
import {
  BenefitCancellationCoverageFromJSON,
  BenefitCancellationCoverageFromJSONTyped,
  BenefitCancellationCoverageToJSON,
} from "./BenefitCancellationCoverage";
import type { BenefitCancellationCoverageStatus } from "./BenefitCancellationCoverageStatus";
import {
  BenefitCancellationCoverageStatusFromJSON,
  BenefitCancellationCoverageStatusFromJSONTyped,
  BenefitCancellationCoverageStatusToJSON,
} from "./BenefitCancellationCoverageStatus";
import type { BikeAdminRead } from "./BikeAdminRead";
import {
  BikeAdminReadFromJSON,
  BikeAdminReadFromJSONTyped,
  BikeAdminReadToJSON,
} from "./BikeAdminRead";
import type { BikeBenefitContractCancellationReason } from "./BikeBenefitContractCancellationReason";
import {
  BikeBenefitContractCancellationReasonFromJSON,
  BikeBenefitContractCancellationReasonFromJSONTyped,
  BikeBenefitContractCancellationReasonToJSON,
} from "./BikeBenefitContractCancellationReason";
import type { BikeBenefitContractState } from "./BikeBenefitContractState";
import {
  BikeBenefitContractStateFromJSON,
  BikeBenefitContractStateFromJSONTyped,
  BikeBenefitContractStateToJSON,
} from "./BikeBenefitContractState";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { FinancingMethods } from "./FinancingMethods";
import {
  FinancingMethodsFromJSON,
  FinancingMethodsFromJSONTyped,
  FinancingMethodsToJSON,
} from "./FinancingMethods";
import type { FundingSource } from "./FundingSource";
import {
  FundingSourceFromJSON,
  FundingSourceFromJSONTyped,
  FundingSourceToJSON,
} from "./FundingSource";
import type { Notification } from "./Notification";
import {
  NotificationFromJSON,
  NotificationFromJSONTyped,
  NotificationToJSON,
} from "./Notification";
import type { Transport } from "./Transport";
import {
  TransportFromJSON,
  TransportFromJSONTyped,
  TransportToJSON,
} from "./Transport";
import type { UserRead } from "./UserRead";
import {
  UserReadFromJSON,
  UserReadFromJSONTyped,
  UserReadToJSON,
} from "./UserRead";

/**
 *
 * @export
 * @interface BikeBenefitContractAdminRead
 */
export interface BikeBenefitContractAdminRead {
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  deliveryDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  endDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  exceptionalEndDate?: Date;
  /**
   *
   * @type {BikeBenefitContractCancellationReason}
   * @memberof BikeBenefitContractAdminRead
   */
  cancellationReason?: BikeBenefitContractCancellationReason;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  cancellationReasonDescription?: string;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitContractAdminRead
   */
  isInvalidated?: boolean;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  bikeBenefitOrderId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  benefitDefinitionTermsId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  bikeId: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  bikePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeBenefitContractAdminRead
   */
  currency: Currency;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  insuranceFee: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  fixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  totalPackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  taxablePackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  monthlyLeasingFee: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  monthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  taxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  flatVatRate: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  residualValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  downPaymentAmount: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  extendedWarranty: number;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitContractAdminRead
   */
  isDownPaymentInsured: boolean;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  yearlyDownPaymentInsuranceFee?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  redemptionPercentage: number;
  /**
   *
   * @type {BenefitCancellationCoverage}
   * @memberof BikeBenefitContractAdminRead
   */
  cancellationCoverage?: BenefitCancellationCoverage;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  notes?: string;
  /**
   *
   * @type {FinancingMethods}
   * @memberof BikeBenefitContractAdminRead
   */
  financingMethod?: FinancingMethods;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  financierSellingPrice?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  tukirahoitusMonthlyLeasingPrice?: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  tukirahoitusInterestRatesId?: string;
  /**
   *
   * @type {FundingSource}
   * @memberof BikeBenefitContractAdminRead
   */
  fundingSource?: FundingSource;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitContractAdminRead
   */
  isEndedNotificationSent?: boolean;
  /**
   *
   * @type {Array<Notification>}
   * @memberof BikeBenefitContractAdminRead
   */
  sentNotifications?: Array<Notification>;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  leasingPeriodStartDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  effectiveEndDate: Date;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  taxablePackagePriceReduction: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  totalMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  spentMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  remainingMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  residualRatio: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  discountedResidualValue: number;
  /**
   *
   * @type {BikeAdminRead}
   * @memberof BikeBenefitContractAdminRead
   */
  bike: BikeAdminRead;
  /**
   *
   * @type {Array<Accessory>}
   * @memberof BikeBenefitContractAdminRead
   */
  accessories: Array<Accessory>;
  /**
   *
   * @type {BikeBenefitContractState}
   * @memberof BikeBenefitContractAdminRead
   */
  state: BikeBenefitContractState;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  vapausCode: string;
  /**
   *
   * @type {Array<AdditionalService>}
   * @memberof BikeBenefitContractAdminRead
   */
  additionalServices: Array<AdditionalService>;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractAdminRead
   */
  updatedAt: Date;
  /**
   *
   * @type {Array<Transport>}
   * @memberof BikeBenefitContractAdminRead
   */
  transports: Array<Transport>;
  /**
   *
   * @type {Transport}
   * @memberof BikeBenefitContractAdminRead
   */
  deliveryTransport?: Transport;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitContractAdminRead
   */
  correctedByBikeBenefitOrderIds: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitContractAdminRead
   */
  revisedByBikeBenefitOrderIds: Array<string>;
  /**
   *
   * @type {BenefitCancellationCoverageStatus}
   * @memberof BikeBenefitContractAdminRead
   */
  cancellationCoverageStatus: BenefitCancellationCoverageStatus;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractAdminRead
   */
  remainingLeasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  currentRedemptionProcessId?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractAdminRead
   */
  currentReturnProcessId?: string;
  /**
   *
   * @type {UserRead}
   * @memberof BikeBenefitContractAdminRead
   */
  user: UserRead;
}

/**
 * Check if a given object implements the BikeBenefitContractAdminRead interface.
 */
export function instanceOfBikeBenefitContractAdminRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "deliveryDate" in value;
  isInstance = isInstance && "startDate" in value;
  isInstance = isInstance && "endDate" in value;
  isInstance = isInstance && "leasingPeriodMonths" in value;
  isInstance = isInstance && "userId" in value;
  isInstance = isInstance && "bikeBenefitOrderId" in value;
  isInstance = isInstance && "benefitDefinitionId" in value;
  isInstance = isInstance && "benefitDefinitionTermsId" in value;
  isInstance = isInstance && "bikeId" in value;
  isInstance = isInstance && "bikePrice" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "insuranceFee" in value;
  isInstance = isInstance && "monthlyMaintenanceBudget" in value;
  isInstance = isInstance && "totalPackagePrice" in value;
  isInstance = isInstance && "taxablePackagePrice" in value;
  isInstance = isInstance && "monthlyLeasingFee" in value;
  isInstance = isInstance && "monthlyTaxableValue" in value;
  isInstance = isInstance && "taxableValue" in value;
  isInstance = isInstance && "flatVatRate" in value;
  isInstance = isInstance && "residualValue" in value;
  isInstance = isInstance && "downPaymentAmount" in value;
  isInstance = isInstance && "extendedWarranty" in value;
  isInstance = isInstance && "isDownPaymentInsured" in value;
  isInstance = isInstance && "redemptionPercentage" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "leasingPeriodStartDate" in value;
  isInstance = isInstance && "effectiveEndDate" in value;
  isInstance = isInstance && "taxablePackagePriceReduction" in value;
  isInstance = isInstance && "totalMaintenanceBudget" in value;
  isInstance = isInstance && "spentMaintenanceBudget" in value;
  isInstance = isInstance && "remainingMaintenanceBudget" in value;
  isInstance = isInstance && "residualRatio" in value;
  isInstance = isInstance && "discountedResidualValue" in value;
  isInstance = isInstance && "bike" in value;
  isInstance = isInstance && "accessories" in value;
  isInstance = isInstance && "state" in value;
  isInstance = isInstance && "vapausCode" in value;
  isInstance = isInstance && "additionalServices" in value;
  isInstance = isInstance && "createdAt" in value;
  isInstance = isInstance && "updatedAt" in value;
  isInstance = isInstance && "transports" in value;
  isInstance = isInstance && "correctedByBikeBenefitOrderIds" in value;
  isInstance = isInstance && "revisedByBikeBenefitOrderIds" in value;
  isInstance = isInstance && "cancellationCoverageStatus" in value;
  isInstance = isInstance && "remainingLeasingPeriodMonths" in value;
  isInstance = isInstance && "user" in value;

  return isInstance;
}

export function BikeBenefitContractAdminReadFromJSON(
  json: any,
): BikeBenefitContractAdminRead {
  return BikeBenefitContractAdminReadFromJSONTyped(json, false);
}

export function BikeBenefitContractAdminReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitContractAdminRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    deliveryDate: new Date(json["delivery_date"]),
    startDate: new Date(json["start_date"]),
    endDate: new Date(json["end_date"]),
    exceptionalEndDate: !exists(json, "exceptional_end_date")
      ? undefined
      : new Date(json["exceptional_end_date"]),
    cancellationReason: !exists(json, "cancellation_reason")
      ? undefined
      : BikeBenefitContractCancellationReasonFromJSON(
          json["cancellation_reason"],
        ),
    cancellationReasonDescription: !exists(
      json,
      "cancellation_reason_description",
    )
      ? undefined
      : json["cancellation_reason_description"],
    isInvalidated: !exists(json, "is_invalidated")
      ? undefined
      : json["is_invalidated"],
    leasingPeriodMonths: json["leasing_period_months"],
    userId: json["user_id"],
    bikeBenefitOrderId: json["bike_benefit_order_id"],
    benefitDefinitionId: json["benefit_definition_id"],
    benefitDefinitionTermsId: json["benefit_definition_terms_id"],
    bikeId: json["bike_id"],
    bikePrice: json["bike_price"],
    currency: CurrencyFromJSON(json["currency"]),
    insuranceFee: json["insurance_fee"],
    fixedMonthlyTaxableValue: !exists(json, "fixed_monthly_taxable_value")
      ? undefined
      : json["fixed_monthly_taxable_value"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    totalPackagePrice: json["total_package_price"],
    taxablePackagePrice: json["taxable_package_price"],
    monthlyLeasingFee: json["monthly_leasing_fee"],
    monthlyTaxableValue: json["monthly_taxable_value"],
    taxableValue: json["taxable_value"],
    flatVatRate: json["flat_vat_rate"],
    residualValue: json["residual_value"],
    downPaymentAmount: json["down_payment_amount"],
    extendedWarranty: json["extended_warranty"],
    isDownPaymentInsured: json["is_down_payment_insured"],
    yearlyDownPaymentInsuranceFee: !exists(
      json,
      "yearly_down_payment_insurance_fee",
    )
      ? undefined
      : json["yearly_down_payment_insurance_fee"],
    redemptionPercentage: json["redemption_percentage"],
    cancellationCoverage: !exists(json, "cancellation_coverage")
      ? undefined
      : BenefitCancellationCoverageFromJSON(json["cancellation_coverage"]),
    notes: !exists(json, "notes") ? undefined : json["notes"],
    financingMethod: !exists(json, "financing_method")
      ? undefined
      : FinancingMethodsFromJSON(json["financing_method"]),
    financierSellingPrice: !exists(json, "financier_selling_price")
      ? undefined
      : json["financier_selling_price"],
    tukirahoitusMonthlyLeasingPrice: !exists(
      json,
      "tukirahoitus_monthly_leasing_price",
    )
      ? undefined
      : json["tukirahoitus_monthly_leasing_price"],
    tukirahoitusInterestRatesId: !exists(json, "tukirahoitus_interest_rates_id")
      ? undefined
      : json["tukirahoitus_interest_rates_id"],
    fundingSource: !exists(json, "funding_source")
      ? undefined
      : FundingSourceFromJSON(json["funding_source"]),
    isEndedNotificationSent: !exists(json, "is_ended_notification_sent")
      ? undefined
      : json["is_ended_notification_sent"],
    sentNotifications: !exists(json, "sent_notifications")
      ? undefined
      : (json["sent_notifications"] as Array<any>).map(NotificationFromJSON),
    id: json["id"],
    leasingPeriodStartDate: new Date(json["leasing_period_start_date"]),
    effectiveEndDate: new Date(json["effective_end_date"]),
    taxablePackagePriceReduction: json["taxable_package_price_reduction"],
    totalMaintenanceBudget: json["total_maintenance_budget"],
    spentMaintenanceBudget: json["spent_maintenance_budget"],
    remainingMaintenanceBudget: json["remaining_maintenance_budget"],
    residualRatio: json["residual_ratio"],
    discountedResidualValue: json["discounted_residual_value"],
    bike: BikeAdminReadFromJSON(json["bike"]),
    accessories: (json["accessories"] as Array<any>).map(AccessoryFromJSON),
    state: BikeBenefitContractStateFromJSON(json["state"]),
    vapausCode: json["vapaus_code"],
    additionalServices: (json["additional_services"] as Array<any>).map(
      AdditionalServiceFromJSON,
    ),
    createdAt: new Date(json["created_at"]),
    updatedAt: new Date(json["updated_at"]),
    transports: (json["transports"] as Array<any>).map(TransportFromJSON),
    deliveryTransport: !exists(json, "delivery_transport")
      ? undefined
      : TransportFromJSON(json["delivery_transport"]),
    correctedByBikeBenefitOrderIds: json["corrected_by_bike_benefit_order_ids"],
    revisedByBikeBenefitOrderIds: json["revised_by_bike_benefit_order_ids"],
    cancellationCoverageStatus: BenefitCancellationCoverageStatusFromJSON(
      json["cancellation_coverage_status"],
    ),
    remainingLeasingPeriodMonths: json["remaining_leasing_period_months"],
    currentRedemptionProcessId: !exists(json, "current_redemption_process_id")
      ? undefined
      : json["current_redemption_process_id"],
    currentReturnProcessId: !exists(json, "current_return_process_id")
      ? undefined
      : json["current_return_process_id"],
    user: UserReadFromJSON(json["user"]),
  };
}

export function BikeBenefitContractAdminReadToJSON(
  value?: BikeBenefitContractAdminRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    delivery_date: value.deliveryDate.toISOString().substring(0, 10),
    start_date: value.startDate.toISOString().substring(0, 10),
    end_date: value.endDate.toISOString().substring(0, 10),
    exceptional_end_date:
      value.exceptionalEndDate === undefined
        ? undefined
        : value.exceptionalEndDate.toISOString().substring(0, 10),
    cancellation_reason: BikeBenefitContractCancellationReasonToJSON(
      value.cancellationReason,
    ),
    cancellation_reason_description: value.cancellationReasonDescription,
    is_invalidated: value.isInvalidated,
    leasing_period_months: value.leasingPeriodMonths,
    user_id: value.userId,
    bike_benefit_order_id: value.bikeBenefitOrderId,
    benefit_definition_id: value.benefitDefinitionId,
    benefit_definition_terms_id: value.benefitDefinitionTermsId,
    bike_id: value.bikeId,
    bike_price: value.bikePrice,
    currency: CurrencyToJSON(value.currency),
    insurance_fee: value.insuranceFee,
    fixed_monthly_taxable_value: value.fixedMonthlyTaxableValue,
    monthly_maintenance_budget: value.monthlyMaintenanceBudget,
    total_package_price: value.totalPackagePrice,
    taxable_package_price: value.taxablePackagePrice,
    monthly_leasing_fee: value.monthlyLeasingFee,
    monthly_taxable_value: value.monthlyTaxableValue,
    taxable_value: value.taxableValue,
    flat_vat_rate: value.flatVatRate,
    residual_value: value.residualValue,
    down_payment_amount: value.downPaymentAmount,
    extended_warranty: value.extendedWarranty,
    is_down_payment_insured: value.isDownPaymentInsured,
    yearly_down_payment_insurance_fee: value.yearlyDownPaymentInsuranceFee,
    redemption_percentage: value.redemptionPercentage,
    cancellation_coverage: BenefitCancellationCoverageToJSON(
      value.cancellationCoverage,
    ),
    notes: value.notes,
    financing_method: FinancingMethodsToJSON(value.financingMethod),
    financier_selling_price: value.financierSellingPrice,
    tukirahoitus_monthly_leasing_price: value.tukirahoitusMonthlyLeasingPrice,
    tukirahoitus_interest_rates_id: value.tukirahoitusInterestRatesId,
    funding_source: FundingSourceToJSON(value.fundingSource),
    is_ended_notification_sent: value.isEndedNotificationSent,
    sent_notifications:
      value.sentNotifications === undefined
        ? undefined
        : (value.sentNotifications as Array<any>).map(NotificationToJSON),
    id: value.id,
    leasing_period_start_date: value.leasingPeriodStartDate
      .toISOString()
      .substring(0, 10),
    effective_end_date: value.effectiveEndDate.toISOString().substring(0, 10),
    taxable_package_price_reduction: value.taxablePackagePriceReduction,
    total_maintenance_budget: value.totalMaintenanceBudget,
    spent_maintenance_budget: value.spentMaintenanceBudget,
    remaining_maintenance_budget: value.remainingMaintenanceBudget,
    residual_ratio: value.residualRatio,
    discounted_residual_value: value.discountedResidualValue,
    bike: BikeAdminReadToJSON(value.bike),
    accessories: (value.accessories as Array<any>).map(AccessoryToJSON),
    state: BikeBenefitContractStateToJSON(value.state),
    vapaus_code: value.vapausCode,
    additional_services: (value.additionalServices as Array<any>).map(
      AdditionalServiceToJSON,
    ),
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt.toISOString(),
    transports: (value.transports as Array<any>).map(TransportToJSON),
    delivery_transport: TransportToJSON(value.deliveryTransport),
    corrected_by_bike_benefit_order_ids: value.correctedByBikeBenefitOrderIds,
    revised_by_bike_benefit_order_ids: value.revisedByBikeBenefitOrderIds,
    cancellation_coverage_status: BenefitCancellationCoverageStatusToJSON(
      value.cancellationCoverageStatus,
    ),
    remaining_leasing_period_months: value.remainingLeasingPeriodMonths,
    current_redemption_process_id: value.currentRedemptionProcessId,
    current_return_process_id: value.currentReturnProcessId,
    user: UserReadToJSON(value.user),
  };
}
