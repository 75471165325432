import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Card, Flex, Select, TabRow, Typography } from "@vapaus/ui-v2";

import { useGetBenefitDefinition } from "../../../../api/benefitDefinitions";
import { Path } from "../../../../constants/paths";
import { useOrganisationAndBenefitsContext } from "../../../../contexts/OrganisationAndBenefitsContext";
import { renderBenefitName } from "../../../../utils/renderBenefitName";
import { ContactUsButton } from "../ContactUsButton";

export function AdminSettingsBenefitsTab() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedOrganisation } = useOrganisationAndBenefitsContext();
  const { benefitId } = useParams();

  const { data: benefitDefinition } = useGetBenefitDefinition(benefitId);

  const benefitList = (selectedOrganisation?.benefits || []).map((benefit) => ({
    label: renderBenefitName(benefit.name, benefit.active, t),
    value: benefit.id,
  }));

  const handleSelectChange = (_: unknown, benefitId: string | null) =>
    navigate(benefitId + "/" + Path.adminBenefitsGuideline, {
      preventScrollReset: true,
    });

  useEffect(() => {
    if (selectedOrganisation?.benefits?.length === 1) {
      navigate(
        selectedOrganisation.benefits[0].id + "/" + Path.adminBenefitsGuideline,
      );
    }
  }, [selectedOrganisation, benefitId, navigate]);

  return (
    <Card
      title={t("admin:adminPage.benefitTab.title")}
      subtitle={t("admin:adminPage.benefitTab.description")}
    >
      <Flex direction="column">
        {benefitList.length > 1 ? (
          <Select
            label={t("admin:adminPage.benefitTab.selectLabel")}
            placeholder={t("admin:adminPage.benefitTab.selectPlaceholder")}
            onChange={handleSelectChange}
            options={benefitList}
            value={benefitId ?? ""}
          />
        ) : null}
        {benefitDefinition ? (
          <Flex direction="column" gap="lg">
            <StyledTabWrapper>
              <TabRow
                padding="none"
                justify="flex-start"
                labelTypoVariant="bodyMd"
                labelWeight="bold"
                border="primary.main"
                items={[
                  {
                    label: t("admin:adminPage.benefitTab.tabs.guideLine"),
                    to: benefitId + "/" + Path.adminBenefitsGuideline,
                  },
                  {
                    label: t("admin:adminPage.benefitTab.tabs.authentication"),
                    to: benefitId + "/" + Path.adminBenefitsAuthentication,
                  },
                ]}
              />
            </StyledTabWrapper>
            <Outlet context={{ benefitDefinition }} />
            <Flex direction="column" align="center" gap="xs">
              <Typography align="center">
                {t("admin:adminPage.benefitTab.footerMessage")}
              </Typography>
              <ContactUsButton />
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </Card>
  );
}

const StyledTabWrapper = styled.div`
  border-bottom: ${({ theme }) => theme.border.primary.light};
`;
