/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  RedemptionProcessRead,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  RedemptionProcessReadFromJSON,
  RedemptionProcessReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderRedemptionProcessesGetRedemptionProcessRequest {
  redemptionProcessId: string;
}

/**
 *
 */
export class ProviderRedemptionProcessesApi extends runtime.BaseAPI {
  /**
   * Get Redemption Process
   */
  async providerRedemptionProcessesGetRedemptionProcessRaw(
    requestParameters: ProviderRedemptionProcessesGetRedemptionProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RedemptionProcessRead>> {
    if (
      requestParameters.redemptionProcessId === null ||
      requestParameters.redemptionProcessId === undefined
    ) {
      throw new runtime.RequiredError(
        "redemptionProcessId",
        "Required parameter requestParameters.redemptionProcessId was null or undefined when calling providerRedemptionProcessesGetRedemptionProcess.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/redemption-processes/{redemption_process_id}`.replace(
          `{${"redemption_process_id"}}`,
          encodeURIComponent(String(requestParameters.redemptionProcessId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RedemptionProcessReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Redemption Process
   */
  async providerRedemptionProcessesGetRedemptionProcess(
    requestParameters: ProviderRedemptionProcessesGetRedemptionProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RedemptionProcessRead> {
    const response =
      await this.providerRedemptionProcessesGetRedemptionProcessRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
