import { useTranslation } from "react-i18next";

import { Form, Input } from "@vapaus/form";
import { Flex, Modal } from "@vapaus/ui-v2";

import { EmployeeViewContextProps } from "../../../../types/EmployeeViewContextProps";
import { useProfileModal } from "./useProfileModal";

type ProfileModalProps = EmployeeViewContextProps & {
  isOpen: boolean;
  organisationId: string;
  onClose: () => void;
};

export function ProfileModal({
  user,
  organisationId,
  isOpen,
  mepcoIntegrationSettings,
  isMepcoIntegrationEnabled,
  onClose,
}: ProfileModalProps) {
  const { t } = useTranslation();
  const { formMethods, mutation, handleReset } = useProfileModal({
    user,
    organisationId,
    mepcoIntegrationSettings,
    isMepcoIntegrationEnabled,
    onClose,
  });
  const handleCancel = () => {
    handleReset();
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onCancel={handleCancel}
      onClose={onClose}
      title={user.fullName}
      description={user.email}
      cancelText={t("commonV2:cancel")}
      confirmText={t("commonV2:save")}
      isSubmitting={mutation.isLoading}
      onConfirm={formMethods._handleSubmit}
      disableConfirm={!formMethods.formState.isDirty}
    >
      <Form formMethods={formMethods}>
        <Flex direction="column" fullWidth>
          <Input
            name="employeeNumber"
            label={t("admin:employeeViewPage.profile.employeeId")}
            placeholder={t("admin:employeeViewPage.profile.employeeId")}
          />
          <Input
            name="costCenter"
            label={t("admin:employeeViewPage.profile.costCenter")}
            placeholder={t("admin:employeeViewPage.profile.costCenter")}
          />
          {isMepcoIntegrationEnabled ? (
            <>
              <Input
                name="payGroupId"
                label={t("admin:mepcoFields.payGroupId")}
                placeholder={t("admin:mepcoFields.payGroupId")}
              />
              <Input
                name="payComponent"
                label={t("admin:mepcoFields.payComponent")}
                placeholder={t("admin:mepcoFields.payComponent")}
              />
            </>
          ) : null}
        </Flex>
      </Form>
    </Modal>
  );
}
