import { useTranslation } from "react-i18next";

import { BikeBenefitContractProviderRead } from "@vapaus/api-codegen";

type MonthsLeftCellProps = {
  contract: BikeBenefitContractProviderRead;
};

export function MonthsLeftCell({ contract }: MonthsLeftCellProps) {
  const { t } = useTranslation();
  return (
    <div>
      {t("admin:employeeViewPage.contracts.monthsLeft", {
        months: contract.remainingLeasingPeriodMonths,
      })}
    </div>
  );
}
