import * as React from "react";

import { useTranslation } from "react-i18next";

import { InfoCard, useUserCountry } from "@vapaus/common-v2";
import { BadgeIcon, CircularIcon } from "@vapaus/ui-v2";

export function EmployeeFaqInfoCard() {
  const { t } = useTranslation();
  const { isSwedishUser } = useUserCountry();

  const link = isSwedishUser
    ? "https://vapaus.se/formanscyklar-for-anstallda"
    : t("admin:support.guidesAndFaqs.employeeFaq.link");

  return (
    <InfoCard
      icon={<CircularIcon icon={BadgeIcon} />}
      title={t("admin:support.guidesAndFaqs.employeeFaq.title")}
      description={t("admin:support.guidesAndFaqs.employeeFaq.description")}
      buttonText={t("admin:support.guidesAndFaqs.viewGuide")}
      link={link}
      target="_blank"
      backgroundColor="primary2.lighter"
      shadow="none"
      variant="small"
    />
  );
}
