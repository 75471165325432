import * as React from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import type { EmployeeBenefitActivationStatus } from "@vapaus/api-codegen";
import { Flex, Tag, TagVariant } from "@vapaus/ui-v2";

type PermissionStatusCellProps = {
  status: EmployeeBenefitActivationStatus;
};

export function PermissionStatusCell({ status }: PermissionStatusCellProps) {
  const { t } = useTranslation();
  const { variant, text } = mapStatusToTagProps(status, t);
  if (!variant || !text) {
    return null;
  }
  return (
    <Flex justify="flex-end">
      <Tag variant={variant} text={text} />
    </Flex>
  );
}

function mapStatusToTagProps(
  status: EmployeeBenefitActivationStatus,
  t: TFunction<"translation">,
): { variant: TagVariant; text: string } {
  switch (status) {
    case "approved":
      return {
        variant: "accepted",
        text: t("admin:home.employees.permissionStatus.approved"),
      };
    case "revoked":
      return {
        variant: "cancelled",
        text: t("admin:home.employees.permissionStatus.revoked"),
      };
    case "no_activation":
      return {
        variant: "done",
        text: t("admin:home.employees.permissionStatus.noActivation"),
      };
  }
}
