import { useQuery } from "@tanstack/react-query";

import type {
  BikeBenefitContractState,
  PaginatedBikeBenefitContractProviderOut,
  ProviderBenefitProviderSearchBikeBenefitContractsRequest,
  ResponseError,
} from "@vapaus/api-codegen";
import { ProviderBenefitApi } from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const providerBenefitAPI = new ProviderBenefitApi(apiConfiguration);

export const useSearchBenefitContracts = (
  params: Omit<
    ProviderBenefitProviderSearchBikeBenefitContractsRequest,
    "benefitDefinitionIds" | "states"
  > & {
    benefitDefinitionIds: Array<string>;
    states?: Array<BikeBenefitContractState>;
  },
  options?: UseQueryOptions<PaginatedBikeBenefitContractProviderOut>,
) =>
  useQuery<PaginatedBikeBenefitContractProviderOut, ResponseError>({
    queryKey: ["benefitContracts", params],
    queryFn: () =>
      providerBenefitAPI.providerBenefitProviderSearchBikeBenefitContracts({
        ...params,
        benefitDefinitionIds: new Set(params.benefitDefinitionIds),
        states: params.states ? new Set(params.states) : undefined,
      }),
    ...options,
  });
