import { useQuery } from "@tanstack/react-query";

import {
  ProviderRedemptionProcessesApi,
  RedemptionProcessRead,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const providerRedemptionProcessesAPI =
  new ProviderRedemptionProcessesApi(apiConfiguration);

export const useGetRedemptionProcess = (
  id?: string,
  options?: UseQueryOptions<RedemptionProcessRead>,
) =>
  useQuery<RedemptionProcessRead, ResponseError>({
    queryKey: ["redemptionProcesses", id],
    queryFn: () =>
      providerRedemptionProcessesAPI.providerRedemptionProcessesGetRedemptionProcess(
        {
          redemptionProcessId: id!,
        },
      ),
    ...options,
    enabled: !!id,
  });
