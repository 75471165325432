import { useTranslation } from "react-i18next";

import { RowItem } from "@vapaus/ui-v2";

import { useGetUserMepcoIntegrationSettings } from "../../../../../../../api/mepco";
import { useIsMepcoIntegrationEnabled } from "../../../../../../../hooks/useIsMepcoIntegrationEnabled";
import { renderBenefitName } from "../../../../../../../utils/renderBenefitName";
import { RequestsTableDataType } from "../../RequestsTable/RequestsTable";

type CommonRowsProps = {
  request: RequestsTableDataType;
};

export function CommonRows({ request }: CommonRowsProps) {
  const { t } = useTranslation();
  const isMepcoIntegrationEnabled = useIsMepcoIntegrationEnabled();
  const { data: mepcoIntegrationSettings } = useGetUserMepcoIntegrationSettings(
    request.employee?.id,
    request.organisationId,
    {
      enabled: isMepcoIntegrationEnabled,
    },
  );
  return (
    <>
      <RowItem
        label={t("admin:home.requests.requestModal.bikeBenefit")}
        value={renderBenefitName(
          request.benefitDefinitionName,
          request.benefitDefinitionActive,
          t,
        )}
      />
      <RowItem
        label={t("admin:home.requests.requestModal.employeeId")}
        value={request.employee.employeeId ?? "-"}
      />
      <RowItem
        label={t("admin:home.requests.requestModal.costCenter")}
        value={request.employee.costCenter ?? "-"}
      />
      {isMepcoIntegrationEnabled ? (
        <>
          <RowItem
            label={t("admin:mepcoFields.payGroupId")}
            value={mepcoIntegrationSettings?.payGroupId ?? "-"}
          />
          <RowItem
            label={t("admin:mepcoFields.payComponent")}
            value={mepcoIntegrationSettings?.payComponent ?? "-"}
          />
        </>
      ) : null}
    </>
  );
}
