/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest
 */
export interface BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest {
  /**
   *
   * @type {string}
   * @memberof BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest
   */
  message?: string;
}

/**
 * Check if a given object implements the BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest interface.
 */
export function instanceOfBodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest(
  value: object,
): boolean {
  let isInstance = true;

  return isInstance;
}

export function BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequestFromJSON(
  json: any,
): BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest {
  return BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequestFromJSONTyped(
    json,
    false,
  );
}

export function BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: !exists(json, "message") ? undefined : json["message"],
  };
}

export function BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequestToJSON(
  value?: BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
  };
}
