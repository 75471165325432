import { useState } from "react";

import { BikeBenefitContractProviderRead } from "@vapaus/api-codegen";
import { Flex } from "@vapaus/ui-v2";

import { ContractDetailsDrawer } from "../../../../components/ContractDetailsDrawer";
import { StyledFilteringSection } from "../../../../components/StyledFilteringSection";
import { ContractSearchBar } from "./components/ContractSearchBar";
import { ContractsClearFiltersSection } from "./components/ContractsClearFiltersSection";
import { ContractStatusSelect } from "./components/ContractsStatusSelect";
import { ContractsTable } from "./components/ContractsTable/ContractsTable";

export function ContractsTab() {
  const [selectedContract, setSelectedContract] =
    useState<BikeBenefitContractProviderRead>();
  return (
    <Flex direction="column" gap="lg">
      <StyledFilteringSection>
        <ContractSearchBar />
        <ContractStatusSelect />
      </StyledFilteringSection>
      <ContractsClearFiltersSection />
      <ContractsTable onRowClick={setSelectedContract} />
      <ContractDetailsDrawer
        contract={selectedContract}
        onClose={() => setSelectedContract(undefined)}
      />
    </Flex>
  );
}
