import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { BenefitDefinitionAccessRequestStatus } from "@vapaus/api-codegen";
import { Tag, TagSize, TagVariant } from "@vapaus/ui-v2";

type RequestTagProps = {
  status: BenefitDefinitionAccessRequestStatus;
  size?: TagSize;
};

export function RequestTag({ status, size = "default" }: RequestTagProps) {
  const { t } = useTranslation();
  const { variant, text } = mapStatusToTagProps(status, t);
  if (!variant || !text) {
    return null;
  }
  return <Tag variant={variant} text={text} size={size} />;
}

const mapStatusToTagProps = (
  status: BenefitDefinitionAccessRequestStatus,
  t: TFunction<"translation">,
): { variant?: TagVariant; text?: string } => {
  switch (status) {
    case BenefitDefinitionAccessRequestStatus.Pending:
      return {
        variant: "pending",
        text: t("admin:home.requests.status.pending"),
      };
    case BenefitDefinitionAccessRequestStatus.Expired:
      return {
        variant: "done",
        text: t("admin:home.requests.status.expired"),
      };
    case BenefitDefinitionAccessRequestStatus.Denied:
      return {
        variant: "cancelled",
        text: t("admin:home.requests.status.denied"),
      };
    default:
      return {
        variant: undefined,
        text: undefined,
      };
  }
};
