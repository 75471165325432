import { useTranslation } from "react-i18next";

import { ClearFiltersSection } from "@vapaus/common-v2";

import { useEmployeesTable } from "./EmployeesTable/useEmployeesTable";

export function EmployeesClearFiltersSection() {
  const { t } = useTranslation();
  const { paginationOptions, clearFilters, areFiltersEmpty } =
    useEmployeesTable();

  return (
    <ClearFiltersSection
      showingItemsText={t(
        "admin:home.employees.clearFiltersSection.showingEmployees",
        {
          numberOfEmployees: paginationOptions.totalItems,
        },
      )}
      onClick={clearFilters}
      buttonActive={!areFiltersEmpty}
    />
  );
}
