import { useTranslation } from "react-i18next";

import { Split } from "@vapaus/common-v2";
import { Typography } from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../../../../packages/i18n/src/hooks";
import { ContractProps } from "../../../type";
import { useContractParams } from "../../ContractBodySection/hooks/useContractParams";

export function ContractSplit({ contract }: ContractProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(contract.currency);
  const { monthsLeft, contractTotal, contractLeftToPay } = useContractParams({
    contract,
  });

  return (
    <Split
      leftContent={
        <>
          <Typography variant="bodyXs" noWrap>
            {t("admin:contractDetails.contractSplit.left.row1")}
          </Typography>
          <Typography variant="h4" noWrap>
            {formatCurrency(contractLeftToPay)}
          </Typography>
          <Typography variant="bodyXs" noWrap>
            {t("admin:contractDetails.contractSplit.left.row2", {
              value: formatCurrency(contractTotal),
            })}
          </Typography>
        </>
      }
      rightContent={
        <>
          <Typography variant="bodyXs" noWrap>
            {t("admin:contractDetails.contractSplit.right.row1")}
          </Typography>
          <Typography variant="h4" noWrap>
            {t("admin:contractDetails.contractSplit.right.row2", {
              value: monthsLeft,
            })}
          </Typography>
          <Typography variant="bodyXs" noWrap>
            {t("admin:contractDetails.contractSplit.right.row3", {
              value: contract.leasingPeriodMonths,
            })}
          </Typography>
        </>
      }
    />
  );
}
