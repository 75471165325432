import { Navigate, RouteObject } from "react-router-dom";

import { AccountSettingsCard, UserProfileCard } from "@vapaus/settings";

import { Path } from "../../constants/paths";
import { AccountSettingsPage } from "./AccountSettingsPage";
import { AccountNotificationsCard } from "./components/AccountNotificationsCard";

export const accountSettingsRoutes: RouteObject = {
  path: Path.settings,
  element: <AccountSettingsPage />,
  children: [
    {
      index: true,
      element: <Navigate to={Path.profile} />,
    },
    {
      path: Path.profile,
      element: <UserProfileCard />,
    },
    {
      path: Path.settingsAccount,
      element: <AccountSettingsCard />,
    },
    {
      path: Path.settingsNotifications,
      element: <AccountNotificationsCard />,
    },
  ],
};
