import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import invariant from "tiny-invariant";

import { UserBenefitActivationRead, UserRead } from "@vapaus/api-codegen";
import { ModalProps, useToaster } from "@vapaus/ui-v2";

import { useRevokeActivation } from "../../../../../../api/benefitActivations";
import { usePermissionMessage } from "../../hooks/usePermissionMessage";
import { getPermissionStatus } from "../../utils/getPermissionStatus";

export function usePermissionModal(
  user: UserRead,
  onClose: () => void,
  activation?: UserBenefitActivationRead,
  benefitActiveState?: boolean,
) {
  const { t } = useTranslation();
  const [modalView, setModalView] = useState<"activation" | "revoke">(
    "activation",
  );
  const revokeActivation = useRevokeActivation();
  const permissionStatus = getPermissionStatus(
    t,
    activation,
    benefitActiveState,
  );
  const permissionMessage = usePermissionMessage(
    permissionStatus,
    activation,
    user,
  );

  const toaster = useToaster();
  const queryClient = useQueryClient();

  const handleConfirmRevoke = () => {
    invariant(activation, "Activation should have a value");
    revokeActivation.mutate(activation.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["benefitActivations"]);
        toaster.add(
          "success",
          t(
            "admin:employeeViewPage.permissions.permissionModal.activationRevoked",
          ),
        );
        onClose();
      },
      onError: () => {
        toaster.add(
          "error",
          t(
            "admin:employeeViewPage.permissions.permissionModal.activationRevokeFailed",
          ),
        );
      },
    });
  };

  const revokeText = t(
    "admin:employeeViewPage.permissions.permissionModal.revoke",
  );

  const activationModalProps: ModalProps = {
    isOpen: !!activation,
    onConfirm:
      permissionStatus?.status === "authorised"
        ? () => setModalView("revoke")
        : onClose,
    confirmButtonVariant:
      permissionStatus?.status === "authorised" ? "primaryDanger" : "primary1",
    confirmText:
      permissionStatus?.status === "authorised"
        ? revokeText
        : t("commonV2:gotIt"),
    onClose,
  };

  const revokeModalProps: ModalProps = {
    isOpen: !!activation,
    title: t("admin:employeeViewPage.permissions.permissionModal.title"),
    description: t(
      "admin:employeeViewPage.permissions.permissionModal.description",
      { name: user.fullName, email: user.email },
    ),
    onCancel: () => setModalView("activation"),
    onConfirm: handleConfirmRevoke,
    cancelText: t("commonV2:cancel"),
    confirmText: revokeText,
    confirmButtonVariant: "primaryDanger",
    shouldCloseOnCancel: false,
    isSubmitting: revokeActivation.isLoading,
    onClose,
  };

  const modalProps =
    modalView === "revoke" ? revokeModalProps : activationModalProps;

  return {
    modalView,
    modalProps,
    permissionMessage,
    permissionStatus,
  };
}
