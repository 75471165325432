import { useTranslation } from "react-i18next";

import { BikeBenefitContractState } from "@vapaus/api-codegen";
import { TagProps } from "@vapaus/ui-v2";

import { useSearchBenefitActivations } from "../../../../api/benefitActivations";
import { useSearchBenefitContracts } from "../../../../api/benefitContracts";
import { useOrganisationAndBenefitsContext } from "../../../../contexts/OrganisationAndBenefitsContext";

type ApprovalStatus = "approved" | "revoked" | "approvedNoActivation";

type ApprovalTagProps = Omit<TagProps, "size"> & {
  status: ApprovalStatus;
};

type UseApprovalTabProps = {
  isLoading: boolean;
  approval: ApprovalTagProps | null;
};

export function useApprovalTag(userId: string): UseApprovalTabProps {
  const { t } = useTranslation();
  const { availableBenefitIds } = useOrganisationAndBenefitsContext();

  const { data: activeBenefitContracts, isLoading: isLoadingBenefits } =
    useSearchBenefitContracts({
      userId,
      benefitDefinitionIds: availableBenefitIds,
      limit: 1,
      states: [
        BikeBenefitContractState.Active,
        BikeBenefitContractState.ActivePendingCorrection,
        BikeBenefitContractState.ActivePendingRevision,
      ],
    });

  const { data: activeBenefitActivations, isLoading: isLoadingActivations } =
    useSearchBenefitActivations({
      userId,
      benefitDefinitionIds: new Set(availableBenefitIds),
      activeOnly: true,
      limit: 1,
    });

  const {
    data: revokedBenefitActivations,
    isLoading: isLoadingInactiveActivations,
  } = useSearchBenefitActivations(
    {
      userId,
      benefitDefinitionIds: new Set(availableBenefitIds),
      activeOnly: false,
      limit: 1,
    },
    {
      enabled: activeBenefitActivations?.total === 0,
    },
  );

  const isLoading =
    isLoadingBenefits ||
    isLoadingActivations ||
    (activeBenefitActivations?.total === 0 && isLoadingInactiveActivations);

  if (isLoading)
    return {
      isLoading: true,
      approval: null,
    };

  if (!activeBenefitActivations || !activeBenefitContracts)
    return {
      isLoading: false,
      approval: null,
    };

  if (activeBenefitActivations.total) {
    return {
      isLoading: false,
      approval: {
        text: t("admin:employeeViewPage.status.approved"),
        variant: "accepted",
        status: "approved",
      },
    };
  }
  if (revokedBenefitActivations?.total)
    return {
      isLoading: false,
      approval: {
        text: t("admin:employeeViewPage.status.revoked"),
        variant: "cancelled",
        status: "revoked",
      },
    };

  if (activeBenefitContracts.total > 0)
    return {
      isLoading: false,
      approval: {
        text: t("admin:employeeViewPage.status.approvedNoActivation"),
        variant: "done",
        status: "approvedNoActivation",
      },
    };
  return {
    isLoading: false,
    approval: null,
  };
}
