import React, { useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { ProfileDropdown } from "@vapaus/common-v2";
import { useCurrentUser } from "@vapaus/shared-api";
import {
  AccountSwitcher,
  Banner,
  LogoutIcon,
  MobileNavigationBar,
  NavigationBar,
  PersonIcon,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { Path } from "../../constants/paths";
import { useOrganisationAndBenefitsContext } from "../../contexts/OrganisationAndBenefitsContext";
import { AccountSwitcherMenu } from "./components/AccountSwitcherMenu";
import { MobileNavigationButtons } from "./components/MobileNavigationButtons";
import { NavigationButtons } from "./components/NavigationButtons";
import { ProductUpgradeBanner } from "./components/ProductUpgradeBanner";

export function Navigation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();
  const { data: user } = useCurrentUser();

  const { selectedOrganisation, organisationsWithBenefits } =
    useOrganisationAndBenefitsContext();

  const [profileMenuTrigger, setProfileMenuTrigger] =
    useState<null | HTMLElement>(null);
  const [accountSwitcherMenuTrigger, setAccountSwitcherMenuTrigger] =
    useState<null | HTMLElement>(null);

  const handleProfileButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAccountSwitcherMenuTrigger(null);
    setProfileMenuTrigger(profileMenuTrigger ? null : event.currentTarget);
  };

  const handleAccountButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setProfileMenuTrigger(null);
    setAccountSwitcherMenuTrigger(
      accountSwitcherMenuTrigger ? null : event.currentTarget,
    );
  };

  const closeProfileMenu = () => {
    setProfileMenuTrigger(null);
  };

  const closeAccountSwitcherMenu = () => {
    setAccountSwitcherMenuTrigger(null);
  };

  const menuProps = useMemo(
    () => ({
      header: {
        icon: PersonIcon,
        title: user?.fullName ?? "",
        description: user?.email ?? "",
      },
      items: [
        {
          label: t("commonV2:account"),
          value: Path.settings,
          icon: PersonIcon,
        },
        {
          label: t("commonV2:logout"),
          value: Path.logout,
          icon: LogoutIcon,
        },
      ],
    }),
    [t, user?.email, user?.fullName],
  );

  const AccountSwitcherComponent = (
    <>
      <AccountSwitcher
        title={t("admin:navigation.accountSwitcher.title")}
        selectedAccount={selectedOrganisation?.name ?? ""}
        onClick={handleAccountButtonClick}
      />
      <AccountSwitcherMenu
        accountSwitcherMenuTrigger={accountSwitcherMenuTrigger}
        closeAccountSwitcherMenu={closeAccountSwitcherMenu}
      />
    </>
  );

  return (
    <>
      {selectedOrganisation?.country === "FI" ? (
        <Banner variant="success">
          <Typography marginBottom="xxs">
            <Trans
              i18nKey="admin:announcement.vatChange"
              components={{
                1: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href="https://www.vapaus.io/tyonantajalle/alv-korotus-2024-vaikuttaa-pyoraetuun"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            ></Trans>
          </Typography>
        </Banner>
      ) : null}
      <NavigationBar
        onLogoClick={() => navigate("")}
        mainContent={<NavigationButtons />}
        secondaryContent={
          organisationsWithBenefits.length > 1 ? AccountSwitcherComponent : null
        }
        profileMenu={
          <ProfileDropdown
            profileMenuTrigger={profileMenuTrigger}
            handleProfileMenuClick={handleProfileButtonClick}
            closeProfileMenu={closeProfileMenu}
            {...menuProps}
          />
        }
      />
      <StyledContainer>
        <ProductUpgradeBanner />
        <Outlet />
      </StyledContainer>
      {isMobile ? (
        <MobileNavigationBar>
          <MobileNavigationButtons />
        </MobileNavigationBar>
      ) : null}
    </>
  );
}

const StyledContainer = styled.div`
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding-bottom: ${theme.height.navBar};
    `)}
`;
