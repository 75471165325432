import { useCallback } from "react";

import { BenefitDefinitionAccessRequestStatus } from "@vapaus/api-codegen";
import { useTableParams } from "@vapaus/ui-v2";

import { useGetAccessRequests } from "../../../../../../api/accessRequests";
import { useCurrentBenefitContext } from "../../../../../../contexts/CurrentBenefitContext";
import { useOrganisationAndBenefitsContext } from "../../../../../../contexts/OrganisationAndBenefitsContext";

export function useRequestsTable() {
  const { availableBenefitIds } = useOrganisationAndBenefitsContext();
  const { currentBenefitId } = useCurrentBenefitContext();

  const {
    q: searchQuery,
    selectedStatusFilter,
    sorting,
    pagination,
    setSorting,
    setPagination,
    setSearchQuery,
    setExtraParams,
  } = useTableParams<{
    selectedStatusFilter: BenefitDefinitionAccessRequestStatus | "";
  }>({ sortId: "date", sortDesc: true, selectedStatusFilter: "pending" });

  const sortFieldMapping: Record<string, string> = {
    sender: "user.last_name",
    date: "created_at",
    status: "status",
  };

  const { data: fetchedData, isLoading } = useGetAccessRequests(
    {
      benefitDefinitionIds: currentBenefitId
        ? [currentBenefitId]
        : availableBenefitIds,
      limit: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      q: searchQuery,
      ...(sortFieldMapping[sorting[0]?.id]
        ? {
            sortField: sortFieldMapping[sorting[0]?.id],
            sortDirection: sorting[0]?.desc ? "desc" : "asc",
          }
        : null),
      status: selectedStatusFilter
        ? [selectedStatusFilter]
        : Object.values(BenefitDefinitionAccessRequestStatus).filter(
            (status) => {
              return !(
                status === BenefitDefinitionAccessRequestStatus.Accepted ||
                status === BenefitDefinitionAccessRequestStatus.Cancelled ||
                status === BenefitDefinitionAccessRequestStatus.Invalid
              );
            },
          ),
    },
    {
      enabled: availableBenefitIds.length > 0,
      keepPreviousData: true,
    },
  );

  const handleSelectedStatusFilterChange = useCallback(
    function handleSelectedStateFilterChange(_: unknown, value: string | null) {
      setExtraParams({
        selectedStatusFilter: value as BenefitDefinitionAccessRequestStatus,
      });
    },
    [setExtraParams],
  );

  const shouldShowCustomPendingTableEmptyState =
    !searchQuery &&
    selectedStatusFilter === "pending" &&
    fetchedData &&
    fetchedData?.total === 0;

  return {
    searchQuery,
    setSearchQuery,
    selectedStatusFilter,
    handleSelectedStatusFilterChange,
    paginationOptions: {
      totalItems: fetchedData?.total ?? 0,
      pagination,
      setPagination,
    },
    sortingOptions: {
      sorting,
      setSorting,
    },
    fetchedData,
    isLoading,
    shouldShowNoPendingRequestsTableEmptyState:
      shouldShowCustomPendingTableEmptyState,
  };
}
