import { BikeBenefitOrderRead } from "@vapaus/api-codegen";
import { getOrderCalculationParams } from "@vapaus/common-v2";
import { useOrderCalculation } from "@vapaus/shared-api";

import { ContractParamsProps, ContractProps } from "../../../type";

export function useContractParams({
  contract,
}: ContractProps): ContractParamsProps {
  const orderCalculationParams = getOrderCalculationParams(
    {
      ...contract,
      benefitDefinitionId: contract.benefitDefinition.id,
    } as unknown as BikeBenefitOrderRead,
    contract.benefitDefinition,
  );

  const { data: calculation } = useOrderCalculation(orderCalculationParams, {
    enabled: Boolean(contract.benefitDefinition),
  });

  const leasingCost = calculation
    ? calculation.monthlyInsuranceFee * calculation.leasingPeriodMonths +
      calculation.totalFinancingCost +
      calculation.totalServiceCost
    : undefined;

  const passedMonths =
    contract.leasingPeriodMonths - contract.remainingLeasingPeriodMonths;
  const monthsLeft = contract.remainingLeasingPeriodMonths;
  const effectiveTaxableValue =
    contract.fixedMonthlyTaxableValue || contract.monthlyTaxableValue;
  const contractTotal = effectiveTaxableValue * contract.leasingPeriodMonths;
  const contractLeftToPay =
    contractTotal - effectiveTaxableValue * passedMonths;

  return { leasingCost, monthsLeft, contractTotal, contractLeftToPay };
}
