import { ComponentType } from "react";

import styled from "styled-components";

import {
  CircularIcon,
  Flex,
  SvgIconProps,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

type SenderCellProps = {
  icon: ComponentType<SvgIconProps>;
  employeeName: string;
  employeeEmail: string;
  additionalData: string;
};

export function EmployeeCell({
  icon,
  employeeName,
  employeeEmail,
  additionalData,
}: SenderCellProps) {
  const { isXs } = useBreakpoints();

  return (
    <Flex>
      {!isXs ? <CircularIcon icon={icon} /> : null}
      <StyledFlex direction="column" gap="xss">
        <Typography>{employeeName}</Typography>
        <Typography variant="bodySm" weight="bold">
          {employeeEmail}
        </Typography>
        <Typography variant="bodySm">{additionalData}</Typography>
      </StyledFlex>
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;
