import { ReactNode } from "react";

import {
  Flex,
  Modal,
  ModalProps,
  Typography,
  VerifiedUserIcon,
} from "@vapaus/ui-v2";

import { RequestTag } from "../../RequestTag";
import { RequestsTableDataType } from "../../RequestsTable/RequestsTable";

export type RequestModalCommonProps = ModalProps & {
  request?: RequestsTableDataType;
};

type RequestModalProps = RequestModalCommonProps & {
  description: string;
  children: ReactNode;
};

export function RequestModal({
  isOpen,
  onClose,
  request,
  description,
  children,
  ...rest
}: RequestModalProps) {
  if (!request) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest}>
      <Flex direction="column" align="center" gap="md">
        <Flex direction="column" align="center">
          <RequestTag status={request.status} size="large" />
          <Flex direction="column" align="center" gap="xxs">
            <Typography variant="h2" align="center">
              {request.employee.name}
            </Typography>
            <Flex gap="xxs">
              <VerifiedUserIcon />
              <Typography variant="bodyLg" weight="bold">
                {request.employee.email}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
        <Typography variant="bodyLg" align="center">
          {description}
        </Typography>
        {children}
      </Flex>
    </Modal>
  );
}
