import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { UserRead } from "@vapaus/api-codegen";
import { useToaster } from "@vapaus/ui-v2";

import {
  useRevokeMultipleActivations,
  useSearchBenefitActivations,
} from "../../../../../../api/benefitActivations";
import { useOrganisationAndBenefitsContext } from "../../../../../../contexts/OrganisationAndBenefitsContext";

export function useRevokeAllCard(user: UserRead) {
  const { t } = useTranslation();

  const [openRevokeAllModal, setOpenRevokeAllModal] = useState(false);
  const revokeAll = useRevokeMultipleActivations();

  const handleOpenModal = () => setOpenRevokeAllModal(true);
  const handleCloseModal = () => setOpenRevokeAllModal(false);
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { availableBenefitIds } = useOrganisationAndBenefitsContext();

  const { data: activeActivations } = useSearchBenefitActivations(
    {
      userId: user.id,
      benefitDefinitionIds: new Set(availableBenefitIds),
      limit: 100,
      activeOnly: true,
    },
    {
      enabled: availableBenefitIds.length > 0,
    },
  );

  const activeActivationsIds = (activeActivations?.items || []).map(
    (activation) => activation.id,
  );

  const handleRevokeAllConfirm = () => {
    revokeAll.mutate(activeActivationsIds, {
      onSuccess: () => {
        handleCloseModal();
        toaster.add(
          "success",
          t(
            "admin:employeeViewPage.permissions.revokeAllCard.modal.messageSuccess",
          ),
        );
      },
      onError: () => {
        toaster.add(
          "error",
          t(
            "admin:employeeViewPage.permissions.revokeAllCard.modal.messageFailed",
          ),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["benefitActivations"]);
      },
    });
  };

  return {
    shouldHideCard: !activeActivations || activeActivationsIds.length === 0,
    isLoading: revokeAll.isLoading,
    openRevokeAllModal,
    handleRevokeAllConfirm,
    handleCloseModal,
    handleOpenModal,
  };
}
