export enum Path {
  home = "home",
  reports = "reports",
  support = "support",
  admin = "admin",
  settings = "settings",
  logout = "logout",
  adminOrganization = "organization",
  adminBenefits = "benefits",
  adminPlan = "plan",
  adminBenefitsGuideline = "guideline",
  adminBenefitsAuthentication = "authentication",
  contracts = "contracts",
  requests = "requests",
  employee = "employee",
  employeePermissions = "permissions",
  profile = "profile",
  settingsAccount = "account",
  settingsNotifications = "notifications",
}
