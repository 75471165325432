import * as React from "react";
import { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { DateTime } from "luxon";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as z from "zod";

import { ResponseError } from "@vapaus/api-codegen";
import { parseApiError } from "@vapaus/shared-api";
import {
  DotFilledIcon,
  LineEndCircleIcon,
  LineStartCircleIcon,
  PedalBikeIcon,
  ReceiptIcon,
} from "@vapaus/ui-v2";

import { useDateFormat } from "../../../../../../packages/i18n/src/hooks";
import { useOrganisationAndBenefitsContext } from "../../../contexts/OrganisationAndBenefitsContext";
import { renderBenefitName } from "../../../utils/renderBenefitName";
import {
  useDownloadContractsReport,
  useDownloadLeasingLiabilityReport,
} from "./useDownloadReport";

export function useReportsForm() {
  const { t } = useTranslation();
  const { selectedOrganisation, availableBenefitIds } =
    useOrganisationAndBenefitsContext();
  const formatDate = useDateFormat();
  const [error, setError] = useState<string | null>(null);

  const initialStartDate =
    DateTime.local().minus({ month: 1 }).startOf("month").toISODate() || "";
  const initialEndDate =
    DateTime.now().minus({ month: 1 }).endOf("month").toISODate() || "";

  const reportsSchema = z
    .object({
      benefit: z.string().optional(),
      type: z.string().min(1),
      startDate: z.date(),
      endDate: z.date(),
    })
    .refine((data) => data.endDate >= data.startDate, {
      path: ["endDate"],
    });

  const {
    register,
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm({ resolver: zodResolver(reportsSchema) });

  const {
    error: errorContract,
    download: downloadContractReport,
    reset: resetContract,
    status: contractStatus,
  } = useDownloadContractsReport();

  const {
    error: errorLiability,
    download: downloadLiabilityReport,
    reset: resetLiability,
    status: liabilityStatus,
  } = useDownloadLeasingLiabilityReport();

  const handleDownload = async (data: FieldValues) => {
    resetContract();
    resetLiability();
    setError(null);
    if (data.type !== "leasingLiability") {
      return await downloadContractReport(
        {
          startDate: data.startDate,
          endDate: data.endDate,
          benefitDefinitionIds: data.benefit
            ? [data.benefit]
            : availableBenefitIds,
          reportType: data.type,
        },
        `${selectedOrganisation?.name}-${data.type}-report.csv`,
        "text/csv",
      );
    }

    return await downloadLiabilityReport(
      {
        startDate: data.startDate,
        endDate: data.endDate,
        benefitDefinitionIds: data.benefit
          ? [data.benefit]
          : availableBenefitIds,
      },
      `leasing_liability_report_${formatDate(data.startDate)}_${formatDate(
        data.endDate,
      )}.csv`,
      "text/csv",
    );
  };

  const downloadError = errorContract || errorLiability;

  useEffect(() => {
    const parseError = async () => {
      if (downloadError) {
        const message = await parseApiError(downloadError as ResponseError);
        setError(message);
      }
    };
    parseError();
  }, [downloadError, setError]);

  const isLoading =
    contractStatus === "loading" || liabilityStatus === "loading";

  const benefitItems =
    selectedOrganisation?.benefits.map((benefit) => ({
      value: benefit.id,
      label: renderBenefitName(benefit.name, benefit.active, t),
      icon: <PedalBikeIcon />,
    })) ?? [];

  const benefitOptions = [
    {
      value: "",
      label: t("admin:reports.card.allBenefits"),
      icon: <PedalBikeIcon />,
    },
    ...benefitItems,
  ];

  const reportTypeOptions = [
    {
      value: "active",
      label: t("admin:reports.card.type.active"),
      icon: <DotFilledIcon color="secondary1.main" />,
    },
    {
      value: "started",
      label: t("admin:reports.card.type.started"),
      icon: <LineStartCircleIcon />,
    },
    {
      value: "expired",
      label: t("admin:reports.card.type.expired"),
      icon: <LineEndCircleIcon />,
    },
    {
      value: "leasingLiability",
      label: t("admin:reports.card.type.leasingLiability"),
      icon: <ReceiptIcon />,
    },
  ];

  const startDate = watch("startDate");

  return {
    register,
    control,
    handleSubmit,
    isValid,
    initialStartDate,
    initialEndDate,
    error,
    benefitOptions,
    reportTypeOptions,
    handleDownload,
    isLoading,
    startDate,
  };
}
