import * as React from "react";

import { DateTime } from "luxon";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, DatePicker, Flex, Notification, Select } from "@vapaus/ui-v2";

import { useReportsForm } from "./useReportsForm";

export function ReportForm() {
  const { t } = useTranslation();

  const {
    register,
    control,
    handleSubmit,
    isValid,
    initialStartDate,
    initialEndDate,
    error,
    benefitOptions,
    reportTypeOptions,
    handleDownload,
    isLoading,
    startDate,
  } = useReportsForm();

  return (
    <>
      {error ? <Notification message={error} type="error" /> : null}
      <form onSubmit={handleSubmit(handleDownload)}>
        <Flex direction="column" gap="md">
          {benefitOptions.length > 2 ? (
            <Controller
              name="benefit"
              defaultValue={""}
              control={control}
              render={({ field }) => (
                <Select
                  options={benefitOptions}
                  placeholder={t("admin:reports.card.benefit.placeholder")}
                  label={t("admin:reports.card.benefit.label")}
                  required
                  name={field.name}
                  value={field.value}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  fullWidth
                />
              )}
            />
          ) : null}
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Select
                options={reportTypeOptions}
                placeholder={t("admin:reports.card.type.placeholder")}
                label={t("admin:reports.card.type.label")}
                required
                name={field.name}
                value={field.value}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                fullWidth
              />
            )}
          />
          <Flex xsDirection="column">
            <DatePicker
              {...register("startDate", {
                valueAsDate: true,
                value: initialStartDate,
              })}
              label={t("admin:reports.card.startDate.label")}
            />
            <DatePicker
              {...register("endDate", {
                valueAsDate: true,
                value: initialEndDate,
              })}
              label={t("admin:reports.card.endDate.label")}
              min={DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}
            />
          </Flex>
          <div>
            <Button isLoading={isLoading} disabled={!isValid}>
              {t("commonV2:download")}
            </Button>
          </div>
        </Flex>
      </form>
    </>
  );
}
