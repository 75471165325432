import styled, { css } from "styled-components";

import { Flex } from "@vapaus/ui-v2";

export const StyledFilteringSection = styled(Flex)`
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      flex-direction: column;

      > div {
        width: 100%;
        flex: auto;
      }
    `)}
`;
