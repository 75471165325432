import { useTranslation } from "react-i18next";

import {
  Card,
  Flex,
  HorizontalLine,
  LineItem,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { useOrganisationAndBenefitsContext } from "../../../../contexts/OrganisationAndBenefitsContext";
import { ContactUsButton } from "../ContactUsButton";

export function AdminSettingsOrganisationTab() {
  const { t } = useTranslation();
  const { selectedOrganisation } = useOrganisationAndBenefitsContext();
  return (
    <Card title={t("admin:adminPage.organisationTab.title")}>
      <Flex direction="column" gap="lg">
        <LineItem
          name={t("admin:adminPage.organisationTab.name")}
          content={selectedOrganisation?.name || "-"}
        />
        <LineItem
          name={t("admin:adminPage.organisationTab.businessId")}
          content={selectedOrganisation?.businessId || "-"}
        />
        <HorizontalLine marginBottom="none" marginTop="none" />
        <Flex direction="column" align="center" gap="xs">
          <Typography align="center">
            {t("admin:adminPage.organisationTab.footerMessage")}
          </Typography>
          <ContactUsButton />
        </Flex>
      </Flex>
    </Card>
  );
}
