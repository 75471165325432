import { ChangeEvent, useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { ProviderNotificationCategory } from "@vapaus/api-codegen";
import { CardRow, Switch, useToaster } from "@vapaus/ui-v2";

import { useUpdateNotificationPreferences } from "../../../api/notificationPreferences";

type NotificationSwitchProps = {
  title: string;
  description: string;
  category: ProviderNotificationCategory;
  currentOptedOutCategories: ProviderNotificationCategory[];
};

export function NotificationSwitch({
  title,
  description,
  category,
  currentOptedOutCategories,
}: NotificationSwitchProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const updateNotification = useUpdateNotificationPreferences();
  const isOptedIn = !currentOptedOutCategories.includes(category);
  const toaster = useToaster();

  const toggleNotificationStatus = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!navigator.onLine) {
        toaster.add(
          "error",
          t("admin:settingsPage.notifications.failedMessage"),
        );
        return;
      }

      const newCategories = event.target.checked
        ? currentOptedOutCategories.filter((e) => e !== category)
        : [...currentOptedOutCategories, category];

      updateNotification.mutate(newCategories, {
        onSuccess: () =>
          queryClient.invalidateQueries(["notificationPreferences"]),
        onError: () => {
          toaster.add(
            "error",
            t("admin:settingsPage.notifications.failedMessage"),
          );
        },
      });
    },
    [category, currentOptedOutCategories, queryClient, updateNotification, t],
  );

  return (
    <CardRow
      title={title}
      description={description}
      action={
        <Switch checked={isOptedIn} onChange={toggleNotificationStatus} />
      }
    />
  );
}
