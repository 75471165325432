import { useCallback, useEffect, useState } from "react";

import {
  EmployeeBenefitActivationStatus,
  ProviderUsersProviderSearchEmployeeUsersSortFieldEnum,
} from "@vapaus/api-codegen";
import { useTableParams } from "@vapaus/ui-v2";

import { useSearchEmployees } from "../../../../../../api/user";
import { useCurrentBenefitContext } from "../../../../../../contexts/CurrentBenefitContext";
import { useOrganisationAndBenefitsContext } from "../../../../../../contexts/OrganisationAndBenefitsContext";

export function useEmployeesTable() {
  const { availableBenefitIds } = useOrganisationAndBenefitsContext();
  const { currentBenefitId } = useCurrentBenefitContext();
  const [shouldClearAllFilters, setShouldClearAllFilters] =
    useState<boolean>(false);

  const {
    q: searchQuery,
    selectedStatusFilter,
    sorting,
    pagination,
    setSorting,
    setPagination,
    setSearchQuery,
    setExtraParams,
  } = useTableParams<{
    selectedStatusFilter: EmployeeBenefitActivationStatus | "";
  }>({ sortId: "", sortDesc: false, selectedStatusFilter: "" });

  const sortFieldMapping: Record<string, string> = {
    permission: "benefit_activation_status",
    employee: "last_name",
  };

  const { data: fetchedData, isLoading } = useSearchEmployees(
    {
      benefitDefinitionIds: currentBenefitId
        ? [currentBenefitId]
        : availableBenefitIds,
      limit: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      q: searchQuery,
      ...(sortFieldMapping[sorting[0]?.id]
        ? {
            sortField: sortFieldMapping[
              sorting[0]?.id
            ] as ProviderUsersProviderSearchEmployeeUsersSortFieldEnum,
            sortDirection: sorting[0]?.desc ? "desc" : "asc",
          }
        : null),
      ...(selectedStatusFilter
        ? {
            benefitActivationStatus:
              selectedStatusFilter as EmployeeBenefitActivationStatus,
          }
        : undefined),
    },
    {
      enabled: availableBenefitIds.length > 0,
      keepPreviousData: true,
    },
  );

  const handleSelectedStatusFilterChange = useCallback(
    function handleSelectedStateFilterChange(_: unknown, value: string | null) {
      setExtraParams({
        selectedStatusFilter: value as EmployeeBenefitActivationStatus,
      });
    },
    [setExtraParams],
  );

  const clearFilters = () => {
    setShouldClearAllFilters(true);
    setSearchQuery("");
  };

  useEffect(() => {
    if (searchQuery === "" && shouldClearAllFilters) {
      setExtraParams({ selectedStatusFilter: "" });
      setShouldClearAllFilters(false);
    }
  }, [shouldClearAllFilters]);

  const areFiltersEmpty = !searchQuery && !selectedStatusFilter;

  return {
    searchQuery,
    setSearchQuery,
    selectedStatusFilter,
    handleSelectedStatusFilterChange,
    paginationOptions: {
      totalItems: fetchedData?.total ?? 0,
      pagination,
      setPagination,
    },
    sortingOptions: {
      sorting,
      setSorting,
    },
    fetchedData,
    isLoading,
    clearFilters,
    areFiltersEmpty,
  };
}
