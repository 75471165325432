import { ReactNode } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { Flex, NavigationButton } from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";

export function NavigationButtons(): ReactNode {
  const { t } = useTranslation();

  return (
    <Flex gap="xs">
      <NavLink to={Path.home}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("admin:navigation.buttons.home")}
          </NavigationButton>
        )}
      </NavLink>
      <NavLink to={Path.reports}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("admin:navigation.buttons.reports")}
          </NavigationButton>
        )}
      </NavLink>
      <NavLink to={Path.support}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("admin:navigation.buttons.support")}
          </NavigationButton>
        )}
      </NavLink>
      <NavLink to={Path.admin}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("admin:navigation.buttons.admin")}
          </NavigationButton>
        )}
      </NavLink>
    </Flex>
  );
}
