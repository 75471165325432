import * as React from "react";

import { useTranslation } from "react-i18next";

import { PageRoot } from "@vapaus/common-v2";
import { Flex, Typography } from "@vapaus/ui-v2";

import { ContactUsCard } from "./components/ContactUsCard";
import { GuidesAndFaqsCard } from "./components/GuidesAndFaqsCard";

export function SupportPage() {
  const { t } = useTranslation();

  return (
    <PageRoot>
      <Flex direction="column" gap="lg">
        <Typography variant="h1" smAlign="center">
          {t("admin:support.title")}
        </Typography>
        <GuidesAndFaqsCard />
        <ContactUsCard />
      </Flex>
    </PageRoot>
  );
}
