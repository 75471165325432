import { useTranslation } from "react-i18next";

import { CollapsibleLineItem, Flex, RowItem } from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../../../../packages/i18n/src/hooks";
import { ContractProps } from "../../../type";

export function ContractMaintenanceBudget({ contract }: ContractProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(contract.currency);

  return (
    <CollapsibleLineItem
      title={t("admin:contractDetails.maintenanceBudget.title")}
    >
      <Flex data-testid="maintenance-budget" direction="column" gap="md">
        <RowItem
          label={t("admin:contractDetails.maintenanceBudget.budgetType")}
          value={t("admin:contractDetails.maintenanceBudget.perMonth", {
            amount: formatCurrency(contract.monthlyMaintenanceBudget),
          })}
          labelIsBold={false}
        />
        <RowItem
          label={t("admin:contractDetails.maintenanceBudget.total")}
          value={formatCurrency(contract.totalMaintenanceBudget)}
          valueIsBold
        />
      </Flex>
    </CollapsibleLineItem>
  );
}
