import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { Card, HorizontalLine, LineItem } from "@vapaus/ui-v2";

import { EmployeeViewContextProps } from "../../types/EmployeeViewContextProps";
import { ProfileModal } from "./components/ProfileModal";

export function ProfileTab() {
  const { t } = useTranslation();
  const {
    user,
    isMepcoIntegrationEnabled,
    mepcoIntegrationSettings,
    organisationId,
  } = useOutletContext<EmployeeViewContextProps>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Card title={user.fullName} subtitle={user.email}>
        <LineItem
          name={t("admin:employeeViewPage.profile.employeeId")}
          content={user.employeeNumber || "-"}
          action={{
            label: t("commonV2:edit"),
            onClick: () => setIsModalOpen(true),
          }}
        />
        <HorizontalLine />
        <LineItem
          name={t("admin:employeeViewPage.profile.costCenter")}
          content={user.costCenter || "-"}
          action={{
            label: t("commonV2:edit"),
            onClick: () => setIsModalOpen(true),
          }}
        />
        {isMepcoIntegrationEnabled ? (
          <>
            <HorizontalLine />
            <LineItem
              name={t("admin:mepcoFields.payGroupId")}
              content={mepcoIntegrationSettings?.payGroupId || "-"}
              action={{
                label: t("commonV2:edit"),
                onClick: () => setIsModalOpen(true),
              }}
            />
            <HorizontalLine />
            <LineItem
              name={t("admin:mepcoFields.payComponent")}
              content={mepcoIntegrationSettings?.payComponent || "-"}
              action={{
                label: t("commonV2:edit"),
                onClick: () => setIsModalOpen(true),
              }}
            />
          </>
        ) : null}
      </Card>
      <ProfileModal
        isOpen={isModalOpen}
        user={user}
        organisationId={organisationId}
        mepcoIntegrationSettings={mepcoIntegrationSettings}
        isMepcoIntegrationEnabled={isMepcoIntegrationEnabled}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
