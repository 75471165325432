import * as React from "react";
import { useState } from "react";

import { Flex } from "@vapaus/ui-v2";

import { StyledFilteringSection } from "../../../../components/StyledFilteringSection";
import { RequestSearchBar } from "./components/RequestSearchBar";
import { RequestStatusSelect } from "./components/RequestStatusSelect";
import {
  RequestsTable,
  RequestsTableDataType,
} from "./components/RequestsTable/RequestsTable";
import { useRequestsTable } from "./components/RequestsTable/useRequestsTable";
import { DenyRequestConfirmationModal } from "./components/modals/DenyRequestConfirmationModal";
import { RequestDeniedModal } from "./components/modals/RequestDeniedModal";
import { RequestExpiredModal } from "./components/modals/RequestExpiredModal";
import { RequestPendingModal } from "./components/modals/RequestPendingModal";

type ModalType = "pending" | "denied" | "expired" | "denyRequestConfirmation";

export function RequestsTab() {
  const { isLoading } = useRequestsTable();
  const [openModal, setOpenModal] = useState<ModalType | undefined>(undefined);
  const [selectedRequest, setSelectedRequest] = useState<
    RequestsTableDataType | undefined
  >(undefined);

  const onRowClick = (row: RequestsTableDataType) => {
    setSelectedRequest(row);
    switch (row.status) {
      case "pending":
      case "denied":
      case "expired":
        setOpenModal(row.status as ModalType);
        return;
      default:
        setOpenModal(undefined);
    }
  };

  const closeModal = () => setOpenModal(undefined);

  const openDenyRequestConfirmationModal = () =>
    setOpenModal("denyRequestConfirmation");

  if (isLoading) {
    return null;
  }

  return (
    <Flex direction="column">
      <StyledFilteringSection>
        <RequestSearchBar />
        <RequestStatusSelect />
      </StyledFilteringSection>
      <RequestsTable onRowClick={onRowClick} />
      <RequestPendingModal
        isOpen={openModal === "pending"}
        onClose={closeModal}
        onCancel={openDenyRequestConfirmationModal}
        request={selectedRequest}
      />
      <RequestDeniedModal
        isOpen={openModal === "denied"}
        onClose={closeModal}
        request={selectedRequest}
      />
      <RequestExpiredModal
        isOpen={openModal === "expired"}
        onClose={closeModal}
        request={selectedRequest}
      />
      <DenyRequestConfirmationModal
        isOpen={openModal === "denyRequestConfirmation"}
        onClose={closeModal}
        request={selectedRequest}
      />
    </Flex>
  );
}
