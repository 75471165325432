import { ScrollRestoration } from "react-router-dom";

import { CurrentBenefitContextProvider } from "../../contexts/CurrentBenefitContext";
import { OrganisationAndBenefitsContextProvider } from "../../contexts/OrganisationAndBenefitsContext";
import { Navigation } from "../Navigation/Navigation";
import { Footer } from "./Footer";

export function Layout() {
  return (
    <OrganisationAndBenefitsContextProvider>
      <CurrentBenefitContextProvider>
        <ScrollRestoration />
        <Navigation />
        <Footer />
      </CurrentBenefitContextProvider>
    </OrganisationAndBenefitsContextProvider>
  );
}
