import { useCallback, useEffect, useState } from "react";

import { useTableParams } from "@vapaus/ui-v2";

import { useSearchBenefitContracts } from "../../../../../../api/benefitContracts";
import { useCurrentBenefitContext } from "../../../../../../contexts/CurrentBenefitContext";
import { useOrganisationAndBenefitsContext } from "../../../../../../contexts/OrganisationAndBenefitsContext";
import { ContractStatus } from "./ContractsTable";
import { mapContractStatusToApi } from "./mapContractStatus";

export function useContractsTable() {
  const { availableBenefitIds } = useOrganisationAndBenefitsContext();
  const { currentBenefitId } = useCurrentBenefitContext();
  const [shouldClearAllFilters, setShouldClearAllFilters] =
    useState<boolean>(false);

  const {
    q: searchQuery,
    selectedStatusFilter,
    sorting,
    pagination,
    setSorting,
    setPagination,
    setSearchQuery,
    setExtraParams,
  } = useTableParams<{
    selectedStatusFilter: ContractStatus | "";
  }>({ sortId: "startDate", sortDesc: true, selectedStatusFilter: "" });

  const sortFieldMapping: Record<string, string> = {
    contract: "user.last_name",
    startDate: "start_date",
    endDate: "effective_end_date",
    monthsLeft: "remaining_leasing_period_months",
    status: "state",
  };

  const { data: fetchedData, isLoading } = useSearchBenefitContracts(
    {
      benefitDefinitionIds: currentBenefitId
        ? [currentBenefitId]
        : availableBenefitIds,
      limit: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      q: searchQuery,
      ...(sortFieldMapping[sorting[0]?.id]
        ? {
            sortField: sortFieldMapping[sorting[0]?.id],
            sortDirection: sorting[0]?.desc ? "desc" : "asc",
          }
        : null),
      states: selectedStatusFilter
        ? mapContractStatusToApi(selectedStatusFilter)
        : undefined,
    },
    {
      enabled: availableBenefitIds.length > 0,
      keepPreviousData: true,
    },
  );

  const handleSelectedStatusFilterChange = useCallback(
    function handleSelectedStatusFilterChange(
      _: unknown,
      value: string | null,
    ) {
      setExtraParams({
        selectedStatusFilter: value as ContractStatus,
      });
    },
    [setExtraParams],
  );

  const clearFilters = () => {
    setShouldClearAllFilters(true);
    setSearchQuery("");
  };

  useEffect(() => {
    if (searchQuery === "" && shouldClearAllFilters) {
      setExtraParams({ selectedStatusFilter: "" });
      setShouldClearAllFilters(false);
    }
  }, [shouldClearAllFilters]);

  const areFiltersEmpty = !searchQuery && !selectedStatusFilter;

  return {
    searchQuery,
    setSearchQuery,
    selectedStatusFilter,
    handleSelectedStatusFilterChange,
    paginationOptions: {
      totalItems: fetchedData?.total ?? 0,
      pagination,
      setPagination,
    },
    sortingOptions: {
      sorting,
      setSorting,
    },
    fetchedData,
    isLoading,
    clearFilters,
    areFiltersEmpty,
  };
}
