import { upperFirst } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { BenefitDefinitionReadWithOrganisationSummary } from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import { useEnumEntries } from "@vapaus/shared-api";
import {
  Card3DIcon,
  CheckFillIcon,
  Flex,
  Tag,
  Typography,
} from "@vapaus/ui-v2";

import { useGetBenefitDefinitionAccessRules } from "../../../../api/benefitDefinitionAccessRule";
import { AuthenticationCard } from "./AuthenticationCard";

export function BenefitAuthentication() {
  const { t } = useTranslation();
  const { benefitDefinition } = useOutletContext<{
    benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
  }>();
  const { data: accessRule } = useGetBenefitDefinitionAccessRules(
    benefitDefinition?.accessRulesUuid,
  );

  const enableWorkEmail = Boolean(accessRule?.allowedEmailAddresses?.length);
  const enableManualRequest = Boolean(
    accessRule?.requestRequirement !== "never",
  );

  const { getEnumLabel } = useEnumEntries(
    "BenefitDefinitionAccessRulesRequestRequirements",
  );

  if (!benefitDefinition?.accessRulesUuid) {
    return (
      <TableEmptyState
        icon={<Card3DIcon size="md" />}
        title={t("admin:adminPage.benefitTab.authentication.noRules.title")}
        subtitle={t(
          "admin:adminPage.benefitTab.authentication.noRules.subtitle",
        )}
        padding="none"
      />
    );
  }

  if (!accessRule) return null;

  return (
    <Flex direction="column" gap="md">
      <Flex direction="column" gap="xs">
        <Typography weight="bold">
          {t("admin:adminPage.benefitTab.authentication.authRules.title")}
        </Typography>
        <Typography>
          {t("admin:adminPage.benefitTab.authentication.authRules.description")}
        </Typography>
      </Flex>
      <AuthenticationCard
        enabled={enableWorkEmail}
        title={t(
          "admin:adminPage.benefitTab.authentication.verifiedEmail.title",
        )}
        subtitle={t(
          "admin:adminPage.benefitTab.authentication.verifiedEmail.description",
        )}
      >
        {(accessRule.allowedEmailAddresses || []).map((email) => (
          <Tag
            key={email}
            text={
              <Flex as="span" gap="xxs">
                <CheckFillIcon size="sm" />{" "}
                <span>{email.replace(/^@/, "")}</span>
              </Flex>
            }
            variant="done"
          />
        ))}
      </AuthenticationCard>
      <AuthenticationCard
        enabled
        title={t(
          "admin:adminPage.benefitTab.authentication.personalEmail.title",
        )}
        subtitle={t(
          "admin:adminPage.benefitTab.authentication.personalEmail.description",
        )}
      />
      <AuthenticationCard
        enabled={enableManualRequest}
        title={t(
          "admin:adminPage.benefitTab.authentication.requestRequirement.title",
        )}
        subtitle={t(
          "admin:adminPage.benefitTab.authentication.requestRequirement.description",
        )}
        statusText={
          accessRule?.requestRequirement === "if needed"
            ? t(
                "admin:adminPage.benefitTab.authentication.requestRequirement.onlyForPersonalEmails",
              )
            : upperFirst(getEnumLabel(accessRule?.requestRequirement))
        }
      />
      <Flex direction="column" gap="xs">
        <Typography weight="bold">
          {t(
            "admin:adminPage.benefitTab.authentication.additionalIdentifier.title",
          )}
        </Typography>
        <Typography>
          {t(
            "admin:adminPage.benefitTab.authentication.additionalIdentifier.description",
          )}
        </Typography>
      </Flex>
      <AuthenticationCard
        enabled={benefitDefinition.isEmployeeNumberMandatory}
        title={t("admin:adminPage.benefitTab.authentication.employeeId.title")}
        subtitle={t(
          "admin:adminPage.benefitTab.authentication.employeeId.description",
        )}
      />
      <AuthenticationCard
        enabled={benefitDefinition.isCostCenterMandatory}
        title={t("admin:adminPage.benefitTab.authentication.costCenter.title")}
        subtitle={t(
          "admin:adminPage.benefitTab.authentication.costCenter.description",
        )}
      />
    </Flex>
  );
}
