import type { BikeBenefitContractState } from "@vapaus/api-codegen";
import { Flex } from "@vapaus/ui-v2";

import { ContractStatus } from "../../../../../../../components/ContractStatus";

type StatusCellProps = {
  status: BikeBenefitContractState;
};

export function StatusCell({ status }: StatusCellProps) {
  return (
    <Flex justify="flex-end">
      <ContractStatus status={status} />
    </Flex>
  );
}
