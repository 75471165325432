/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BikeBenefitContractAdminRead,
  BikeBenefitContractState,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBikeBenefitContractProviderOut,
} from "../models/index";
import {
  BikeBenefitContractAdminReadFromJSON,
  BikeBenefitContractAdminReadToJSON,
  BikeBenefitContractStateFromJSON,
  BikeBenefitContractStateToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBikeBenefitContractProviderOutFromJSON,
  PaginatedBikeBenefitContractProviderOutToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderBenefitProviderGetBikeBenefitContractsRequest {
  contractId: string;
}

export interface ProviderBenefitProviderSearchBikeBenefitContractsRequest {
  benefitDefinitionIds: Set<string>;
  userId?: string;
  states?: Set<BikeBenefitContractState>;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class ProviderBenefitApi extends runtime.BaseAPI {
  /**
   * Provider Get Bike Benefit Contracts
   */
  async providerBenefitProviderGetBikeBenefitContractsRaw(
    requestParameters: ProviderBenefitProviderGetBikeBenefitContractsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitContractAdminRead>> {
    if (
      requestParameters.contractId === null ||
      requestParameters.contractId === undefined
    ) {
      throw new runtime.RequiredError(
        "contractId",
        "Required parameter requestParameters.contractId was null or undefined when calling providerBenefitProviderGetBikeBenefitContracts.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/bike-benefit-contracts/{contract_id}`.replace(
          `{${"contract_id"}}`,
          encodeURIComponent(String(requestParameters.contractId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitContractAdminReadFromJSON(jsonValue),
    );
  }

  /**
   * Provider Get Bike Benefit Contracts
   */
  async providerBenefitProviderGetBikeBenefitContracts(
    requestParameters: ProviderBenefitProviderGetBikeBenefitContractsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitContractAdminRead> {
    const response =
      await this.providerBenefitProviderGetBikeBenefitContractsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Provider Search Bike Benefit Contracts
   */
  async providerBenefitProviderSearchBikeBenefitContractsRaw(
    requestParameters: ProviderBenefitProviderSearchBikeBenefitContractsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBikeBenefitContractProviderOut>> {
    if (
      requestParameters.benefitDefinitionIds === null ||
      requestParameters.benefitDefinitionIds === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionIds",
        "Required parameter requestParameters.benefitDefinitionIds was null or undefined when calling providerBenefitProviderSearchBikeBenefitContracts.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.benefitDefinitionIds) {
      queryParameters["benefit_definition_ids"] =
        requestParameters.benefitDefinitionIds;
    }

    if (requestParameters.userId !== undefined) {
      queryParameters["user_id"] = requestParameters.userId;
    }

    if (requestParameters.states) {
      queryParameters["states"] = requestParameters.states;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/bike-benefit-contracts`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBikeBenefitContractProviderOutFromJSON(jsonValue),
    );
  }

  /**
   * Provider Search Bike Benefit Contracts
   */
  async providerBenefitProviderSearchBikeBenefitContracts(
    requestParameters: ProviderBenefitProviderSearchBikeBenefitContractsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBikeBenefitContractProviderOut> {
    const response =
      await this.providerBenefitProviderSearchBikeBenefitContractsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
