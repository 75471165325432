import { ReactNode } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  GroupIcon,
  InsightsIcon,
  MobileNavigationButton,
  SettingsIcon,
  SupportIcon,
} from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";

export function MobileNavigationButtons(): ReactNode {
  const { t } = useTranslation();

  return (
    <>
      <NavLink to={Path.home}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={GroupIcon}
            label={t("admin:navigation.buttons.home")}
            isActivated={isActive}
          />
        )}
      </NavLink>
      <NavLink to={Path.reports}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={InsightsIcon}
            label={t("admin:navigation.buttons.reports")}
            isActivated={isActive}
          />
        )}
      </NavLink>
      <NavLink to={Path.support}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={SupportIcon}
            label={t("admin:navigation.buttons.support")}
            isActivated={isActive}
          />
        )}
      </NavLink>
      <NavLink to={Path.admin}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={SettingsIcon}
            label={t("admin:navigation.buttons.admin")}
            isActivated={isActive}
          />
        )}
      </NavLink>
    </>
  );
}
