import { useQuery } from "@tanstack/react-query";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  ProviderBenefitDefinitionsApi,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const providerBenefitDefinitionsAPI = new ProviderBenefitDefinitionsApi(
  apiConfiguration,
);

export const useGetBenefitDefinition = (
  id?: string,
  options?: UseQueryOptions<BenefitDefinitionReadWithOrganisationSummary>,
) =>
  useQuery<BenefitDefinitionReadWithOrganisationSummary, ResponseError>({
    queryKey: ["benefitDefinitions", id],
    queryFn: () =>
      providerBenefitDefinitionsAPI.providerBenefitDefinitionsProviderGetBenefitDefinition(
        {
          benefitDefinitionId: id!,
        },
      ),
    ...options,
    enabled: !!id,
  });
