/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BenefitDefinitionAccessRequestStatus,
  BenefitDefinitionAccessRequestWithUserRead,
  BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest,
  BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBenefitDefinitionAccessRequestWithUserOut,
} from "../models/index";
import {
  BenefitDefinitionAccessRequestStatusFromJSON,
  BenefitDefinitionAccessRequestStatusToJSON,
  BenefitDefinitionAccessRequestWithUserReadFromJSON,
  BenefitDefinitionAccessRequestWithUserReadToJSON,
  BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequestFromJSON,
  BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequestToJSON,
  BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequestFromJSON,
  BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequestToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBenefitDefinitionAccessRequestWithUserOutFromJSON,
  PaginatedBenefitDefinitionAccessRequestWithUserOutToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderBenefitDefinitionAccessRequestsAcceptAccessRequestRequest {
  benefitDefinitionAccessRequestId: string;
  bodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest?: BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest;
}

export interface ProviderBenefitDefinitionAccessRequestsGetRequest {
  benefitDefinitionAccessRequestId: string;
}

export interface ProviderBenefitDefinitionAccessRequestsRefuseAccessRequestRequest {
  benefitDefinitionAccessRequestId: string;
  bodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest?: BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest;
}

export interface ProviderBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequestsRequest {
  benefitDefinitionIds: Set<string>;
  userId?: string;
  status?: Array<BenefitDefinitionAccessRequestStatus>;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class ProviderBenefitDefinitionAccessRequestsApi extends runtime.BaseAPI {
  /**
   * Accept Access Request
   */
  async providerBenefitDefinitionAccessRequestsAcceptAccessRequestRaw(
    requestParameters: ProviderBenefitDefinitionAccessRequestsAcceptAccessRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionAccessRequestWithUserRead>> {
    if (
      requestParameters.benefitDefinitionAccessRequestId === null ||
      requestParameters.benefitDefinitionAccessRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionAccessRequestId",
        "Required parameter requestParameters.benefitDefinitionAccessRequestId was null or undefined when calling providerBenefitDefinitionAccessRequestsAcceptAccessRequest.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/benefit-definition-access-requests/{benefit_definition_access_request_id}/accept`.replace(
          `{${"benefit_definition_access_request_id"}}`,
          encodeURIComponent(
            String(requestParameters.benefitDefinitionAccessRequestId),
          ),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequestToJSON(
          requestParameters.bodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionAccessRequestWithUserReadFromJSON(jsonValue),
    );
  }

  /**
   * Accept Access Request
   */
  async providerBenefitDefinitionAccessRequestsAcceptAccessRequest(
    requestParameters: ProviderBenefitDefinitionAccessRequestsAcceptAccessRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionAccessRequestWithUserRead> {
    const response =
      await this.providerBenefitDefinitionAccessRequestsAcceptAccessRequestRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Get
   */
  async providerBenefitDefinitionAccessRequestsGetRaw(
    requestParameters: ProviderBenefitDefinitionAccessRequestsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionAccessRequestWithUserRead>> {
    if (
      requestParameters.benefitDefinitionAccessRequestId === null ||
      requestParameters.benefitDefinitionAccessRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionAccessRequestId",
        "Required parameter requestParameters.benefitDefinitionAccessRequestId was null or undefined when calling providerBenefitDefinitionAccessRequestsGet.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/benefit-definition-access-requests/{benefit_definition_access_request_id}`.replace(
          `{${"benefit_definition_access_request_id"}}`,
          encodeURIComponent(
            String(requestParameters.benefitDefinitionAccessRequestId),
          ),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionAccessRequestWithUserReadFromJSON(jsonValue),
    );
  }

  /**
   * Get
   */
  async providerBenefitDefinitionAccessRequestsGet(
    requestParameters: ProviderBenefitDefinitionAccessRequestsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionAccessRequestWithUserRead> {
    const response = await this.providerBenefitDefinitionAccessRequestsGetRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Refuse Access Request
   */
  async providerBenefitDefinitionAccessRequestsRefuseAccessRequestRaw(
    requestParameters: ProviderBenefitDefinitionAccessRequestsRefuseAccessRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionAccessRequestWithUserRead>> {
    if (
      requestParameters.benefitDefinitionAccessRequestId === null ||
      requestParameters.benefitDefinitionAccessRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionAccessRequestId",
        "Required parameter requestParameters.benefitDefinitionAccessRequestId was null or undefined when calling providerBenefitDefinitionAccessRequestsRefuseAccessRequest.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/benefit-definition-access-requests/{benefit_definition_access_request_id}/deny`.replace(
          `{${"benefit_definition_access_request_id"}}`,
          encodeURIComponent(
            String(requestParameters.benefitDefinitionAccessRequestId),
          ),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequestToJSON(
          requestParameters.bodyProviderBenefitDefinitionAccessRequestsRefuseAccessRequest,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionAccessRequestWithUserReadFromJSON(jsonValue),
    );
  }

  /**
   * Refuse Access Request
   */
  async providerBenefitDefinitionAccessRequestsRefuseAccessRequest(
    requestParameters: ProviderBenefitDefinitionAccessRequestsRefuseAccessRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionAccessRequestWithUserRead> {
    const response =
      await this.providerBenefitDefinitionAccessRequestsRefuseAccessRequestRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Search Benefit Definition Access Requests
   */
  async providerBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequestsRaw(
    requestParameters: ProviderBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequestsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<PaginatedBenefitDefinitionAccessRequestWithUserOut>
  > {
    if (
      requestParameters.benefitDefinitionIds === null ||
      requestParameters.benefitDefinitionIds === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionIds",
        "Required parameter requestParameters.benefitDefinitionIds was null or undefined when calling providerBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequests.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.benefitDefinitionIds) {
      queryParameters["benefit_definition_ids"] =
        requestParameters.benefitDefinitionIds;
    }

    if (requestParameters.userId !== undefined) {
      queryParameters["user_id"] = requestParameters.userId;
    }

    if (requestParameters.status) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/benefit-definition-access-requests`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBenefitDefinitionAccessRequestWithUserOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Benefit Definition Access Requests
   */
  async providerBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequests(
    requestParameters: ProviderBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequestsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBenefitDefinitionAccessRequestWithUserOut> {
    const response =
      await this.providerBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequestsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
