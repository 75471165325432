import { useMemo } from "react";

import { ColumnDef, Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import type { BikeBenefitContractProviderRead } from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import {
  Bike3DIcon,
  Lens3DIcon,
  PedalBikeIcon,
  Table,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { EmployeeCell } from "../../../../../../components/EmployeeCell";
import { renderBenefitName } from "../../../../../../utils/renderBenefitName";
import { DateCell } from "./components/DateCell";
import { MonthsLeftCell } from "./components/MonthsLeftCell";
import { StatusCell } from "./components/StatusCell";
import { useContractsTable } from "./useContractsTable";

export type ContractStatus =
  | "active"
  | "cancelled"
  | "ended"
  | "invalidated"
  | "scheduled"
  | "pending"
  | "paymentRequired";

export type Employee = {
  name: string;
  email: string;
};

type ContractsTableProps = {
  onRowClick: (row: BikeBenefitContractProviderRead) => void;
};

export function ContractsTable({ onRowClick }: ContractsTableProps) {
  const { t } = useTranslation();
  const { isXs, isSm, isMd } = useBreakpoints();
  const { areFiltersEmpty, paginationOptions, sortingOptions, fetchedData } =
    useContractsTable();

  const columns = useMemo<ColumnDef<BikeBenefitContractProviderRead>[]>(
    () => [
      {
        id: "contract",
        header: t("admin:home.contracts.table.contract"),
        cell: ({ row }) => (
          <EmployeeCell
            icon={PedalBikeIcon}
            employeeName={row.original.user.fullName!}
            employeeEmail={row.original.beneficiaryEmail}
            additionalData={renderBenefitName(
              row.original.benefitDefinition.name,
              row.original.benefitDefinition.active,
              t,
            )}
          />
        ),
        accessorFn: (row) => row.user,
        enableSorting: true,
        size: 30,
      },
      ...(!isXs && !isSm && !isMd
        ? [
            {
              id: "startDate",
              header: t("admin:home.contracts.table.startDate"),
              cell: ({
                row,
              }: {
                row: Row<BikeBenefitContractProviderRead>;
              }) => <DateCell date={row.original.startDate} />,
              accessorFn: ({
                row,
              }: {
                row: Row<BikeBenefitContractProviderRead>;
              }) => row.original.startDate,
              enableSorting: true,
            },
          ]
        : []),
      ...(!isXs && !isSm
        ? [
            {
              id: "endDate",
              header: t("admin:home.contracts.table.endDate"),
              cell: ({
                row,
              }: {
                row: Row<BikeBenefitContractProviderRead>;
              }) => <DateCell date={row.original.effectiveEndDate} />,
              accessorFn: ({
                row,
              }: {
                row: Row<BikeBenefitContractProviderRead>;
              }) => row.original.effectiveEndDate,
              enableSorting: true,
            },
          ]
        : []),
      ...(!isXs
        ? [
            {
              id: "monthsLeft",
              header: t("admin:home.contracts.table.monthsLeft"),
              cell: ({
                row,
              }: {
                row: Row<BikeBenefitContractProviderRead>;
              }) => (
                <MonthsLeftCell
                  monthsLeft={row.original.remainingLeasingPeriodMonths}
                />
              ),
              accessorFn: ({
                row,
              }: {
                row: Row<BikeBenefitContractProviderRead>;
              }) => row.original.remainingLeasingPeriodMonths,
              enableSorting: true,
              size: !isSm ? 16 : 20,
            },
          ]
        : []),
      {
        id: "status",
        header: t("admin:home.contracts.table.status"),
        cell: ({ row }) => <StatusCell status={row.original.state} />,
        accessorFn: (row) => row.state,
        enableSorting: true,
        size: 15,
      },
    ],
    [t, isXs, isSm, isMd],
  );

  return (
    <Table<BikeBenefitContractProviderRead>
      data={fetchedData?.items ?? []}
      columns={columns}
      paginationOptions={paginationOptions}
      sortingOptions={sortingOptions}
      emptyStateComponent={
        areFiltersEmpty ? (
          <TableEmptyState
            icon={<Bike3DIcon size="md" />}
            title={t("admin:home.contracts.table.emptyState.title")}
            subtitle={t("admin:home.contracts.table.emptyState.subtitle")}
          />
        ) : (
          <TableEmptyState
            icon={<Lens3DIcon size="md" />}
            title={t("admin:home.contracts.table.noResults.title")}
            subtitle={t("admin:home.contracts.table.noResults.subtitle")}
          />
        )
      }
      onRowClick={onRowClick}
    />
  );
}
