/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BenefitDefinitionRead } from "./BenefitDefinitionRead";
import {
  BenefitDefinitionReadFromJSON,
  BenefitDefinitionReadFromJSONTyped,
  BenefitDefinitionReadToJSON,
} from "./BenefitDefinitionRead";

/**
 *
 * @export
 * @interface PaginatedBenefitDefinitionOut
 */
export interface PaginatedBenefitDefinitionOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedBenefitDefinitionOut
   */
  total: number;
  /**
   *
   * @type {Array<BenefitDefinitionRead>}
   * @memberof PaginatedBenefitDefinitionOut
   */
  items: Array<BenefitDefinitionRead>;
}

/**
 * Check if a given object implements the PaginatedBenefitDefinitionOut interface.
 */
export function instanceOfPaginatedBenefitDefinitionOut(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedBenefitDefinitionOutFromJSON(
  json: any,
): PaginatedBenefitDefinitionOut {
  return PaginatedBenefitDefinitionOutFromJSONTyped(json, false);
}

export function PaginatedBenefitDefinitionOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBenefitDefinitionOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(BenefitDefinitionReadFromJSON),
  };
}

export function PaginatedBenefitDefinitionOutToJSON(
  value?: PaginatedBenefitDefinitionOut | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(BenefitDefinitionReadToJSON),
  };
}
