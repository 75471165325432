import { ReactNode, createContext, useContext, useMemo, useState } from "react";

export type CurrentBenefitContextValue = {
  currentBenefitId?: string;
  setCurrentBenefitId: (benefitId?: string) => void;
};

export const CurrentBenefitContext = createContext<CurrentBenefitContextValue>({
  currentBenefitId: undefined,
  setCurrentBenefitId: () => {},
});

type CurrentBenefitContextProviderProps = {
  children: ReactNode;
};

export const CurrentBenefitContextProvider = ({
  children,
}: CurrentBenefitContextProviderProps) => {
  const [currentBenefitId, setCurrentBenefitId] = useState<string>();

  const changeCurrentBenefitId = (benefitId?: string) =>
    setCurrentBenefitId(benefitId);

  const contextValue = useMemo(
    () => ({
      currentBenefitId,
      setCurrentBenefitId: changeCurrentBenefitId,
    }),
    [currentBenefitId],
  );

  return (
    <CurrentBenefitContext.Provider value={contextValue}>
      {children}
    </CurrentBenefitContext.Provider>
  );
};

export const useCurrentBenefitContext = () => useContext(CurrentBenefitContext);
