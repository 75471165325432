import * as React from "react";

import { useTranslation } from "react-i18next";

import { SearchBar } from "@vapaus/ui-v2";

import { useEmployeesTable } from "./EmployeesTable/useEmployeesTable";

export function EmployeesSearchBar() {
  const { t } = useTranslation();
  const { searchQuery, setSearchQuery } = useEmployeesTable();

  return (
    <SearchBar
      onSearch={setSearchQuery}
      defaultValue={searchQuery}
      placeholder={t("commonV2:search")}
      fullWidth
    />
  );
}
