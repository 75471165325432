/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserProviderUpdate
 */
export interface UserProviderUpdate {
  /**
   *
   * @type {string}
   * @memberof UserProviderUpdate
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof UserProviderUpdate
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof UserProviderUpdate
   */
  employeeNumber?: string;
}

/**
 * Check if a given object implements the UserProviderUpdate interface.
 */
export function instanceOfUserProviderUpdate(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserProviderUpdateFromJSON(json: any): UserProviderUpdate {
  return UserProviderUpdateFromJSONTyped(json, false);
}

export function UserProviderUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserProviderUpdate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    company: !exists(json, "company") ? undefined : json["company"],
    costCenter: !exists(json, "cost_center") ? undefined : json["cost_center"],
    employeeNumber: !exists(json, "employee_number")
      ? undefined
      : json["employee_number"],
  };
}

export function UserProviderUpdateToJSON(
  value?: UserProviderUpdate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    company: value.company,
    cost_center: value.costCenter,
    employee_number: value.employeeNumber,
  };
}
