import { useEffect } from "react";

type JimoSetupProps = {
  projectId: string;
};

export function JimoSetup({ projectId }: JimoSetupProps) {
  useEffect(() => {
    if (window.jimo != null) {
      return;
    }
    window.jimo = [];

    const s = document.createElement("script");

    s.type = "text/javascript";
    s.async = true;
    s.src = "https://undercity.usejimo.com/jimo-invader.js";
    window["JIMO_PROJECT_ID"] = projectId;

    document.getElementsByTagName("head")[0].appendChild(s);
  }, []);

  return null;
}
