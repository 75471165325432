import styled from "styled-components";

import { BikeBenefitContractProviderRead } from "@vapaus/api-codegen";
import { CircularIcon, Flex, PedalBikeIcon, Typography } from "@vapaus/ui-v2";

type BikeCellProps = {
  contract: BikeBenefitContractProviderRead;
};

export function BikeCell({ contract }: BikeCellProps) {
  return (
    <Flex>
      <CircularIcon icon={PedalBikeIcon} />
      <StyledFlex direction="column" gap="xss">
        <Typography weight="bold">{contract.vapausCode}</Typography>
        <Typography variant="bodySm">
          {contract.bike.bikeBrand.name} {contract.bike.model}
        </Typography>
        <Typography variant="bodySm" weight="bold">
          {contract.benefitDefinition.name}
        </Typography>
      </StyledFlex>
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;
