import React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { UserBenefitActivationRead } from "@vapaus/api-codegen";
import {
  BadgeIcon,
  ChevronRightIcon,
  CircularIcon,
  Flex,
  Tag,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { useGetBenefitDefinition } from "../../../../../../api/benefitDefinitions";
import { renderBenefitName } from "../../../../../../utils/renderBenefitName";
import { usePermissionMessage } from "../../hooks/usePermissionMessage";
import { getPermissionStatus } from "../../utils/getPermissionStatus";

type BikeBenefitCellProps = {
  activation: UserBenefitActivationRead;
};

export function BikeBenefitCell({ activation }: BikeBenefitCellProps) {
  const { t } = useTranslation();
  const { data: benefitDefinition } = useGetBenefitDefinition(
    activation?.benefitDefinitionId,
  );
  const permissionStatus = getPermissionStatus(
    t,
    activation,
    benefitDefinition?.active,
  );
  const permissionMessage = usePermissionMessage(permissionStatus, activation);

  const { isXs } = useBreakpoints();

  return (
    <Flex justify="space-between" xsDirection="column" xsAlign="flex-start">
      <Flex>
        {!isXs ? <CircularIcon icon={BadgeIcon} /> : null}
        <StyledFlex direction="column" gap="xss">
          <Typography>
            {renderBenefitName(
              benefitDefinition?.name ?? "",
              benefitDefinition?.active ?? true,
              t,
            )}
          </Typography>
          {permissionMessage ? (
            <Typography variant="bodySm" weight="bold">
              {permissionMessage.shortMessage}
            </Typography>
          ) : null}
        </StyledFlex>
      </Flex>
      <Flex>
        {permissionStatus ? (
          <Tag
            variant={permissionStatus.variant}
            text={permissionStatus.statusText}
          />
        ) : null}
        {!isXs ? <ChevronRightIcon /> : null}
      </Flex>
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;
