import { BenefitDefinitionAccessRequestStatus } from "@vapaus/api-codegen";

import { useGetAccessRequests } from "../../../../api/accessRequests";
import { useCurrentBenefitContext } from "../../../../contexts/CurrentBenefitContext";
import { useOrganisationAndBenefitsContext } from "../../../../contexts/OrganisationAndBenefitsContext";

export function useTotalPendingRequests() {
  const { availableBenefitIds } = useOrganisationAndBenefitsContext();
  const { currentBenefitId } = useCurrentBenefitContext();

  const { data: pendingRequests, isLoading } = useGetAccessRequests(
    {
      benefitDefinitionIds: currentBenefitId
        ? [currentBenefitId]
        : availableBenefitIds,
      status: [BenefitDefinitionAccessRequestStatus.Pending],
    },
    {
      enabled: availableBenefitIds.length > 0,
    },
  );

  return {
    totalPendingRequests: pendingRequests?.total,
    isLoading,
  };
}
