import { useTranslation } from "react-i18next";

import { type LinksGroupProps, PageFooter } from "@vapaus/common-v2";

export function FooterSweden() {
  const { t } = useTranslation();

  const linkGroups: LinksGroupProps[] = [
    {
      title: t("admin:footer.benefitBikes.title"),
      links: [
        {
          label: t("admin:footer.benefitBikes.forEmployees.label"),
          link: "https://vapaus.se/formanscyklar-for-anstallda",
        },
        {
          label: t("admin:footer.benefitBikes.forEmployers.label"),
          link: "https://vapaus.se/formanscykeltjanst-for-arbetsgivare",
        },
      ],
    },
    {
      title: t("admin:footer.about.title"),
      links: [
        {
          label: t("admin:footer.about.whoWeAre.label"),
          link: "https://vapaus.se/om-oss",
        },
        {
          label: t("admin:footer.about.blog.label"),
          link: "https://vapaus.se/blog",
        },
        {
          label: t("admin:footer.about.careers.label"),
          link: "https://careers.vapaus.io/",
        },
        {
          label: t("admin:footer.about.sustainability.label"),
          link: "https://www.vapaus.io/en/sustainability",
        },
      ],
    },
    {
      title: t("admin:footer.support.title"),
      links: [
        {
          label: t("admin:sweden.footer.support.faq.label"),
          link: "https://vapaus.se/formanscykeltjanst-for-arbetsgivare",
        },
        {
          label: t("admin:sweden.footer.support.portalGuide.label"),
          link: "https://tyosuhdepyora.vapaus.io/fi/provider-portal-guide",
        },
        {
          label: t("admin:footer.support.contactUs.label"),
          link: "https://vapaus.se/kontakt",
        },
      ],
    },
  ];
  return <PageFooter linkGroups={linkGroups} />;
}
