import { useMutation, useQuery } from "@tanstack/react-query";

import {
  type BenefitDefinitionAccessRequestWithUserRead,
  type PaginatedBenefitDefinitionAccessRequestWithUserOut,
  ProviderBenefitDefinitionAccessRequestsAcceptAccessRequestRequest,
  ProviderBenefitDefinitionAccessRequestsApi,
  ProviderBenefitDefinitionAccessRequestsRefuseAccessRequestRequest,
  ProviderBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequestsRequest,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const providerBenefitDefinitionAccessRequestsAPI =
  new ProviderBenefitDefinitionAccessRequestsApi(apiConfiguration);

export const useGetAccessRequests = (
  params: Omit<
    ProviderBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequestsRequest,
    "benefitDefinitionIds"
  > & { benefitDefinitionIds: Array<string> },
  options?: UseQueryOptions<PaginatedBenefitDefinitionAccessRequestWithUserOut>,
) => {
  return useQuery<
    PaginatedBenefitDefinitionAccessRequestWithUserOut,
    ResponseError
  >({
    queryKey: ["accessRequests", params],
    queryFn: () =>
      providerBenefitDefinitionAccessRequestsAPI.providerBenefitDefinitionAccessRequestsSearchBenefitDefinitionAccessRequests(
        {
          ...params,
          benefitDefinitionIds: new Set(params.benefitDefinitionIds),
        },
      ),
    ...options,
  });
};

export const useDenyAccessRequest = (
  params: ProviderBenefitDefinitionAccessRequestsRefuseAccessRequestRequest,
) => {
  return useMutation<BenefitDefinitionAccessRequestWithUserRead, ResponseError>(
    {
      mutationFn: () =>
        providerBenefitDefinitionAccessRequestsAPI.providerBenefitDefinitionAccessRequestsRefuseAccessRequest(
          params,
        ),
    },
  );
};

export const useApproveAccessRequest = (
  params: ProviderBenefitDefinitionAccessRequestsAcceptAccessRequestRequest,
) => {
  return useMutation<BenefitDefinitionAccessRequestWithUserRead, ResponseError>(
    {
      mutationFn: () =>
        providerBenefitDefinitionAccessRequestsAPI.providerBenefitDefinitionAccessRequestsAcceptAccessRequest(
          params,
        ),
    },
  );
};
