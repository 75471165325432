import { useMutation, useQuery } from "@tanstack/react-query";

import type {
  ProviderNotificationCategory,
  ProviderNotificationPreference,
  ResponseError,
} from "@vapaus/api-codegen";
import { ProviderNotificationPreferencesApi } from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const notificationsPreferencesApi =
  new ProviderNotificationPreferencesApi(apiConfiguration);

export const useGetNotificationPreferences = (
  options?: UseQueryOptions<ProviderNotificationPreference[]>,
) =>
  useQuery<ProviderNotificationPreference[], ResponseError>({
    queryKey: ["notificationPreferences"],
    queryFn: () =>
      notificationsPreferencesApi.providerNotificationPreferencesProviderGetNotificationsPreferences(),
    ...options,
  });

export const useUpdateNotificationPreferences = () =>
  useMutation<
    ProviderNotificationPreference[],
    ResponseError,
    ProviderNotificationCategory[]
  >({
    mutationFn: (categories: ProviderNotificationCategory[]) =>
      notificationsPreferencesApi.providerNotificationPreferencesProviderUpdateNotificationsPreferences(
        {
          providerNotificationCategory: categories,
        },
      ),
  });
