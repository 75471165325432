import { Navigate, RouteObject } from "react-router-dom";

import { Path } from "../../../../constants/paths";
import { AdminSettingsBenefitsTab } from "./AdminSettingsBenefitsTab";
import { BenefitAuthentication } from "./BenefitAuthentication";
import { BenefitGuideline } from "./BenefitGuideline";

export const adminSettingsBenefitsTabRoutes: RouteObject = {
  path: Path.adminBenefits,
  element: <AdminSettingsBenefitsTab />,
  children: [
    {
      index: true,
      path: ":benefitId",
      element: <Navigate to={Path.adminBenefitsGuideline} />,
    },
    {
      path: ":benefitId/" + Path.adminBenefitsGuideline,
      element: <BenefitGuideline />,
    },
    {
      path: ":benefitId/" + Path.adminBenefitsAuthentication,
      element: <BenefitAuthentication />,
    },
  ],
};
