import { useState } from "react";

import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { UserBenefitActivationRead, UserRead } from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import { Card, Lens3DIcon, Table } from "@vapaus/ui-v2";

import { PermissionModal } from "../PermissionModal";
import { BikeBenefitCell } from "./BikeBenefitCell";
import { usePermissionsTable } from "./usePermissionsTable";

type PermissionsTableProps = {
  user: UserRead;
};
export function PermissionsTable({ user }: PermissionsTableProps) {
  const { t } = useTranslation();
  const [selectedPermission, setSelectedPermission] =
    useState<UserBenefitActivationRead>();
  const { paginationOptions, sortingOptions, data } = usePermissionsTable(
    user.id,
  );

  const columns: ColumnDef<UserBenefitActivationRead>[] = [
    {
      id: "bikeBenefit",
      header: t("admin:employeeViewPage.permissions.table.bikeBenefit"),
      cell: ({ row }) => <BikeBenefitCell activation={row.original} />,
      accessorFn: (row) => row.benefitDefinitionId,
      enableSorting: true,
    },
  ];

  return (
    <Card title={t("admin:employeeViewPage.permissions.table.title")}>
      <Table<UserBenefitActivationRead>
        data={data}
        columns={columns}
        paginationOptions={paginationOptions}
        sortingOptions={sortingOptions}
        emptyStateComponent={
          <TableEmptyState
            icon={<Lens3DIcon size="sm" />}
            title={t(
              "admin:employeeViewPage.permissions.table.emptyState.title",
            )}
            subtitle={t(
              "admin:employeeViewPage.permissions.table.emptyState.subtitle",
            )}
          />
        }
        onRowClick={setSelectedPermission}
      />
      <PermissionModal
        activation={selectedPermission}
        onClose={() => setSelectedPermission(undefined)}
        user={user}
      />
    </Card>
  );
}
