import * as React from "react";

import { useTranslation } from "react-i18next";

import { BadgeIcon, DotFilledIcon, Select } from "@vapaus/ui-v2";

import { useEmployeesTable } from "./EmployeesTable/useEmployeesTable";

export function PermissionStatusSelect() {
  const { t } = useTranslation();
  const { selectedStatusFilter, handleSelectedStatusFilterChange } =
    useEmployeesTable();

  const permissionStatusSelectOptions = [
    {
      icon: <BadgeIcon />,
      value: "",
      label: t("admin:home.employees.permissionStatus.allStatuses"),
    },
    {
      icon: <DotFilledIcon color="secondary1.main" />,
      value: "approved",
      label: t("admin:home.employees.permissionStatus.approved"),
    },
    {
      icon: <DotFilledIcon color="primary1.lighter" />,
      value: "no_activation",
      label: t(
        "admin:home.employees.permissionStatus.approvedForCurrentContract",
      ),
      tooltip: t(
        "admin:home.employees.permissionStatus.tooltip.approvedForCurrentContract",
      ),
    },
    {
      icon: <DotFilledIcon color="secondary2.main" />,
      value: "revoked",
      label: t("admin:home.employees.permissionStatus.revoked"),
    },
  ];

  return (
    <Select<string>
      options={permissionStatusSelectOptions}
      value={selectedStatusFilter}
      onChange={handleSelectedStatusFilterChange}
    />
  );
}
