import type { BikeBenefitContractState } from "@vapaus/api-codegen";

import { ContractStatus } from "./ContractsTable";

export function mapContractStatusToApi(
  status: ContractStatus,
): BikeBenefitContractState[] {
  switch (status) {
    case "paymentRequired":
      return ["cancelled_pending_payment", "ended_pending_payment"];
    case "pending":
      return [
        "active_pending_revision",
        "active_pending_correction",
        "active_pending_redemption",
        "active_pending_return",
      ];
    default:
      return [status as BikeBenefitContractState];
  }
}
