/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { EmployeeUser } from "./EmployeeUser";
import {
  EmployeeUserFromJSON,
  EmployeeUserFromJSONTyped,
  EmployeeUserToJSON,
} from "./EmployeeUser";

/**
 *
 * @export
 * @interface PaginatedEmployeeUserOut
 */
export interface PaginatedEmployeeUserOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedEmployeeUserOut
   */
  total: number;
  /**
   *
   * @type {Array<EmployeeUser>}
   * @memberof PaginatedEmployeeUserOut
   */
  items: Array<EmployeeUser>;
}

/**
 * Check if a given object implements the PaginatedEmployeeUserOut interface.
 */
export function instanceOfPaginatedEmployeeUserOut(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedEmployeeUserOutFromJSON(
  json: any,
): PaginatedEmployeeUserOut {
  return PaginatedEmployeeUserOutFromJSONTyped(json, false);
}

export function PaginatedEmployeeUserOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedEmployeeUserOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(EmployeeUserFromJSON),
  };
}

export function PaginatedEmployeeUserOutToJSON(
  value?: PaginatedEmployeeUserOut | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(EmployeeUserToJSON),
  };
}
