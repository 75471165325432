import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as zod from "zod";
import { z } from "zod";

import type {
  BenefitDefinitionAccessRequestWithUserRead,
  UserMepcoIntegrationSettingsRead,
  UserRead,
} from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { useToaster } from "@vapaus/ui-v2";

import { providerBenefitDefinitionAccessRequestsAPI } from "../../../../../../api/accessRequests";
import {
  providerMepcoApi,
  useGetUserMepcoIntegrationSettings,
} from "../../../../../../api/mepco";
import { userApi } from "../../../../../../api/user";
import { useIsMepcoIntegrationEnabled } from "../../../../../../hooks/useIsMepcoIntegrationEnabled";
import { RequestsTableDataType } from "../RequestsTable/RequestsTable";

type UseRequestPendingModalProps = {
  request?: RequestsTableDataType;
  onClose: () => void;
};

const pendingRequestFormSchema = zod.object({
  benefitDefinitionId: zod.string().min(1),
  costCenter: zod.string(),
  employeeId: zod.string(),
  payGroupId: zod.string(),
  payComponent: zod.string(),
});

export type PendingRequestFormSchema = z.infer<typeof pendingRequestFormSchema>;

export function useRequestPendingModal({
  request,
  onClose,
}: UseRequestPendingModalProps) {
  const { t } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { organisationId } = useParams();
  const isMepcoIntegrationEnabled = useIsMepcoIntegrationEnabled();
  const { data: mepcoIntegrationSettings } = useGetUserMepcoIntegrationSettings(
    request?.employee?.id,
    organisationId,
    {
      enabled: isMepcoIntegrationEnabled,
    },
  );

  const { formMethods, mutation } = useFormMutation<
    PendingRequestFormSchema,
    (
      | UserMepcoIntegrationSettingsRead
      | UserRead
      | BenefitDefinitionAccessRequestWithUserRead
      | undefined
    )[]
  >({
    mutationFn: (data) =>
      Promise.all(
        [
          userApi.providerUsersProviderUpdateUser({
            userId: request?.employee.id ?? "",
            userProviderUpdate: {
              costCenter: data.costCenter,
              employeeNumber: data.employeeId,
            },
          }),
          providerBenefitDefinitionAccessRequestsAPI.providerBenefitDefinitionAccessRequestsAcceptAccessRequest(
            {
              benefitDefinitionAccessRequestId: request?.accessRequestId ?? "",
              bodyProviderBenefitDefinitionAccessRequestsAcceptAccessRequest: {
                benefitDefinitionId: data.benefitDefinitionId,
              },
            },
          ),
          isMepcoIntegrationEnabled
            ? providerMepcoApi.providerMepcoSetUserMepcoIntegrationSettings({
                organisationId: organisationId!,
                userId: request?.employee.id ?? "",
                userMepcoIntegrationSettingsCreateUpdate: {
                  payGroupId: data.payGroupId,
                  payComponent: data.payComponent,
                },
              })
            : undefined,
        ].filter(Boolean),
      ),
    onSuccess: async () => {
      onClose();
      toaster.add(
        "success",
        t("admin:home.requests.pendingModal.approveRequestSuccessMessage"),
      );
      await queryClient.invalidateQueries(["accessRequests"]);
      await queryClient.invalidateQueries(["user"]);
    },
  });

  useEffect(() => {
    if (request) {
      formMethods.reset({
        benefitDefinitionId: request?.benefitDefinitionId,
        employeeId: request?.employee.employeeId ?? "",
        costCenter: request?.employee.costCenter ?? "",
        payGroupId: mepcoIntegrationSettings?.payGroupId ?? "",
        payComponent: mepcoIntegrationSettings?.payComponent ?? "",
      });
    }
  }, [request, mepcoIntegrationSettings]);

  return { formMethods, mutation };
}
