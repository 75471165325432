import * as React from "react";
import { useMemo } from "react";

import { ColumnDef, Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import type { EmployeeBenefitActivationStatus } from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import {
  BadgeIcon,
  Card3DIcon,
  Lens3DIcon,
  Table,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { EmployeeCell } from "../../../../../../components/EmployeeCell";
import { Path } from "../../../../../../constants";
import { BenefitCell } from "./components/BenefitCell";
import { PermissionStatusCell } from "./components/PermissionStatusCell";
import { useEmployeesTable } from "./useEmployeesTable";

export type Employee = {
  id: string;
  name: string;
  email: string;
  employeeId: string;
  costCenter: string;
};

export type BenefitDefinition = {
  name: string;
  active: boolean;
};

export type EmployeesTableDataType = {
  employee: Employee;
  benefitDefinitions: BenefitDefinition[];
  status: EmployeeBenefitActivationStatus;
};

export function EmployeesTable() {
  const { t } = useTranslation();
  const { isXs, isSm } = useBreakpoints();
  const navigate = useNavigate();
  const {
    areFiltersEmpty,
    paginationOptions,
    sortingOptions,
    fetchedData: data,
  } = useEmployeesTable();

  const getTableRows = (): EmployeesTableDataType[] => {
    if (!data) {
      return [];
    }

    return data.items.map((item) => ({
      employee: {
        id: item.id,
        name: item.fullName ?? "",
        email: item.benefitEmail,
        employeeId: item.employeeNumber ?? "",
        costCenter: item.costCenter ?? "",
      },
      benefitDefinitions: item.benefitDefinitions.map((benefitDefinition) => ({
        name: benefitDefinition.name,
        active: benefitDefinition.active,
      })),
      status: item.benefitActivationStatus,
    }));
  };

  const getEmployeeCellAdditionalData = (
    employeeId: string,
    costCenter: string,
  ) => {
    return [employeeId, costCenter].filter(Boolean).join(" · ");
  };

  const columns = useMemo<ColumnDef<EmployeesTableDataType>[]>(
    () => [
      {
        id: "employee",
        header: t("admin:home.employees.table.employee"),
        cell: ({ row }) => (
          <EmployeeCell
            icon={BadgeIcon}
            employeeName={row.original.employee.name}
            employeeEmail={row.original.employee.email}
            additionalData={getEmployeeCellAdditionalData(
              row.original.employee.employeeId,
              row.original.employee.costCenter,
            )}
          />
        ),
        accessorFn: (row) => row.employee,
        enableSorting: true,
        size: !isXs && !isSm ? 40 : 70,
      },
      ...(!isXs && !isSm
        ? [
            {
              id: "benefit",
              header: t("admin:home.employees.table.benefit"),
              cell: ({ row }: { row: Row<EmployeesTableDataType> }) => (
                <BenefitCell benefits={row.original.benefitDefinitions} />
              ),
              accessorFn: ({ row }: { row: Row<EmployeesTableDataType> }) =>
                row.original.benefitDefinitions,
              size: 40,
            },
          ]
        : []),
      {
        id: "permission",
        header: t("admin:home.employees.table.permission"),
        cell: ({ row }) => (
          <PermissionStatusCell status={row.original.status} />
        ),
        accessorFn: (row) => row.status,
        enableSorting: true,
      },
    ],
    [t, isXs, isSm],
  );

  const onRowClick = (row: EmployeesTableDataType) =>
    navigate(`../${Path.employee}/${row.employee.id}/${Path.contracts}`);

  return (
    <Table<EmployeesTableDataType>
      data={getTableRows()}
      columns={columns}
      paginationOptions={paginationOptions}
      sortingOptions={sortingOptions}
      onRowClick={onRowClick}
      emptyStateComponent={
        areFiltersEmpty ? (
          <TableEmptyState
            icon={<Card3DIcon size="md" />}
            title={t("admin:home.employees.table.emptyState.title")}
            subtitle={t("admin:home.employees.table.emptyState.subtitle")}
            link={{
              to: "https://tyosuhdepyora.vapaus.io/fi/tyonantajan-faq-tietopankki",
              text: t("admin:home.employees.table.emptyState.linkText"),
            }}
          />
        ) : (
          <TableEmptyState
            icon={<Lens3DIcon size="md" />}
            title={t("admin:home.employees.table.noResults.title")}
            subtitle={t("admin:home.employees.table.noResults.subtitle")}
          />
        )
      }
    />
  );
}
