import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import * as zod from "zod";
import { z } from "zod";

import type {
  UserMepcoIntegrationSettingsRead,
  UserRead,
} from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { useToaster } from "@vapaus/ui-v2";

import { providerMepcoApi } from "../../../../../../api/mepco";
import { userApi } from "../../../../../../api/user";
import { EmployeeViewContextProps } from "../../../../types/EmployeeViewContextProps";

type UseProfileModalProps = EmployeeViewContextProps & {
  organisationId: string;
  onClose: () => void;
};

const employeeFormSchema = zod.object({
  costCenter: zod.string(),
  employeeNumber: zod.string(),
  payGroupId: zod.string(),
  payComponent: zod.string(),
});

export type EmployeeFormSchemaType = z.infer<typeof employeeFormSchema>;

export function useProfileModal({
  user,
  organisationId,
  isMepcoIntegrationEnabled,
  mepcoIntegrationSettings,
  onClose,
}: UseProfileModalProps) {
  const { t } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const { formMethods, mutation } = useFormMutation<
    EmployeeFormSchemaType,
    (UserRead | UserMepcoIntegrationSettingsRead | undefined)[]
  >({
    mutationFn: (data) =>
      Promise.all(
        [
          userApi.providerUsersProviderUpdateUser({
            userId: user.id,
            userProviderUpdate: {
              costCenter: data.costCenter,
              employeeNumber: data.employeeNumber,
            },
          }),
          isMepcoIntegrationEnabled
            ? providerMepcoApi.providerMepcoSetUserMepcoIntegrationSettings({
                organisationId,
                userId: user.id,
                userMepcoIntegrationSettingsCreateUpdate: {
                  payGroupId: data.payGroupId,
                  payComponent: data.payComponent,
                },
              })
            : undefined,
        ].filter(Boolean),
      ),
    onSuccess: async () => {
      onClose();
      toaster.add(
        "success",
        t("admin:employeeViewPage.profile.profileUpdated"),
      );
      await queryClient.invalidateQueries(["user"]);
      await queryClient.invalidateQueries(["userMepcoIntegrationSettings"]);
    },
    onError: () =>
      toaster.add(
        "error",
        t("admin:employeeViewPage.profile.profileNotUpdated"),
      ),
  });
  const handleReset = () => {
    formMethods.reset({
      employeeNumber: user.employeeNumber ?? "",
      costCenter: user.costCenter ?? "",
      payGroupId: mepcoIntegrationSettings?.payGroupId ?? "",
      payComponent: mepcoIntegrationSettings?.payComponent ?? "",
    });
  };

  useEffect(() => {
    if (user) handleReset();
  }, [user, mepcoIntegrationSettings]);

  return { formMethods, mutation, handleReset };
}
