import { UserBenefitActivationRead, UserRead } from "@vapaus/api-codegen";
import { Flex, Modal, Tag, Typography } from "@vapaus/ui-v2";

import { useGetBenefitDefinition } from "../../../../../../api/benefitDefinitions";
import { usePermissionModal } from "./usePermissionModal";

type PermissionModalProps = {
  onClose: () => void;
  activation?: UserBenefitActivationRead;
  user: UserRead;
};

export function PermissionModal({
  activation,
  user,
  onClose,
}: PermissionModalProps) {
  const { data: benefitDefinition } = useGetBenefitDefinition(
    activation?.benefitDefinitionId,
  );
  const { modalView, modalProps, permissionStatus, permissionMessage } =
    usePermissionModal(user, onClose, activation, benefitDefinition?.active);

  return (
    <Modal {...modalProps}>
      {modalView === "activation" ? (
        <Flex direction="column" align="center">
          {permissionStatus ? (
            <Tag
              variant={permissionStatus.variant}
              text={permissionStatus.statusText}
              size="large"
            />
          ) : null}
          <Typography variant="h2" align="center">
            {benefitDefinition?.name}
          </Typography>
          <Typography weight="bold" align="center">
            {activation?.accessRequest?.userEmail || user?.email}
          </Typography>
          {permissionMessage ? (
            <Typography variant="bodyLg" align="center">
              {permissionMessage.longMessage}
            </Typography>
          ) : null}
        </Flex>
      ) : null}
    </Modal>
  );
}
