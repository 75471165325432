/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { OrganisationSummaryRead } from "./OrganisationSummaryRead";
import {
  OrganisationSummaryReadFromJSON,
  OrganisationSummaryReadFromJSONTyped,
  OrganisationSummaryReadToJSON,
} from "./OrganisationSummaryRead";
import type { UserSummaryRead } from "./UserSummaryRead";
import {
  UserSummaryReadFromJSON,
  UserSummaryReadFromJSONTyped,
  UserSummaryReadToJSON,
} from "./UserSummaryRead";

/**
 *
 * @export
 * @interface UserMepcoIntegrationSettingsRead
 */
export interface UserMepcoIntegrationSettingsRead {
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  payGroupId: string;
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  payComponent: string;
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  id: string;
  /**
   *
   * @type {UserSummaryRead}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  user: UserSummaryRead;
  /**
   *
   * @type {OrganisationSummaryRead}
   * @memberof UserMepcoIntegrationSettingsRead
   */
  organisation: OrganisationSummaryRead;
}

/**
 * Check if a given object implements the UserMepcoIntegrationSettingsRead interface.
 */
export function instanceOfUserMepcoIntegrationSettingsRead(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "payGroupId" in value;
  isInstance = isInstance && "payComponent" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "user" in value;
  isInstance = isInstance && "organisation" in value;

  return isInstance;
}

export function UserMepcoIntegrationSettingsReadFromJSON(
  json: any,
): UserMepcoIntegrationSettingsRead {
  return UserMepcoIntegrationSettingsReadFromJSONTyped(json, false);
}

export function UserMepcoIntegrationSettingsReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserMepcoIntegrationSettingsRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    payGroupId: json["pay_group_id"],
    payComponent: json["pay_component"],
    id: json["id"],
    user: UserSummaryReadFromJSON(json["user"]),
    organisation: OrganisationSummaryReadFromJSON(json["organisation"]),
  };
}

export function UserMepcoIntegrationSettingsReadToJSON(
  value?: UserMepcoIntegrationSettingsRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pay_group_id: value.payGroupId,
    pay_component: value.payComponent,
    id: value.id,
    user: UserSummaryReadToJSON(value.user),
    organisation: OrganisationSummaryReadToJSON(value.organisation),
  };
}
