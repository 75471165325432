/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserMepcoIntegrationSettingsCreateUpdate
 */
export interface UserMepcoIntegrationSettingsCreateUpdate {
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsCreateUpdate
   */
  payGroupId: string;
  /**
   *
   * @type {string}
   * @memberof UserMepcoIntegrationSettingsCreateUpdate
   */
  payComponent: string;
}

/**
 * Check if a given object implements the UserMepcoIntegrationSettingsCreateUpdate interface.
 */
export function instanceOfUserMepcoIntegrationSettingsCreateUpdate(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "payGroupId" in value;
  isInstance = isInstance && "payComponent" in value;

  return isInstance;
}

export function UserMepcoIntegrationSettingsCreateUpdateFromJSON(
  json: any,
): UserMepcoIntegrationSettingsCreateUpdate {
  return UserMepcoIntegrationSettingsCreateUpdateFromJSONTyped(json, false);
}

export function UserMepcoIntegrationSettingsCreateUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserMepcoIntegrationSettingsCreateUpdate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    payGroupId: json["pay_group_id"],
    payComponent: json["pay_component"],
  };
}

export function UserMepcoIntegrationSettingsCreateUpdateToJSON(
  value?: UserMepcoIntegrationSettingsCreateUpdate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pay_group_id: value.payGroupId,
    pay_component: value.payComponent,
  };
}
