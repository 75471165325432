import { useMutation, useQuery } from "@tanstack/react-query";

import type {
  PaginatedUserBenefitActivationOut,
  ProviderUserBenefitActivationsProviderGetUserBenefitDefinitionsActivationsRequest,
  ResponseError,
  UserBenefitActivationRead,
} from "@vapaus/api-codegen";
import { ProviderUserBenefitActivationsApi } from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const providerUserBenefitActivationAPI =
  new ProviderUserBenefitActivationsApi(apiConfiguration);

export const useSearchBenefitActivations = (
  params: ProviderUserBenefitActivationsProviderGetUserBenefitDefinitionsActivationsRequest,
  options?: UseQueryOptions<PaginatedUserBenefitActivationOut>,
) =>
  useQuery<PaginatedUserBenefitActivationOut, ResponseError>({
    queryKey: ["benefitActivations", params],
    queryFn: () =>
      providerUserBenefitActivationAPI.providerUserBenefitActivationsProviderGetUserBenefitDefinitionsActivations(
        params,
      ),
    ...options,
  });

const revokeSingleActivation = (userBenefitActivationId: string) =>
  providerUserBenefitActivationAPI.providerUserBenefitActivationsRevokeUserBenefitActivation(
    {
      userBenefitActivationId,
    },
  );

export const useRevokeActivation = () =>
  useMutation<UserBenefitActivationRead, ResponseError, string>({
    mutationFn: (userBenefitActivationId: string) =>
      revokeSingleActivation(userBenefitActivationId),
  });

export const useRevokeMultipleActivations = () =>
  useMutation<UserBenefitActivationRead[], ResponseError, string[]>({
    mutationFn: (userBenefitActivationIds: string[]) =>
      Promise.all(
        userBenefitActivationIds.map((id) => revokeSingleActivation(id)),
      ),
  });
