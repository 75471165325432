/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BenefitDefinitionAccessRulesRead,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
} from "../models/index";
import {
  BenefitDefinitionAccessRulesReadFromJSON,
  BenefitDefinitionAccessRulesReadToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderBenefitDefinitionAccessRulesGetAccessRulesRequest {
  accessRulesUuid: string;
}

/**
 *
 */
export class ProviderBenefitDefinitionAccessRulesApi extends runtime.BaseAPI {
  /**
   * Get Access Rules
   */
  async providerBenefitDefinitionAccessRulesGetAccessRulesRaw(
    requestParameters: ProviderBenefitDefinitionAccessRulesGetAccessRulesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionAccessRulesRead>> {
    if (
      requestParameters.accessRulesUuid === null ||
      requestParameters.accessRulesUuid === undefined
    ) {
      throw new runtime.RequiredError(
        "accessRulesUuid",
        "Required parameter requestParameters.accessRulesUuid was null or undefined when calling providerBenefitDefinitionAccessRulesGetAccessRules.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/benefit-definition-access-rules/{access_rules_uuid}`.replace(
          `{${"access_rules_uuid"}}`,
          encodeURIComponent(String(requestParameters.accessRulesUuid)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionAccessRulesReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Access Rules
   */
  async providerBenefitDefinitionAccessRulesGetAccessRules(
    requestParameters: ProviderBenefitDefinitionAccessRulesGetAccessRulesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionAccessRulesRead> {
    const response =
      await this.providerBenefitDefinitionAccessRulesGetAccessRulesRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
