import * as React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PopupWrapper } from "@vapaus/common-v2";
import {
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  DropdownMenu,
  Flex,
  Typography,
} from "@vapaus/ui-v2";

import { useCurrentBenefitContext } from "../../../contexts/CurrentBenefitContext";
import { useOrganisationAndBenefitsContext } from "../../../contexts/OrganisationAndBenefitsContext";
import { renderBenefitName } from "../../../utils/renderBenefitName";

export function BenefitFilter() {
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState<null | HTMLElement>(null);
  const { selectedOrganisation } = useOrganisationAndBenefitsContext();
  const { currentBenefitId, setCurrentBenefitId } = useCurrentBenefitContext();

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setTrigger(trigger ? null : event.currentTarget);
  };

  const closeMenu = () => {
    setTrigger(null);
  };

  const benefitItems =
    selectedOrganisation?.benefits.map((benefit) => ({
      value: benefit.id,
      label: renderBenefitName(benefit.name, benefit.active, t),
    })) ?? [];

  const items = [
    { value: "", label: t("admin:home.benefitFilter.allBikeBenefits") },
    ...benefitItems,
  ];

  const currentBenefitLabel = benefitItems.find(
    (item) => item.value === currentBenefitId,
  )?.label;

  const onSelectCurrentBenefit = (benefitId: string) => {
    setCurrentBenefitId(benefitId);
    closeMenu();
  };

  return (
    <>
      <Flex gap="xs">
        <Typography color="primary1.light" weight="bold">
          {t("admin:home.benefitFilter.currentlyViewing")}:
        </Typography>
        <StyledBenefitFilter gap="xxs" onClick={handleButtonClick}>
          <Typography weight="bold">
            {currentBenefitLabel ??
              t("admin:home.benefitFilter.allBikeBenefits")}
          </Typography>
          {trigger ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </StyledBenefitFilter>
      </Flex>
      <PopupWrapper triggerElement={trigger} onOutsideClick={closeMenu}>
        <DropdownMenu items={items} onItemClick={onSelectCurrentBenefit} />
      </PopupWrapper>
    </>
  );
}

const StyledBenefitFilter = styled(Flex)`
  cursor: pointer;
`;
