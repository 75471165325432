/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { ProviderNotificationCategory } from "./ProviderNotificationCategory";
import {
  ProviderNotificationCategoryFromJSON,
  ProviderNotificationCategoryFromJSONTyped,
  ProviderNotificationCategoryToJSON,
} from "./ProviderNotificationCategory";

/**
 *
 * @export
 * @interface ProviderNotificationPreference
 */
export interface ProviderNotificationPreference {
  /**
   *
   * @type {ProviderNotificationCategory}
   * @memberof ProviderNotificationPreference
   */
  notificationCategory: ProviderNotificationCategory;
  /**
   *
   * @type {boolean}
   * @memberof ProviderNotificationPreference
   */
  optedIn: boolean;
}

/**
 * Check if a given object implements the ProviderNotificationPreference interface.
 */
export function instanceOfProviderNotificationPreference(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "notificationCategory" in value;
  isInstance = isInstance && "optedIn" in value;

  return isInstance;
}

export function ProviderNotificationPreferenceFromJSON(
  json: any,
): ProviderNotificationPreference {
  return ProviderNotificationPreferenceFromJSONTyped(json, false);
}

export function ProviderNotificationPreferenceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderNotificationPreference {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    notificationCategory: ProviderNotificationCategoryFromJSON(
      json["notification_category"],
    ),
    optedIn: json["opted_in"],
  };
}

export function ProviderNotificationPreferenceToJSON(
  value?: ProviderNotificationPreference | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    notification_category: ProviderNotificationCategoryToJSON(
      value.notificationCategory,
    ),
    opted_in: value.optedIn,
  };
}
