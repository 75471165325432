import { ReportType, ResponseError } from "@vapaus/api-codegen";
import { useDownload } from "@vapaus/utils";

import { reportsApi } from "../../../api/reports";

type DownloadReportParams = {
  benefitDefinitionIds: Array<string>;
  startDate: Date;
  endDate: Date;
};

type UseDownloadContractsReportParams = DownloadReportParams & {
  reportType: string;
};

type UseDownloadLeasingLiabilityReportParams = DownloadReportParams;

export function useDownloadContractsReport() {
  return useDownload<UseDownloadContractsReportParams, ResponseError>(
    (params: UseDownloadContractsReportParams) =>
      reportsApi
        .providerReportsGetProviderSearchBikeBenefitContractsCsv({
          ...params,
          benefitDefinitionIds: new Set(params.benefitDefinitionIds),
          reportType: params.reportType as ReportType,
        })
        .then(addBomPrefix()),
  );
}

export function useDownloadLeasingLiabilityReport() {
  return useDownload<UseDownloadLeasingLiabilityReportParams, ResponseError>(
    (params: UseDownloadLeasingLiabilityReportParams) =>
      reportsApi
        .providerReportsGetLeasingLiabilityReportCsv({
          ...params,
          benefitDefinitionIds: new Set(params.benefitDefinitionIds),
        })
        .then(addBomPrefix()),
  );
}

function addBomPrefix() {
  return (body: unknown) => {
    // the automatically generated `__request` uses `response.text()` internally
    // and removes the BOM prefix from CSV files
    // we need to add it forcibly to make sure they open fine in Excel
    const bom = "\ufeff";
    return bom + body;
  };
}
