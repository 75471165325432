import { useTranslation } from "react-i18next";

import { UserRead } from "@vapaus/api-codegen";
import { Button, Card, CardRow, Modal } from "@vapaus/ui-v2";

import { useRevokeAllCard } from "./useRevokeAllCard";

type RevokeAllCardProps = {
  user: UserRead;
};

export function RevokeAllCard({ user }: RevokeAllCardProps) {
  const { t } = useTranslation();

  const {
    shouldHideCard,
    openRevokeAllModal,
    isLoading,
    handleCloseModal,
    handleOpenModal,
    handleRevokeAllConfirm,
  } = useRevokeAllCard(user);

  if (shouldHideCard) return null;

  return (
    <Card>
      <CardRow
        title={t("admin:employeeViewPage.permissions.revokeAllCard.title")}
        description={t(
          "admin:employeeViewPage.permissions.revokeAllCard.description",
        )}
        action={
          <Button
            onClick={handleOpenModal}
            variant="primaryDanger"
            size="small"
            xsFullWidth
          >
            {t("admin:employeeViewPage.permissions.revokeAllCard.revokeAll")}
          </Button>
        }
      />
      <Modal
        isOpen={openRevokeAllModal}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        title={t(
          "admin:employeeViewPage.permissions.revokeAllCard.modal.title",
        )}
        description={t(
          "admin:employeeViewPage.permissions.revokeAllCard.modal.description",
          {
            email: user.email,
            name: user.fullName,
          },
        )}
        onConfirm={handleRevokeAllConfirm}
        confirmText={t(
          "admin:employeeViewPage.permissions.revokeAllCard.revokeAll",
        )}
        cancelText={t("commonV2:cancel")}
        confirmButtonVariant="primaryDanger"
        isSubmitting={isLoading}
      />
    </Card>
  );
}
