import { useQuery } from "@tanstack/react-query";

import {
  ProviderMepcoApi,
  ResponseError,
  UserMepcoIntegrationSettingsRead,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const providerMepcoApi = new ProviderMepcoApi(apiConfiguration);

export const useGetUserMepcoIntegrationSettings = (
  userId?: string,
  organisationId?: string,
  options?: UseQueryOptions<UserMepcoIntegrationSettingsRead>,
) =>
  useQuery<UserMepcoIntegrationSettingsRead, ResponseError>({
    queryKey: ["userMepcoIntegrationSettings", userId, organisationId],
    queryFn: () =>
      providerMepcoApi.providerMepcoGetUserMepcoIntegrationSettings({
        userId: userId!,
        organisationId: organisationId!,
      }),
    ...options,
    enabled: Boolean(
      (options?.enabled || !options) && userId && organisationId,
    ),
  });
