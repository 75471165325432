import { useTableParams } from "@vapaus/ui-v2";

import { useSearchBenefitContracts } from "../../../../api/benefitContracts";
import { useOrganisationAndBenefitsContext } from "../../../../contexts/OrganisationAndBenefitsContext";

export function useContractTabsTable(userId: string) {
  const { availableBenefitIds } = useOrganisationAndBenefitsContext();

  const { sorting, pagination, setSorting, setPagination } = useTableParams({
    sortId: "status",
    sortDesc: true,
  });

  const sortFieldMapping: Record<string, string> = {
    bike: "bike.bike_brand.name",
    monthsLeft: "start_date",
    status: "state",
  };

  const { data: fetchedData, isLoading } = useSearchBenefitContracts(
    {
      limit: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      benefitDefinitionIds: availableBenefitIds,
      userId,
      ...(sortFieldMapping[sorting[0]?.id]
        ? {
            sortField: sortFieldMapping[sorting[0]?.id],
            sortDirection: sorting[0]?.desc ? "desc" : "asc",
          }
        : null),
    },
    {
      enabled: !!userId,
      keepPreviousData: true,
    },
  );

  return {
    paginationOptions: {
      totalItems: fetchedData?.total ?? 0,
      pagination,
      setPagination,
    },
    sortingOptions: {
      sorting,
      setSorting,
    },
    data: fetchedData?.items || [],
    isLoading,
  };
}
