import { now } from "lodash-es";
import { useTranslation } from "react-i18next";

import { getDateDifferenceInDays } from "@vapaus/common-v2";

import { DAYS_TO_ACCEPT_REQUEST } from "../../../../../../../constants";

type DateCellProps = {
  date: Date;
};

export function DateCell({ date }: DateCellProps) {
  const { t } = useTranslation();

  const daysPassedSinceSubmission = getDateDifferenceInDays(
    date,
    new Date(now()),
  );
  const daysLeft = DAYS_TO_ACCEPT_REQUEST - daysPassedSinceSubmission;
  return (
    <article>
      {daysLeft > 0
        ? t("admin:home.requests.table.daysLeft", { value: daysLeft })
        : t("admin:home.requests.table.daysAgo", {
            value: Math.abs(daysLeft),
          })}
    </article>
  );
}
