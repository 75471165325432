import { useTranslation } from "react-i18next";

import { Flex, RowItem } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  useDateFormat,
} from "../../../../../../../../../packages/i18n/src/hooks";
import { ContractParamsProps, ContractProps } from "../../../type";

interface ContractSummaryProps extends ContractProps {
  contractParams: ContractParamsProps;
}

export function ContractSummary({
  contract,
  contractParams,
}: ContractSummaryProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(contract.currency);
  const formatDate = useDateFormat();
  return (
    <Flex direction="column" gap="md" marginBottom="md">
      <RowItem
        label={t("admin:contractDetails.summary.startDate")}
        value={formatDate(contract.startDate)}
      />
      <RowItem
        label={t("admin:contractDetails.summary.endDate")}
        value={formatDate(contract.endDate)}
      />
      <RowItem
        label={t("admin:contractDetails.summary.leasingPeriod.name")}
        value={t("admin:contractDetails.summary.leasingPeriod.content", {
          months: contract.leasingPeriodMonths,
        })}
      />
      <RowItem
        label={t("admin:contractDetails.summary.monthlyPayment")}
        value={formatCurrency(
          contract.fixedMonthlyTaxableValue || contract.monthlyTaxableValue,
        )}
      />
      <RowItem
        label={t("admin:contractDetails.summary.totalContractValue")}
        value={formatCurrency(contractParams.contractTotal)}
        valueIsBold
      />
    </Flex>
  );
}
