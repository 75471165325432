import { useTranslation } from "react-i18next";

import { ClearFiltersSection } from "@vapaus/common-v2";

import { useContractsTable } from "./ContractsTable/useContractsTable";

export function ContractsClearFiltersSection() {
  const { t } = useTranslation();
  const { paginationOptions, clearFilters, areFiltersEmpty } =
    useContractsTable();

  return (
    <ClearFiltersSection
      showingItemsText={t(
        "admin:home.contracts.clearFiltersSection.showingContracts",
        {
          numberOfContracts: paginationOptions.totalItems,
        },
      )}
      onClick={clearFilters}
      buttonActive={!areFiltersEmpty}
    />
  );
}
