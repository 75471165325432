import * as React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Flex, TabRow, TabRowItemProps } from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";
import { useTotalPendingRequests } from "./RequestsTab/useTotalPendingRequests";

export function HomePageTabRow() {
  const { t } = useTranslation();
  const { totalPendingRequests, isLoading } = useTotalPendingRequests();

  const tabItems: TabRowItemProps[] = [
    {
      label: t("admin:home.tabs.employees"),
      to: "",
    },
    {
      label: t("admin:home.tabs.contracts"),
      to: Path.contracts,
    },
    {
      label: t("admin:home.tabs.requests"),
      to: Path.requests,
      badgeCount:
        !isLoading && totalPendingRequests && totalPendingRequests > 0
          ? totalPendingRequests
          : undefined,
    },
  ];

  return (
    <StyledTabRowContainer>
      <TabRow items={tabItems} />
    </StyledTabRowContainer>
  );
}

const StyledTabRowContainer = styled(Flex)`
  border-bottom: ${({ theme }) => theme.border.primary.light};

  & > * {
    padding-inline: 0;
  }
`;
