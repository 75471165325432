import { useTranslation } from "react-i18next";

import { PopupWrapper } from "@vapaus/common-v2";
import { DropdownMenu, LoginIcon, StorefrontIcon } from "@vapaus/ui-v2";

import { useOrganisationAndBenefitsContext } from "../../../contexts/OrganisationAndBenefitsContext";

export type AccountSwitcherMenuProps = {
  accountSwitcherMenuTrigger: HTMLElement | null;
  closeAccountSwitcherMenu: () => void;
};

export function AccountSwitcherMenu({
  accountSwitcherMenuTrigger,
  closeAccountSwitcherMenu,
}: AccountSwitcherMenuProps) {
  const { t } = useTranslation();
  const {
    selectedOrganisation,
    organisationsWithBenefits,
    changeSelectedOrganisation,
  } = useOrganisationAndBenefitsContext();

  const organisations = organisationsWithBenefits.map((org) => ({
    label: org.name,
    value: org.id,
  }));

  const menuProps = {
    header: {
      icon: StorefrontIcon,
      title: t("admin:navigation.accountSwitcher.menu.title"),
      description: selectedOrganisation?.name ?? "",
    },
    items: organisations,
    onItemClick: (value: string) => {
      closeAccountSwitcherMenu();
      changeSelectedOrganisation(value);
    },
    selectable: {
      selected: selectedOrganisation?.id ?? "",
      onHoverAdornment: LoginIcon,
    },
  };

  return (
    <PopupWrapper
      triggerElement={accountSwitcherMenuTrigger}
      onOutsideClick={closeAccountSwitcherMenu}
    >
      <DropdownMenu {...menuProps} />
    </PopupWrapper>
  );
}
