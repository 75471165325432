import * as React from "react";

import { useTranslation } from "react-i18next";

import { InfoCard, useUserCountry } from "@vapaus/common-v2";
import { CircularIcon, PsychologyIcon } from "@vapaus/ui-v2";

export function EmployerFaqInfoCard() {
  const { t } = useTranslation();
  const { isSwedishUser } = useUserCountry();

  const description = isSwedishUser
    ? t("admin:sweden.support.guidesAndFaqs.employerFaq.description")
    : t("admin:support.guidesAndFaqs.employerFaq.description");

  const link = isSwedishUser
    ? "https://vapaus.se/formanscykeltjanst-for-arbetsgivare"
    : "https://tyosuhdepyora.vapaus.io/fi/tyonantajan-faq-tietopankki";

  return (
    <InfoCard
      icon={<CircularIcon icon={PsychologyIcon} />}
      title={t("admin:support.guidesAndFaqs.employerFaq.title")}
      description={description}
      buttonText={t("admin:support.guidesAndFaqs.viewGuide")}
      link={link}
      target="_blank"
      backgroundColor="secondary3.lighter"
      shadow="none"
      variant="small"
    />
  );
}
