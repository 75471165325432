/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  EmployeeBenefitActivationStatus,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBenefitUserOut,
  PaginatedEmployeeUserOut,
  UserProviderUpdate,
  UserRead,
} from "../models/index";
import {
  EmployeeBenefitActivationStatusFromJSON,
  EmployeeBenefitActivationStatusToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBenefitUserOutFromJSON,
  PaginatedBenefitUserOutToJSON,
  PaginatedEmployeeUserOutFromJSON,
  PaginatedEmployeeUserOutToJSON,
  UserProviderUpdateFromJSON,
  UserProviderUpdateToJSON,
  UserReadFromJSON,
  UserReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderUsersProviderGetUserRequest {
  userId: string;
}

export interface ProviderUsersProviderSearchEmployeeUsersRequest {
  benefitDefinitionIds: Set<string>;
  benefitActivationStatus?: EmployeeBenefitActivationStatus;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: ProviderUsersProviderSearchEmployeeUsersSortFieldEnum;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface ProviderUsersProviderSearchUsersRequest {
  benefitDefinitionIds: Set<string>;
  onlyActive?: boolean;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface ProviderUsersProviderUpdateUserRequest {
  userId: string;
  userProviderUpdate: UserProviderUpdate;
}

/**
 *
 */
export class ProviderUsersApi extends runtime.BaseAPI {
  /**
   * Provider Get User
   */
  async providerUsersProviderGetUserRaw(
    requestParameters: ProviderUsersProviderGetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserRead>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling providerUsersProviderGetUser.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserReadFromJSON(jsonValue),
    );
  }

  /**
   * Provider Get User
   */
  async providerUsersProviderGetUser(
    requestParameters: ProviderUsersProviderGetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserRead> {
    const response = await this.providerUsersProviderGetUserRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Provider Search Employee Users
   */
  async providerUsersProviderSearchEmployeeUsersRaw(
    requestParameters: ProviderUsersProviderSearchEmployeeUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedEmployeeUserOut>> {
    if (
      requestParameters.benefitDefinitionIds === null ||
      requestParameters.benefitDefinitionIds === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionIds",
        "Required parameter requestParameters.benefitDefinitionIds was null or undefined when calling providerUsersProviderSearchEmployeeUsers.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.benefitDefinitionIds) {
      queryParameters["benefit_definition_ids"] =
        requestParameters.benefitDefinitionIds;
    }

    if (requestParameters.benefitActivationStatus !== undefined) {
      queryParameters["benefit_activation_status"] =
        requestParameters.benefitActivationStatus;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/users/employees`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedEmployeeUserOutFromJSON(jsonValue),
    );
  }

  /**
   * Provider Search Employee Users
   */
  async providerUsersProviderSearchEmployeeUsers(
    requestParameters: ProviderUsersProviderSearchEmployeeUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedEmployeeUserOut> {
    const response = await this.providerUsersProviderSearchEmployeeUsersRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Provider Search Users
   */
  async providerUsersProviderSearchUsersRaw(
    requestParameters: ProviderUsersProviderSearchUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBenefitUserOut>> {
    if (
      requestParameters.benefitDefinitionIds === null ||
      requestParameters.benefitDefinitionIds === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionIds",
        "Required parameter requestParameters.benefitDefinitionIds was null or undefined when calling providerUsersProviderSearchUsers.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.benefitDefinitionIds) {
      queryParameters["benefit_definition_ids"] =
        requestParameters.benefitDefinitionIds;
    }

    if (requestParameters.onlyActive !== undefined) {
      queryParameters["only_active"] = requestParameters.onlyActive;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/users/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBenefitUserOutFromJSON(jsonValue),
    );
  }

  /**
   * Provider Search Users
   */
  async providerUsersProviderSearchUsers(
    requestParameters: ProviderUsersProviderSearchUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBenefitUserOut> {
    const response = await this.providerUsersProviderSearchUsersRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Provider Update User
   */
  async providerUsersProviderUpdateUserRaw(
    requestParameters: ProviderUsersProviderUpdateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserRead>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling providerUsersProviderUpdateUser.",
      );
    }

    if (
      requestParameters.userProviderUpdate === null ||
      requestParameters.userProviderUpdate === undefined
    ) {
      throw new runtime.RequiredError(
        "userProviderUpdate",
        "Required parameter requestParameters.userProviderUpdate was null or undefined when calling providerUsersProviderUpdateUser.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UserProviderUpdateToJSON(requestParameters.userProviderUpdate),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserReadFromJSON(jsonValue),
    );
  }

  /**
   * Provider Update User
   */
  async providerUsersProviderUpdateUser(
    requestParameters: ProviderUsersProviderUpdateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserRead> {
    const response = await this.providerUsersProviderUpdateUserRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}

/**
 * @export
 */
export const ProviderUsersProviderSearchEmployeeUsersSortFieldEnum = {
  BenefitActivationStatus: "benefit_activation_status",
  LastName: "last_name",
} as const;
export type ProviderUsersProviderSearchEmployeeUsersSortFieldEnum =
  (typeof ProviderUsersProviderSearchEmployeeUsersSortFieldEnum)[keyof typeof ProviderUsersProviderSearchEmployeeUsersSortFieldEnum];
