import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import styled, { css } from "styled-components";

import { Flex, TabRow, type TabRowItemProps, Typography } from "@vapaus/ui-v2";

import { Path } from "../../constants/paths";
import { useOrganisationAndBenefitsContext } from "../../contexts/OrganisationAndBenefitsContext";

export function AdminSettingsPage() {
  const { selectedOrganisation } = useOrganisationAndBenefitsContext();
  const { t } = useTranslation();

  const tabItems: TabRowItemProps[] = [
    {
      label: t("admin:adminPage.hero.tab.organization"),
      to: Path.adminOrganization,
    },
    {
      label: t("admin:adminPage.hero.tab.benefits"),
      to: Path.adminBenefits,
      end: false,
    },
    {
      label: t("admin:adminPage.hero.tab.plan"),
      to: Path.adminPlan,
    },
  ];

  return (
    <>
      <StyledRoot direction="column" align="center">
        <StyledContent>
          <Typography variant="h6" align="center" marginBottom="xxs">
            {t("admin:adminPage.hero.title")}
          </Typography>
          <Typography variant="h1" align="center" marginBottom="xxs">
            {selectedOrganisation?.name}
          </Typography>
        </StyledContent>
        <TabRow items={tabItems} />
      </StyledRoot>
      <StyledPageContent>
        <Outlet />
      </StyledPageContent>
    </>
  );
}

const StyledRoot = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.secondary3.lighter};
  border-bottom: ${({ theme }) => theme.border.secondary.main};
`;

const StyledContent = styled.div`
  padding-top: 120px;
  padding-bottom: 80px;
  width: 100%;
`;

const StyledPageContent = styled.div`
  max-width: 800px;
  margin: ${({ theme }) => theme.spacing.xl} auto;
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      margin: ${theme.spacing.lg};
      padding-bottom: ${theme.height.navBar};
    `)}
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      margin: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.sm}`};
    `)}
`;
