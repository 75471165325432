import * as React from "react";

import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { PageRoot } from "@vapaus/common-v2";
import { Card, Flex, Typography } from "@vapaus/ui-v2";

import { useOrganisationAndBenefitsContext } from "../../contexts/OrganisationAndBenefitsContext";
import { BenefitFilter } from "./components/BenefitFilter";
import { HomePageTabRow } from "./components/HomePageTabRow";

export function HomePage() {
  const { t } = useTranslation();
  const { selectedOrganisation, availableBenefitIds } =
    useOrganisationAndBenefitsContext();

  return (
    <PageRoot size="wide">
      <Flex direction="column" gap="xl">
        <Flex direction="column" gap="xxs">
          <Typography variant="h6" marginBottom="none">
            {selectedOrganisation?.name}
          </Typography>
          <Typography variant="h1">{t("admin:home.benefitBikes")}</Typography>
          {availableBenefitIds.length > 1 ? <BenefitFilter /> : null}
        </Flex>
        <Card>
          <Flex direction="column" gap="lg">
            <HomePageTabRow />
            <Outlet />
          </Flex>
        </Card>
      </Flex>
    </PageRoot>
  );
}
