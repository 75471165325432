import { useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import { Plan } from "@vapaus/api-codegen";
import { Banner, Typography } from "@vapaus/ui-v2";

import { useOrganisationAndBenefitsContext } from "../../../contexts/OrganisationAndBenefitsContext";

export const ProductUpgradeBanner = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { selectedOrganisation } = useOrganisationAndBenefitsContext();

  const shouldShowBanner = useMemo(() => {
    const hasCustomLegacyBenefit = selectedOrganisation?.benefits.find(
      (benefitDefinition) =>
        benefitDefinition.plan === Plan.Custom ||
        benefitDefinition.plan === Plan.Legacy,
    );
    const hasNewPlansBenefit = selectedOrganisation?.benefits.find(
      (benefitDefinition) =>
        benefitDefinition.plan === Plan.Starter ||
        benefitDefinition.plan === Plan.Pro ||
        benefitDefinition.plan === Plan.Enterprise,
    );
    return hasCustomLegacyBenefit && !hasNewPlansBenefit && isOpen;
  }, [isOpen, selectedOrganisation?.benefits]);

  return shouldShowBanner ? (
    <Banner variant="warning" onClose={() => setIsOpen(false)}>
      <Typography>
        <Trans
          i18nKey="admin:announcement.productUpgrade.description"
          components={{
            1: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={t("admin:announcement.productUpgrade.infoPageLink")}
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        ></Trans>
      </Typography>
    </Banner>
  ) : null;
};
