import { useTableParams } from "@vapaus/ui-v2";

import { useSearchBenefitActivations } from "../../../../../../api/benefitActivations";
import { useOrganisationAndBenefitsContext } from "../../../../../../contexts/OrganisationAndBenefitsContext";

export function usePermissionsTable(userId: string) {
  const { availableBenefitIds } = useOrganisationAndBenefitsContext();

  const { sorting, pagination, setSorting, setPagination } = useTableParams({
    sortId: "bikeBenefit",
    sortDesc: true,
  });

  const sortFieldMapping: Record<string, string> = {
    bikeBenefit: "benefit_definition.name",
  };

  const { data: fetchedData, isLoading } = useSearchBenefitActivations(
    {
      userId,
      benefitDefinitionIds: new Set(availableBenefitIds),
      limit: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      ...(sortFieldMapping[sorting[0]?.id]
        ? {
            sortField: sortFieldMapping[sorting[0]?.id],
            sortDirection: sorting[0]?.desc ? "desc" : "asc",
          }
        : null),
    },
    {
      enabled: availableBenefitIds.length > 0,
      keepPreviousData: true,
    },
  );

  return {
    paginationOptions: {
      totalItems: fetchedData?.total ?? 0,
      pagination,
      setPagination,
    },
    sortingOptions: {
      sorting,
      setSorting,
    },
    data: fetchedData?.items || [],
    isLoading,
  };
}
