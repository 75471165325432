import * as React from "react";

import { useTranslation } from "react-i18next";

import { BadgeIcon, DotFilledIcon, Select } from "@vapaus/ui-v2";

import { useRequestsTable } from "./RequestsTable/useRequestsTable";

export function RequestStatusSelect() {
  const { t } = useTranslation();
  const { selectedStatusFilter, handleSelectedStatusFilterChange } =
    useRequestsTable();

  const requestStatusSelectOptions = [
    {
      icon: <BadgeIcon />,
      value: "",
      label: t("admin:home.requests.status.allStatuses"),
    },
    {
      icon: <DotFilledIcon color="secondary3.main" />,
      value: "pending",
      label: t("admin:home.requests.status.pending"),
    },
    {
      icon: <DotFilledIcon color="secondary2.main" />,
      value: "denied",
      label: t("admin:home.requests.status.denied"),
    },
    {
      icon: <DotFilledIcon color="primary1.lighter" />,
      value: "expired",
      label: t("admin:home.requests.status.expired"),
    },
  ];

  return (
    <Select<string>
      options={requestStatusSelectOptions}
      value={selectedStatusFilter}
      onChange={handleSelectedStatusFilterChange}
    />
  );
}
