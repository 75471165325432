import { Flex } from "@vapaus/ui-v2";

import { StyledFilteringSection } from "../../../../components/StyledFilteringSection";
import { EmployeesClearFiltersSection } from "./components/EmployeesClearFiltersSection";
import { EmployeesSearchBar } from "./components/EmployeesSearchBar";
import { EmployeesTable } from "./components/EmployeesTable/EmployeesTable";
import { PermissionStatusSelect } from "./components/PermissionStatusSelect";

export function EmployeesTab() {
  return (
    <Flex direction="column" gap="lg">
      <StyledFilteringSection>
        <EmployeesSearchBar />
        <PermissionStatusSelect />
      </StyledFilteringSection>
      <EmployeesClearFiltersSection />
      <EmployeesTable />
    </Flex>
  );
}
