import { useOutletContext } from "react-router-dom";

import { UserRead } from "@vapaus/api-codegen";
import { Flex } from "@vapaus/ui-v2";

import { PermissionsTable } from "./components/PermissionsTable";
import { RevokeAllCard } from "./components/RevokeAllCard";

export function PermissionsTab() {
  const { user } = useOutletContext<{ user: UserRead }>();

  return (
    <Flex direction="column" gap="lg">
      <PermissionsTable user={user} />
      <RevokeAllCard user={user} />
    </Flex>
  );
}
