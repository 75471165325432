/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BenefitDefinitionReadWithOrganisationSummary,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBenefitDefinitionOut,
} from "../models/index";
import {
  BenefitDefinitionReadWithOrganisationSummaryFromJSON,
  BenefitDefinitionReadWithOrganisationSummaryToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBenefitDefinitionOutFromJSON,
  PaginatedBenefitDefinitionOutToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderBenefitDefinitionsProviderGetBenefitDefinitionRequest {
  benefitDefinitionId: string;
}

export interface ProviderBenefitDefinitionsProviderSearchBenefitDefinitionsRequest {
  organisationId: string;
  onlyActive?: boolean;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class ProviderBenefitDefinitionsApi extends runtime.BaseAPI {
  /**
   * Provider Get Benefit Definition
   */
  async providerBenefitDefinitionsProviderGetBenefitDefinitionRaw(
    requestParameters: ProviderBenefitDefinitionsProviderGetBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<BenefitDefinitionReadWithOrganisationSummary>
  > {
    if (
      requestParameters.benefitDefinitionId === null ||
      requestParameters.benefitDefinitionId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionId",
        "Required parameter requestParameters.benefitDefinitionId was null or undefined when calling providerBenefitDefinitionsProviderGetBenefitDefinition.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/benefit-definitions/{benefit_definition_id}`.replace(
          `{${"benefit_definition_id"}}`,
          encodeURIComponent(String(requestParameters.benefitDefinitionId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionReadWithOrganisationSummaryFromJSON(jsonValue),
    );
  }

  /**
   * Provider Get Benefit Definition
   */
  async providerBenefitDefinitionsProviderGetBenefitDefinition(
    requestParameters: ProviderBenefitDefinitionsProviderGetBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionReadWithOrganisationSummary> {
    const response =
      await this.providerBenefitDefinitionsProviderGetBenefitDefinitionRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Provider Search Benefit Definitions
   */
  async providerBenefitDefinitionsProviderSearchBenefitDefinitionsRaw(
    requestParameters: ProviderBenefitDefinitionsProviderSearchBenefitDefinitionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBenefitDefinitionOut>> {
    if (
      requestParameters.organisationId === null ||
      requestParameters.organisationId === undefined
    ) {
      throw new runtime.RequiredError(
        "organisationId",
        "Required parameter requestParameters.organisationId was null or undefined when calling providerBenefitDefinitionsProviderSearchBenefitDefinitions.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.organisationId !== undefined) {
      queryParameters["organisation_id"] = requestParameters.organisationId;
    }

    if (requestParameters.onlyActive !== undefined) {
      queryParameters["only_active"] = requestParameters.onlyActive;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/benefit-definitions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBenefitDefinitionOutFromJSON(jsonValue),
    );
  }

  /**
   * Provider Search Benefit Definitions
   */
  async providerBenefitDefinitionsProviderSearchBenefitDefinitions(
    requestParameters: ProviderBenefitDefinitionsProviderSearchBenefitDefinitionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBenefitDefinitionOut> {
    const response =
      await this.providerBenefitDefinitionsProviderSearchBenefitDefinitionsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
