import { omit, sortBy } from "lodash-es";

import {
  BenefitDefinitionSummaryRead,
  OrganisationSummaryRead,
} from "@vapaus/api-codegen";

export type OrganisationWithBenefits = OrganisationSummaryRead & {
  benefits: Array<Omit<BenefitDefinitionSummaryRead, "organisation">>;
};

export type OrganisationsWithBenefits = Array<OrganisationWithBenefits>;

export const benefitsToOrganisations = (
  benefits: Array<BenefitDefinitionSummaryRead>,
): OrganisationsWithBenefits => {
  const organisations = benefits.reduce<OrganisationsWithBenefits>(
    (orgs, benefit) => {
      let currentOrg = orgs.find((org) => org.id === benefit.organisation.id);
      if (!currentOrg) {
        orgs.push({ ...benefit.organisation, benefits: [] });
        currentOrg = orgs[orgs.length - 1];
      }
      currentOrg.benefits.push(omit(benefit, "organisation"));
      return orgs;
    },
    [],
  );
  const organisationsSortedByName = sortBy(organisations, "name");
  organisationsSortedByName.forEach(
    (org) => (org.benefits = sortBy(org.benefits, "name")),
  );
  return organisationsSortedByName;
};
