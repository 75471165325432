import { Flex } from "@vapaus/ui-v2";

import { ContractBodySection } from "./components/ContractBodySection";
import { ContractHeaderSection } from "./components/ContractHeaderSection";
import { ContractProps } from "./type";

export function ContractDetails({ contract }: ContractProps) {
  return (
    <Flex direction="column" gap="none">
      <ContractHeaderSection contract={contract} />
      <ContractBodySection contract={contract} />
    </Flex>
  );
}
