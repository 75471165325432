import * as React from "react";

import { useTranslation } from "react-i18next";

import { DotFilledIcon, OrderApproveIcon, Select } from "@vapaus/ui-v2";

import { useContractsTable } from "./ContractsTable/useContractsTable";

export function ContractStatusSelect() {
  const { t } = useTranslation();
  const { selectedStatusFilter, handleSelectedStatusFilterChange } =
    useContractsTable();

  const contractStatusSelectOptions = [
    [
      {
        icon: <OrderApproveIcon />,
        value: "",
        label: t("admin:home.contracts.status.allStatuses"),
      },
      {
        icon: <DotFilledIcon color="secondary1.main" />,
        value: "active",
        label: t("admin:home.contracts.status.active"),
        tooltip: t("admin:home.contracts.status.tooltip.active"),
      },
      {
        icon: <DotFilledIcon color="secondary2.main" />,
        value: "cancelled",
        label: t("admin:home.contracts.status.cancelled"),
        tooltip: t("admin:home.contracts.status.tooltip.cancelled"),
      },
      {
        icon: <DotFilledIcon color="primary1.main" />,
        value: "ended",
        label: t("admin:home.contracts.status.ended"),
        tooltip: t("admin:home.contracts.status.tooltip.ended"),
      },
    ],
    [
      {
        icon: <DotFilledIcon color="secondary3.main" />,
        value: "scheduled",
        label: t("admin:home.contracts.status.scheduled"),
        tooltip: t("admin:home.contracts.status.tooltip.scheduled"),
      },
      {
        icon: <DotFilledIcon color="primary1.lighter" />,
        value: "pending",
        label: t("admin:home.contracts.status.pending"),
        tooltip: t("admin:home.contracts.status.tooltip.pending"),
      },
      {
        icon: <DotFilledIcon color="secondary2.main" />,
        value: "paymentRequired",
        label: t("admin:home.contracts.status.paymentRequired"),
        tooltip: t("admin:home.contracts.status.tooltip.paymentRequired"),
      },
      {
        icon: <DotFilledIcon color="primary1.lighter" />,
        value: "invalidated",
        label: t("admin:home.contracts.status.invalidated"),
        tooltip: t("admin:home.contracts.status.tooltip.invalidated"),
      },
    ],
  ];

  return (
    <Select<string>
      optionGroups={contractStatusSelectOptions}
      value={selectedStatusFilter}
      onChange={handleSelectedStatusFilterChange}
    />
  );
}
