/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BenefitDefinitionReferenceRead } from "./BenefitDefinitionReferenceRead";
import {
  BenefitDefinitionReferenceReadFromJSON,
  BenefitDefinitionReferenceReadFromJSONTyped,
  BenefitDefinitionReferenceReadToJSON,
} from "./BenefitDefinitionReferenceRead";
import type { EmployeeBenefitActivationStatus } from "./EmployeeBenefitActivationStatus";
import {
  EmployeeBenefitActivationStatusFromJSON,
  EmployeeBenefitActivationStatusFromJSONTyped,
  EmployeeBenefitActivationStatusToJSON,
} from "./EmployeeBenefitActivationStatus";

/**
 *
 * @export
 * @interface EmployeeUser
 */
export interface EmployeeUser {
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  benefitEmail: string;
  /**
   *
   * @type {Array<BenefitDefinitionReferenceRead>}
   * @memberof EmployeeUser
   */
  benefitDefinitions: Array<BenefitDefinitionReferenceRead>;
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUser
   */
  employeeNumber?: string;
  /**
   *
   * @type {EmployeeBenefitActivationStatus}
   * @memberof EmployeeUser
   */
  benefitActivationStatus: EmployeeBenefitActivationStatus;
}

/**
 * Check if a given object implements the EmployeeUser interface.
 */
export function instanceOfEmployeeUser(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "benefitEmail" in value;
  isInstance = isInstance && "benefitDefinitions" in value;
  isInstance = isInstance && "benefitActivationStatus" in value;

  return isInstance;
}

export function EmployeeUserFromJSON(json: any): EmployeeUser {
  return EmployeeUserFromJSONTyped(json, false);
}

export function EmployeeUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EmployeeUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fullName: !exists(json, "full_name") ? undefined : json["full_name"],
    benefitEmail: json["benefit_email"],
    benefitDefinitions: (json["benefit_definitions"] as Array<any>).map(
      BenefitDefinitionReferenceReadFromJSON,
    ),
    costCenter: !exists(json, "cost_center") ? undefined : json["cost_center"],
    employeeNumber: !exists(json, "employee_number")
      ? undefined
      : json["employee_number"],
    benefitActivationStatus: EmployeeBenefitActivationStatusFromJSON(
      json["benefit_activation_status"],
    ),
  };
}

export function EmployeeUserToJSON(value?: EmployeeUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    full_name: value.fullName,
    benefit_email: value.benefitEmail,
    benefit_definitions: (value.benefitDefinitions as Array<any>).map(
      BenefitDefinitionReferenceReadToJSON,
    ),
    cost_center: value.costCenter,
    employee_number: value.employeeNumber,
    benefit_activation_status: EmployeeBenefitActivationStatusToJSON(
      value.benefitActivationStatus,
    ),
  };
}
