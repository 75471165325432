import { useQuery } from "@tanstack/react-query";

import {
  BenefitDefinitionAccessRulesRead,
  ProviderBenefitDefinitionAccessRulesApi,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const providerBenefitDefinitionAccessRulesApi =
  new ProviderBenefitDefinitionAccessRulesApi(apiConfiguration);

export const useGetBenefitDefinitionAccessRules = (
  id?: string,
  options?: UseQueryOptions<BenefitDefinitionAccessRulesRead>,
) =>
  useQuery<BenefitDefinitionAccessRulesRead, ResponseError>(
    ["benefitDefinitionsAccessRules", id],
    () =>
      providerBenefitDefinitionAccessRulesApi.providerBenefitDefinitionAccessRulesGetAccessRules(
        {
          accessRulesUuid: id as string,
        },
      ),
    {
      ...options,
      enabled: !!id,
    },
  );
