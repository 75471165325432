/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  ReportType,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ReportTypeFromJSON,
  ReportTypeToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderReportsGetLeasingLiabilityReportCsvRequest {
  benefitDefinitionIds: Set<string>;
  startDate: Date;
  endDate: Date;
  reportType?: ReportType;
}

export interface ProviderReportsGetProviderSearchBikeBenefitContractsCsvRequest {
  benefitDefinitionIds: Set<string>;
  startDate: Date;
  endDate: Date;
  reportType?: ReportType;
}

/**
 *
 */
export class ProviderReportsApi extends runtime.BaseAPI {
  /**
   * Get Leasing Liability Report Csv
   */
  async providerReportsGetLeasingLiabilityReportCsvRaw(
    requestParameters: ProviderReportsGetLeasingLiabilityReportCsvRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.benefitDefinitionIds === null ||
      requestParameters.benefitDefinitionIds === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionIds",
        "Required parameter requestParameters.benefitDefinitionIds was null or undefined when calling providerReportsGetLeasingLiabilityReportCsv.",
      );
    }

    if (
      requestParameters.startDate === null ||
      requestParameters.startDate === undefined
    ) {
      throw new runtime.RequiredError(
        "startDate",
        "Required parameter requestParameters.startDate was null or undefined when calling providerReportsGetLeasingLiabilityReportCsv.",
      );
    }

    if (
      requestParameters.endDate === null ||
      requestParameters.endDate === undefined
    ) {
      throw new runtime.RequiredError(
        "endDate",
        "Required parameter requestParameters.endDate was null or undefined when calling providerReportsGetLeasingLiabilityReportCsv.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.benefitDefinitionIds) {
      queryParameters["benefit_definition_ids"] =
        requestParameters.benefitDefinitionIds;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters["start_date"] = (requestParameters.startDate as any)
        .toISOString()
        .substring(0, 10);
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters["end_date"] = (requestParameters.endDate as any)
        .toISOString()
        .substring(0, 10);
    }

    if (requestParameters.reportType !== undefined) {
      queryParameters["report_type"] = requestParameters.reportType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/reports/leasing-liability-report-csv`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Get Leasing Liability Report Csv
   */
  async providerReportsGetLeasingLiabilityReportCsv(
    requestParameters: ProviderReportsGetLeasingLiabilityReportCsvRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<any> {
    const response = await this.providerReportsGetLeasingLiabilityReportCsvRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Provider Search Bike Benefit Contracts Csv
   */
  async providerReportsGetProviderSearchBikeBenefitContractsCsvRaw(
    requestParameters: ProviderReportsGetProviderSearchBikeBenefitContractsCsvRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.benefitDefinitionIds === null ||
      requestParameters.benefitDefinitionIds === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionIds",
        "Required parameter requestParameters.benefitDefinitionIds was null or undefined when calling providerReportsGetProviderSearchBikeBenefitContractsCsv.",
      );
    }

    if (
      requestParameters.startDate === null ||
      requestParameters.startDate === undefined
    ) {
      throw new runtime.RequiredError(
        "startDate",
        "Required parameter requestParameters.startDate was null or undefined when calling providerReportsGetProviderSearchBikeBenefitContractsCsv.",
      );
    }

    if (
      requestParameters.endDate === null ||
      requestParameters.endDate === undefined
    ) {
      throw new runtime.RequiredError(
        "endDate",
        "Required parameter requestParameters.endDate was null or undefined when calling providerReportsGetProviderSearchBikeBenefitContractsCsv.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.benefitDefinitionIds) {
      queryParameters["benefit_definition_ids"] =
        requestParameters.benefitDefinitionIds;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters["start_date"] = (requestParameters.startDate as any)
        .toISOString()
        .substring(0, 10);
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters["end_date"] = (requestParameters.endDate as any)
        .toISOString()
        .substring(0, 10);
    }

    if (requestParameters.reportType !== undefined) {
      queryParameters["report_type"] = requestParameters.reportType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/reports/bike-benefit-contracts-csv`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Get Provider Search Bike Benefit Contracts Csv
   */
  async providerReportsGetProviderSearchBikeBenefitContractsCsv(
    requestParameters: ProviderReportsGetProviderSearchBikeBenefitContractsCsvRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<any> {
    const response =
      await this.providerReportsGetProviderSearchBikeBenefitContractsCsvRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
