import * as React from "react";

import { useTranslation } from "react-i18next";

import { PageRoot } from "@vapaus/common-v2";
import { Card, Flex, Typography } from "@vapaus/ui-v2";

import { useOrganisationAndBenefitsContext } from "../../contexts/OrganisationAndBenefitsContext";
import { ReportForm } from "./components/ReportForm";

export function ReportsPage() {
  const { t } = useTranslation();
  const { selectedOrganisation } = useOrganisationAndBenefitsContext();

  return (
    <PageRoot size="narrower">
      <Flex direction="column" gap="lg">
        <Flex direction="column">
          <Flex direction="column" gap="xxs">
            <Typography variant="h6" marginBottom="none" xsAlign="center">
              {selectedOrganisation?.name}
            </Typography>
            <Typography variant="h1" xsAlign="center">
              {t("admin:reports.title")}
            </Typography>
          </Flex>
          <Typography xsAlign="center">
            {t("admin:reports.description")}
          </Typography>
        </Flex>
        <Card>
          <Flex direction="column" gap="lg">
            <Flex direction="column" gap="md">
              <Typography variant="h2">
                {t("admin:reports.card.title")}
              </Typography>
              <Typography>{t("admin:reports.card.description")}</Typography>
            </Flex>
            <ReportForm />
          </Flex>
        </Card>
      </Flex>
    </PageRoot>
  );
}
