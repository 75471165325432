import * as React from "react";

import { useTranslation } from "react-i18next";

import { InfoCard } from "@vapaus/common-v2";
import { CircularIcon, SupportIcon } from "@vapaus/ui-v2";

export function EmployerPortalGuideInfoCard() {
  const { t } = useTranslation();

  return (
    <InfoCard
      icon={<CircularIcon icon={SupportIcon} />}
      title={t("admin:support.guidesAndFaqs.employerPortalGuide.title")}
      description={t(
        "admin:support.guidesAndFaqs.employerPortalGuide.description",
      )}
      buttonText={t("admin:support.guidesAndFaqs.viewGuide")}
      link="https://tyosuhdepyora.vapaus.io/fi/provider-portal-guide"
      target="_blank"
      backgroundColor="secondary1.lighter"
      shadow="none"
      variant="small"
    />
  );
}
