/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedUserBenefitActivationOut,
  UserBenefitActivationRead,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedUserBenefitActivationOutFromJSON,
  PaginatedUserBenefitActivationOutToJSON,
  UserBenefitActivationReadFromJSON,
  UserBenefitActivationReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderUserBenefitActivationsProviderGetUserBenefitDefinitionsActivationsRequest {
  userId: string;
  benefitDefinitionIds: Set<string>;
  activeOnly?: boolean;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface ProviderUserBenefitActivationsRevokeUserBenefitActivationRequest {
  userBenefitActivationId: string;
}

/**
 *
 */
export class ProviderUserBenefitActivationsApi extends runtime.BaseAPI {
  /**
   * Provider Get User Benefit Definitions Activations
   */
  async providerUserBenefitActivationsProviderGetUserBenefitDefinitionsActivationsRaw(
    requestParameters: ProviderUserBenefitActivationsProviderGetUserBenefitDefinitionsActivationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedUserBenefitActivationOut>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling providerUserBenefitActivationsProviderGetUserBenefitDefinitionsActivations.",
      );
    }

    if (
      requestParameters.benefitDefinitionIds === null ||
      requestParameters.benefitDefinitionIds === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionIds",
        "Required parameter requestParameters.benefitDefinitionIds was null or undefined when calling providerUserBenefitActivationsProviderGetUserBenefitDefinitionsActivations.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.activeOnly !== undefined) {
      queryParameters["active_only"] = requestParameters.activeOnly;
    }

    if (requestParameters.benefitDefinitionIds) {
      queryParameters["benefit_definition_ids"] =
        requestParameters.benefitDefinitionIds;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/user-benefit-activations/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedUserBenefitActivationOutFromJSON(jsonValue),
    );
  }

  /**
   * Provider Get User Benefit Definitions Activations
   */
  async providerUserBenefitActivationsProviderGetUserBenefitDefinitionsActivations(
    requestParameters: ProviderUserBenefitActivationsProviderGetUserBenefitDefinitionsActivationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedUserBenefitActivationOut> {
    const response =
      await this.providerUserBenefitActivationsProviderGetUserBenefitDefinitionsActivationsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Revoke User Benefit Activation
   */
  async providerUserBenefitActivationsRevokeUserBenefitActivationRaw(
    requestParameters: ProviderUserBenefitActivationsRevokeUserBenefitActivationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserBenefitActivationRead>> {
    if (
      requestParameters.userBenefitActivationId === null ||
      requestParameters.userBenefitActivationId === undefined
    ) {
      throw new runtime.RequiredError(
        "userBenefitActivationId",
        "Required parameter requestParameters.userBenefitActivationId was null or undefined when calling providerUserBenefitActivationsRevokeUserBenefitActivation.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/user-benefit-activations/{user_benefit_activation_id}/revoke`.replace(
          `{${"user_benefit_activation_id"}}`,
          encodeURIComponent(String(requestParameters.userBenefitActivationId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserBenefitActivationReadFromJSON(jsonValue),
    );
  }

  /**
   * Revoke User Benefit Activation
   */
  async providerUserBenefitActivationsRevokeUserBenefitActivation(
    requestParameters: ProviderUserBenefitActivationsRevokeUserBenefitActivationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserBenefitActivationRead> {
    const response =
      await this.providerUserBenefitActivationsRevokeUserBenefitActivationRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
