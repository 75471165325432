import { BikeBenefitContractProviderRead } from "@vapaus/api-codegen";
import { Drawer } from "@vapaus/ui-v2";

import { ContractDetails } from "./ContractDetails";

type ContractDetailsDrawerProps = {
  contract?: BikeBenefitContractProviderRead;
  onClose: () => void;
};

export function ContractDetailsDrawer({
  contract,
  onClose,
}: ContractDetailsDrawerProps) {
  return (
    <Drawer isOpen={!!contract} onClose={onClose}>
      {contract ? <ContractDetails contract={contract} /> : null}
    </Drawer>
  );
}
