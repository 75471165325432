import { useTranslation } from "react-i18next";

import { useUserCountry } from "@vapaus/common-v2";
import { TextButton } from "@vapaus/ui-v2";

export function ContactUsButton() {
  const { t } = useTranslation();
  const { isSwedishUser } = useUserCountry();

  return (
    <TextButton
      to={
        isSwedishUser
          ? t("admin:sweden.adminPage.footer.link")
          : t("admin:adminPage.footer.link")
      }
      target="_blank"
      size="md"
    >
      {t("admin:adminPage.footer.button")}
    </TextButton>
  );
}
