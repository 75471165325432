import { useTranslation } from "react-i18next";

import { Form, Input, Notification } from "@vapaus/form";
import { Flex } from "@vapaus/ui-v2";

import { useIsMepcoIntegrationEnabled } from "../../../../../../hooks/useIsMepcoIntegrationEnabled";
import { PendingModalFormSelect } from "./components/PendingModalFormSelect";
import {
  RequestModal,
  RequestModalCommonProps,
} from "./components/RequestModal";
import { useRequestPendingModal } from "./useRequestPendingModal";

export function RequestPendingModal({
  isOpen,
  onClose,
  onCancel,
  request,
}: RequestModalCommonProps) {
  const { t } = useTranslation();
  const isMepcoIntegrationEnabled = useIsMepcoIntegrationEnabled();
  const { formMethods, mutation } = useRequestPendingModal({
    request,
    onClose: onClose!,
  });

  return (
    <RequestModal
      isOpen={isOpen}
      onClose={onClose}
      request={request}
      description={t("admin:home.requests.pendingModal.description")}
      onCancel={onCancel}
      cancelText={t("admin:home.requests.pendingModal.form.deny")}
      shouldCloseOnCancel={false}
      cancelButtonVariant="primaryDanger"
      onConfirm={formMethods._handleSubmit}
      confirmText={t("admin:home.requests.pendingModal.form.approve")}
      isSubmitting={mutation.isLoading}
    >
      <Form formMethods={formMethods}>
        <Flex direction="column" fullWidth>
          <Notification />
          <PendingModalFormSelect />
          <Input
            name="employeeId"
            label={t("admin:home.requests.pendingModal.form.employeeId.label")}
            placeholder={t(
              "admin:home.requests.pendingModal.form.employeeId.placeholder",
            )}
          />
          <Input
            name="costCenter"
            label={t("admin:home.requests.pendingModal.form.costCenter.label")}
            placeholder={t(
              "admin:home.requests.pendingModal.form.costCenter.placeholder",
            )}
          />
          {isMepcoIntegrationEnabled ? (
            <>
              <Input
                name="payGroupId"
                label={t("admin:mepcoFields.payGroupId")}
                placeholder={t("admin:mepcoFields.payGroupId")}
              />
              <Input
                name="payComponent"
                label={t("admin:mepcoFields.payComponent")}
                placeholder={t("admin:mepcoFields.payComponent")}
              />
            </>
          ) : null}
        </Flex>
      </Form>
    </RequestModal>
  );
}
