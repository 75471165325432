import { useTranslation } from "react-i18next";

import { CollapsibleLineItem, Flex, RowItem } from "@vapaus/ui-v2";

import { useGetUserMepcoIntegrationSettings } from "../../../../../../api/mepco";
import { useIsMepcoIntegrationEnabled } from "../../../../../../hooks/useIsMepcoIntegrationEnabled";
import { ContractProps } from "../../../type";

export function ContractEmployeeDetails({ contract }: ContractProps) {
  const { t } = useTranslation();
  const isMepcoIntegrationEnabled = useIsMepcoIntegrationEnabled();
  const { data: mepcoIntegrationSettings } = useGetUserMepcoIntegrationSettings(
    contract.userId,
    contract.benefitDefinition.organisation.id,
    {
      enabled: isMepcoIntegrationEnabled,
    },
  );
  return (
    <CollapsibleLineItem
      title={t("admin:contractDetails.employeeDetails.title")}
    >
      <Flex data-testid="employee-details" direction="column" gap="md">
        <RowItem
          label={t("admin:contractDetails.employeeDetails.name")}
          value={contract.user.fullName || "-"}
        />
        <RowItem
          label={t("admin:contractDetails.employeeDetails.email")}
          value={contract.beneficiaryEmail || "-"}
        />

        <RowItem
          label={t("admin:contractDetails.employeeDetails.employeeId")}
          value={contract.user.employeeNumber || "-"}
        />
        <RowItem
          label={t("admin:contractDetails.employeeDetails.costCenter")}
          value={contract.user.costCenter || "-"}
        />
        {isMepcoIntegrationEnabled ? (
          <>
            <RowItem
              label={t("admin:mepcoFields.payGroupId")}
              value={mepcoIntegrationSettings?.payGroupId ?? "-"}
            />
            <RowItem
              label={t("admin:mepcoFields.payComponent")}
              value={mepcoIntegrationSettings?.payComponent ?? "-"}
            />
          </>
        ) : null}
      </Flex>
    </CollapsibleLineItem>
  );
}
