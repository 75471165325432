import { useTranslation } from "react-i18next";

import { Select } from "@vapaus/form";

import { useOrganisationAndBenefitsContext } from "../../../../../../../contexts/OrganisationAndBenefitsContext";

export function PendingModalFormSelect() {
  const { t } = useTranslation();
  const { selectedOrganisation } = useOrganisationAndBenefitsContext();

  const benefitItems =
    selectedOrganisation?.benefits
      .filter((benefit) => benefit.active)
      .map((benefit) => ({
        value: benefit.id,
        label: benefit.name,
      })) ?? [];

  return (
    <Select
      name="benefitDefinitionId"
      label={t("admin:home.requests.pendingModal.form.bikeBenefit.label")}
      placeholder={t(
        "admin:home.requests.pendingModal.form.bikeBenefit.placeholder",
      )}
      options={benefitItems}
    />
  );
}
