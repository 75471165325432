import { Outlet, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import invariant from "tiny-invariant";

import { PageSpinner } from "@vapaus/ui-v2";

import { useGetUserMepcoIntegrationSettings } from "../../api/mepco";
import { useGetUser } from "../../api/user";
import { useIsMepcoIntegrationEnabled } from "../../hooks/useIsMepcoIntegrationEnabled";
import { EmployeeViewHero } from "./components/EmployeeViewHero";

export function EmployeeViewPage() {
  const { employeeId, organisationId } = useParams();
  invariant(employeeId);
  const { data: user } = useGetUser(employeeId);
  const isMepcoIntegrationEnabled = useIsMepcoIntegrationEnabled();
  const { data: mepcoIntegrationSettings } = useGetUserMepcoIntegrationSettings(
    employeeId,
    organisationId,
    {
      enabled: isMepcoIntegrationEnabled,
    },
  );
  if (!user) return <PageSpinner />;

  return (
    <div>
      <EmployeeViewHero user={user} />
      <StyledRoot>
        <StyledContainer data-testid="employee-tab-content">
          <Outlet
            context={{
              user,
              organisationId,
              isMepcoIntegrationEnabled,
              mepcoIntegrationSettings,
            }}
          />
        </StyledContainer>
      </StyledRoot>
    </div>
  );
}

const StyledRoot = styled.div`
  margin: ${({ theme }) => theme.spacing.lg} ${({ theme }) => theme.spacing.xl};

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      margin: ${({ theme }) => theme.spacing.md};
    `)}
`;

const StyledContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;
