import * as React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUserCountry } from "@vapaus/common-v2";
import { Button, Card, Flex, Typography } from "@vapaus/ui-v2";

export function ContactUsCard() {
  const { t } = useTranslation();
  const { isSwedishUser } = useUserCountry();

  const link = isSwedishUser
    ? "https://vapaus.se/kontakt"
    : t("admin:support.contactUs.link");

  return (
    <Card>
      <Flex direction="column" gap="md">
        <Flex direction="column" gap="sm">
          <Typography variant="h2">
            {t("admin:support.contactUs.title")}
          </Typography>
          <Typography variant="bodyLg">
            {t("admin:support.contactUs.description")}
          </Typography>
        </Flex>
        <StyledButtonWrapper>
          <a href={link} target="_blank">
            <Button>{t("admin:support.contactUs.button")}</Button>
          </a>
        </StyledButtonWrapper>
      </Flex>
    </Card>
  );
}

const StyledButtonWrapper = styled.div`
  width: fit-content;
`;
