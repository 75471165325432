import { ReactNode } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Flex, Tag, Typography } from "@vapaus/ui-v2";

type AuthenticationCardProps = {
  enabled: boolean;
  title: string;
  subtitle: string;
  children?: ReactNode;
  statusText?: string;
};

export function AuthenticationCard({
  enabled,
  title,
  subtitle,
  statusText,
  children,
}: AuthenticationCardProps) {
  const { t } = useTranslation();
  return (
    <StyledRoot gap="sm" padding="md" direction="column" enabled={enabled}>
      <Flex direction="column" gap="xs">
        <Typography weight="bold">{title}</Typography>
        <Typography>{subtitle}</Typography>
      </Flex>
      <Flex gap="xs">
        <Tag
          text={
            statusText ||
            (enabled
              ? t("admin:adminPage.benefitTab.authentication.enabled")
              : t("admin:adminPage.benefitTab.authentication.disabled"))
          }
          variant={enabled ? "accepted" : "done"}
        />
        {children}
      </Flex>
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)<{ enabled: boolean }>`
  background: ${({ theme, enabled }) =>
    enabled ? theme.palette.secondary1.lighter : theme.palette.neutral.light};
  border-radius: ${({ theme }) => theme.spacing.xs};
`;
