import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { Flex, Modal, ModalProps, Typography, useToaster } from "@vapaus/ui-v2";

import { useDenyAccessRequest } from "../../../../../../api/accessRequests";
import { RequestsTableDataType } from "../RequestsTable/RequestsTable";

type DenyRequestConfirmationModalProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  request?: RequestsTableDataType;
};

export function DenyRequestConfirmationModal({
  isOpen,
  onClose,
  request,
}: DenyRequestConfirmationModalProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toaster = useToaster();

  const { mutate, isLoading } = useDenyAccessRequest({
    benefitDefinitionAccessRequestId: request?.accessRequestId ?? "",
  });

  const onConfirm = () =>
    mutate(undefined, {
      onSuccess: async () => {
        onClose?.();
        toaster.add(
          "success",
          t("admin:home.requests.denyRequestConfirmationModal.successMessage"),
        );
        await queryClient.invalidateQueries(["accessRequests"]);
      },
    });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      cancelText={t("commonV2:cancel")}
      confirmButtonVariant="primaryDanger"
      onConfirm={onConfirm}
      confirmText={t(
        "admin:home.requests.denyRequestConfirmationModal.denyRequest",
      )}
      isSubmitting={isLoading}
    >
      <Flex direction="column" align="center" gap="md">
        <Typography variant="h2" align="center">
          {t("admin:home.requests.denyRequestConfirmationModal.denyRequest")}
        </Typography>
        <Typography variant="bodyLg" align="center">
          {t("admin:home.requests.denyRequestConfirmationModal.description", {
            email: request?.employee.email,
          })}
        </Typography>
      </Flex>
    </Modal>
  );
}
