/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BenefitDefinitionAccessRulesRequestRequirements } from "./BenefitDefinitionAccessRulesRequestRequirements";
import {
  BenefitDefinitionAccessRulesRequestRequirementsFromJSON,
  BenefitDefinitionAccessRulesRequestRequirementsFromJSONTyped,
  BenefitDefinitionAccessRulesRequestRequirementsToJSON,
} from "./BenefitDefinitionAccessRulesRequestRequirements";

/**
 *
 * @export
 * @interface BenefitDefinitionAccessRulesRead
 */
export interface BenefitDefinitionAccessRulesRead {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  name: string;
  /**
   * array of authorized emails. Supports specific emails (someone@somewhere.eu) and wildcards (@somewhere.eu)
   * @type {Array<string>}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  allowedEmailAddresses?: Array<string>;
  /**
   * array of blacklisted emails.
   * @type {Array<string>}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  blacklistEmails?: Array<string>;
  /**
   *
   * @type {BenefitDefinitionAccessRulesRequestRequirements}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  requestRequirement: BenefitDefinitionAccessRulesRequestRequirements;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  organisationId: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  createdAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionAccessRulesRead
   */
  isActive: boolean;
}

/**
 * Check if a given object implements the BenefitDefinitionAccessRulesRead interface.
 */
export function instanceOfBenefitDefinitionAccessRulesRead(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "requestRequirement" in value;
  isInstance = isInstance && "uuid" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "organisationId" in value;
  isInstance = isInstance && "isActive" in value;

  return isInstance;
}

export function BenefitDefinitionAccessRulesReadFromJSON(
  json: any,
): BenefitDefinitionAccessRulesRead {
  return BenefitDefinitionAccessRulesReadFromJSONTyped(json, false);
}

export function BenefitDefinitionAccessRulesReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionAccessRulesRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    allowedEmailAddresses: !exists(json, "allowed_email_addresses")
      ? undefined
      : json["allowed_email_addresses"],
    blacklistEmails: !exists(json, "blacklist_emails")
      ? undefined
      : json["blacklist_emails"],
    requestRequirement: BenefitDefinitionAccessRulesRequestRequirementsFromJSON(
      json["request_requirement"],
    ),
    uuid: json["uuid"],
    id: json["id"],
    organisationId: json["organisation_id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    isActive: json["is_active"],
  };
}

export function BenefitDefinitionAccessRulesReadToJSON(
  value?: BenefitDefinitionAccessRulesRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    allowed_email_addresses: value.allowedEmailAddresses,
    blacklist_emails: value.blacklistEmails,
    request_requirement: BenefitDefinitionAccessRulesRequestRequirementsToJSON(
      value.requestRequirement,
    ),
    uuid: value.uuid,
    id: value.id,
    organisation_id: value.organisationId,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    is_active: value.isActive,
  };
}
