import * as React from "react";

import { useTranslation } from "react-i18next";

import { SearchBar } from "@vapaus/ui-v2";

import { useRequestsTable } from "./RequestsTable/useRequestsTable";

export function RequestSearchBar() {
  const { t } = useTranslation();
  const { searchQuery, setSearchQuery } = useRequestsTable();

  return (
    <SearchBar
      onSearch={setSearchQuery}
      defaultValue={searchQuery}
      placeholder={t("commonV2:search")}
      fullWidth
    />
  );
}
