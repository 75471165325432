import { useTranslation } from "react-i18next";

import { renderBenefitName } from "../../../../../../../utils/renderBenefitName";
import { BenefitDefinition } from "../EmployeesTable";

type BenefitCellProps = {
  benefits: BenefitDefinition[];
};

export function BenefitCell({ benefits }: BenefitCellProps) {
  const { t } = useTranslation();

  const getCellContent = (): string => {
    if (benefits.length === 0) {
      return "-";
    }

    const activeBenefits = benefits.filter((benefit) => benefit.active);
    if (activeBenefits.length === 1) {
      return activeBenefits[0].name;
    }

    if (activeBenefits.length > 1) {
      return t("admin:home.employees.table.benefitCell.multipleBenefits", {
        numberOfBenefits: activeBenefits.length,
      });
    }

    if (benefits.length > 1) {
      return t("admin:home.employees.table.benefitCell.multipleBenefits", {
        numberOfBenefits: benefits.length,
      });
    }

    return renderBenefitName(benefits[0].name, benefits[0].active, t);
  };

  return <article>{getCellContent()}</article>;
}
