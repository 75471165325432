import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CurrentUserRead } from "@vapaus/api-codegen";
import { useLogout } from "@vapaus/auth-v2";
import { useCurrentUser } from "@vapaus/shared-api";

import { Path } from "../constants/paths";
import {
  OrganisationWithBenefits,
  OrganisationsWithBenefits,
  benefitsToOrganisations,
} from "./benefitsToOrganisations";
import {
  retrieveOrganisationId,
  storeOrganisationId,
} from "./localStorageHandlers";

export type OrganisationAndBenefitsContextValue = {
  user?: CurrentUserRead;
  isLoading: boolean;
  organisationsWithBenefits: OrganisationsWithBenefits;
  selectedOrganisation?: OrganisationWithBenefits;
  changeSelectedOrganisation: (organisationId: string) => void;
  availableBenefitIds: Array<string>;
};

export const OrganisationAndBenefitsContext =
  createContext<OrganisationAndBenefitsContextValue>({
    user: undefined,
    isLoading: true,
    organisationsWithBenefits: [],
    selectedOrganisation: undefined,
    changeSelectedOrganisation: () => {},
    availableBenefitIds: [],
  });

type OrganisationAndBenefitsContextProviderProps = {
  children: ReactNode;
};

export const OrganisationAndBenefitsContextProvider = ({
  children,
}: OrganisationAndBenefitsContextProviderProps) => {
  const logout = useLogout();
  const { organisationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: user, isLoading } = useCurrentUser({
    staleTime: 0,
  });

  const managedBenefits = useMemo(() => user?.managedBenefits || [], [user]);
  const organisationsWithBenefits = useMemo(
    () => benefitsToOrganisations(managedBenefits),
    [managedBenefits],
  );

  const selectedOrganisation = organisationsWithBenefits.find(
    (org) => org.id === organisationId || org.id === retrieveOrganisationId(),
  );
  const availableOrganisationIds: Array<string> = useMemo(
    () => organisationsWithBenefits.map((org) => org.id),
    [organisationsWithBenefits],
  );

  const availableBenefitIds: Array<string> = useMemo(
    () => selectedOrganisation?.benefits.map((benefit) => benefit.id) || [],
    [selectedOrganisation],
  );

  const changeSelectedOrganisation = useCallback(
    (organisationId: string) => {
      const slashCount = (location.pathname.match(/\//g) || []).length - 1;
      const root = "../".repeat(slashCount);
      navigate(`${root}${organisationId}/${Path.home}`);
      storeOrganisationId(organisationId);
      queryClient.clear();
    },
    [location.pathname, navigate, queryClient],
  );

  useEffect(() => {
    const firstOrganisationId = availableOrganisationIds[0];
    if (!firstOrganisationId) {
      logout();
      return;
    }
    if (!selectedOrganisation) {
      navigate(`/${firstOrganisationId}`);
      storeOrganisationId(firstOrganisationId);
      return;
    }
    if (!organisationId) {
      navigate(`/${retrieveOrganisationId()}`);
      return;
    }
    if (!retrieveOrganisationId()) {
      storeOrganisationId(organisationId);
    }
  }, [
    selectedOrganisation,
    logout,
    navigate,
    availableOrganisationIds,
    organisationId,
  ]);

  const contextValue = useMemo(
    () => ({
      user,
      isLoading,
      organisationsWithBenefits,
      selectedOrganisation,
      changeSelectedOrganisation,
      availableBenefitIds,
    }),
    [
      user,
      isLoading,
      organisationsWithBenefits,
      selectedOrganisation,
      changeSelectedOrganisation,
      availableBenefitIds,
    ],
  );

  return (
    <OrganisationAndBenefitsContext.Provider value={contextValue}>
      {children}
    </OrganisationAndBenefitsContext.Provider>
  );
};

export const useOrganisationAndBenefitsContext = () =>
  useContext(OrganisationAndBenefitsContext);
