import { Navigate, RouteObject } from "react-router-dom";

import { Path } from "../../constants/paths";
import { EmployeeViewPage } from "./EmployeeViewPage";
import { ContractsTab } from "./components/ContractsTab";
import { PermissionsTab } from "./components/PermissionsTab";
import { ProfileTab } from "./components/ProfileTab";

export const employeeViewRoutes: RouteObject = {
  path: Path.employee + "/:employeeId",
  element: <EmployeeViewPage />,
  children: [
    {
      index: true,
      element: <Navigate to={Path.contracts} />,
    },
    {
      path: Path.contracts,
      element: <ContractsTab />,
    },
    {
      path: Path.employeePermissions,
      element: <PermissionsTab />,
    },
    {
      path: Path.profile,
      element: <ProfileTab />,
    },
  ],
};
