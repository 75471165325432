import { useState } from "react";

import { ColumnDef, Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { BikeBenefitContractProviderRead, UserRead } from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import { Card, Lens3DIcon, Table, useBreakpoints } from "@vapaus/ui-v2";

import { ContractDetailsDrawer } from "../../../../components/ContractDetailsDrawer";
import { BikeCell } from "./components/BikeCell";
import { MonthsLeftCell } from "./components/MonthsLeftCell";
import { StatusCell } from "./components/StatusCell";
import { useContractTabsTable } from "./useContractTabsTable";

export function ContractsTab() {
  const { user } = useOutletContext<{ user: UserRead }>();
  const { t } = useTranslation();
  const { isXs } = useBreakpoints();
  const [selectedContract, setSelectedContract] =
    useState<BikeBenefitContractProviderRead>();
  const { paginationOptions, sortingOptions, data } = useContractTabsTable(
    user.id,
  );

  const columns: ColumnDef<BikeBenefitContractProviderRead>[] = [
    {
      id: "bike",
      header: t("admin:employeeViewPage.contracts.table.bike"),
      cell: ({ row }) => <BikeCell contract={row.original} />,
      accessorFn: (row) => row.vapausCode,
      enableSorting: true,
      size: 40,
    },
    ...(isXs
      ? []
      : [
          {
            id: "monthsLeft",
            header: t("admin:employeeViewPage.contracts.table.monthsLeft"),
            cell: ({ row }: { row: Row<BikeBenefitContractProviderRead> }) => (
              <MonthsLeftCell contract={row.original} />
            ),
            accessorFn: (row: BikeBenefitContractProviderRead) => row.startDate,
            enableSorting: true,
          },
        ]),
    {
      id: "status",
      header: t("admin:employeeViewPage.contracts.table.status"),
      cell: ({ row }) => <StatusCell status={row.original.state} />,
      accessorFn: (row) => row.state,
      enableSorting: true,
      size: 25,
    },
  ];

  return (
    <Card title={t("admin:employeeViewPage.contracts.table.title")}>
      <Table<BikeBenefitContractProviderRead>
        data={data}
        columns={columns}
        paginationOptions={paginationOptions}
        sortingOptions={sortingOptions}
        emptyStateComponent={
          <TableEmptyState
            icon={<Lens3DIcon size="sm" />}
            title={t("admin:employeeViewPage.contracts.table.emptyState.title")}
            subtitle={t(
              "admin:employeeViewPage.contracts.table.emptyState.subtitle",
            )}
          />
        }
        onRowClick={setSelectedContract}
      />
      <ContractDetailsDrawer
        contract={selectedContract}
        onClose={() => setSelectedContract(undefined)}
      />
    </Card>
  );
}
