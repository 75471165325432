import { Navigate, RouteObject } from "react-router-dom";

import { Path } from "../../constants/paths";
import { adminSettingsBenefitsTabRoutes } from "./components/AdminSettingsBenefitsTab";
import { AdminSettingsOrganisationTab } from "./components/AdminSettingsOrganisationTab";
import { AdminSettingsPlanTab } from "./components/AdminSettingsPlanTab";

export const adminSettingsRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={Path.adminOrganization} />,
  },
  {
    path: Path.adminOrganization,
    element: <AdminSettingsOrganisationTab />,
  },
  adminSettingsBenefitsTabRoutes,
  {
    path: Path.adminPlan,
    element: <AdminSettingsPlanTab />,
  },
];
