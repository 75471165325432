import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUserCountry } from "@vapaus/common-v2";
import { useEnumEntries } from "@vapaus/shared-api";
import {
  Card,
  Flex,
  HorizontalLine,
  LineItem,
  Typography,
} from "@vapaus/ui-v2";

import { useOrganisationAndBenefitsContext } from "../../../../contexts/OrganisationAndBenefitsContext";
import { ContactUsButton } from "../ContactUsButton";

export function AdminSettingsPlanTab() {
  const { t } = useTranslation();
  const { selectedOrganisation } = useOrganisationAndBenefitsContext();
  const { getEnumLabel } = useEnumEntries("Plan");
  const activeBenefit = (selectedOrganisation?.benefits ?? []).find(
    (benefit) => benefit.active,
  );
  const planName = getEnumLabel(activeBenefit?.plan);
  const { isSwedishUser } = useUserCountry();

  return (
    <Card
      title={t("admin:adminPage.planTab.title")}
      subtitle={
        activeBenefit ? (
          <Trans
            t={t}
            i18nKey="admin:adminPage.planTab.description"
            components={{
              a: (
                <StyledLink
                  href={
                    isSwedishUser
                      ? t("admin:sweden.adminPage.footer.link")
                      : t("admin:adminPage.footer.link")
                  }
                  target="_blank"
                />
              ),
            }}
            values={{ plan: planName }}
          />
        ) : (
          t("admin:adminPage.planTab.noActiveBenefitDescription")
        )
      }
    >
      <Flex direction="column" gap="lg">
        {activeBenefit ? (
          <LineItem
            name={t("admin:adminPage.planTab.plan")}
            content={planName}
          />
        ) : null}

        <HorizontalLine marginBottom="none" marginTop="none" />

        <Flex direction="column" align="center" gap="xs">
          <Typography align="center">
            {t("admin:adminPage.planTab.footerMessage")}
          </Typography>
          <ContactUsButton />
        </Flex>
      </Flex>
    </Card>
  );
}

const StyledLink = styled.a`
  font-weight: normal !important;
`;
