import { useTranslation } from "react-i18next";

type MonthsLeftCellProps = {
  monthsLeft: number;
};

export function MonthsLeftCell({ monthsLeft }: MonthsLeftCellProps) {
  const { t } = useTranslation();

  return (
    <article>
      {t("admin:home.contracts.table.monthsLeftCell.monthsLeft", {
        monthsLeft,
      })}
    </article>
  );
}
