import { useDateFormat } from "../../../../../../../../../../packages/i18n/src/hooks";

type DateCellProps = {
  date: Date;
};

export function DateCell({ date }: DateCellProps) {
  const formatDate = useDateFormat();
  return <article>{formatDate(date)}</article>;
}
