import { QueryClient } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";

import { createAuthRoutes } from "@vapaus/auth-v2";

import { Layout } from "./components/Layout";
import { Path } from "./constants/paths";
import { accountSettingsRoutes } from "./pages/AccountSettingsPage";
import {
  AdminSettingsPage,
  adminSettingsRoutes,
} from "./pages/AdminSettingsPage";
import { employeeViewRoutes } from "./pages/EmployeeViewPage";
import { HomePage } from "./pages/HomePage/HomePage";
import { ContractsTab } from "./pages/HomePage/components/ContractsTab/ContractsTab";
import { EmployeesTab } from "./pages/HomePage/components/EmployeesTab/EmployeesTab";
import { RequestsTab } from "./pages/HomePage/components/RequestsTab/RequestsTab";
import { ReportsPage } from "./pages/ReportsPage/ReportsPage";
import { SupportPage } from "./pages/SupportPage/SupportPage";

export const createRoutes = (queryClient: QueryClient) =>
  createAuthRoutes(
    {
      allowSignUp: false,
      app: "provider",
      scope: "provider",
    },
    [
      {
        path: ":organisationId?",
        element: <Layout />,
        children: [
          {
            index: true,
            element: <Navigate to={Path.home} replace />,
          },
          {
            path: Path.home,
            element: <HomePage />,
            children: [
              {
                index: true,
                element: <EmployeesTab />,
              },
              {
                path: Path.contracts,
                element: <ContractsTab />,
              },
              {
                path: Path.requests,
                element: <RequestsTab />,
              },
            ],
          },
          {
            path: Path.reports,
            element: <ReportsPage />,
          },
          {
            path: Path.support,
            element: <SupportPage />,
          },
          {
            path: Path.admin,
            element: <AdminSettingsPage />,
            children: adminSettingsRoutes,
          },
          accountSettingsRoutes,
          employeeViewRoutes,
        ],
      },
    ],
  );
