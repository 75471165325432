/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  ProviderNotificationCategory,
  ProviderNotificationPreference,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ProviderNotificationCategoryFromJSON,
  ProviderNotificationCategoryToJSON,
  ProviderNotificationPreferenceFromJSON,
  ProviderNotificationPreferenceToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ProviderNotificationPreferencesProviderUpdateNotificationsPreferencesRequest {
  providerNotificationCategory: Array<ProviderNotificationCategory>;
}

/**
 *
 */
export class ProviderNotificationPreferencesApi extends runtime.BaseAPI {
  /**
   * Provider Get Notifications Preferences
   */
  async providerNotificationPreferencesProviderGetNotificationsPreferencesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ProviderNotificationPreference>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/notification-preferences`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ProviderNotificationPreferenceFromJSON),
    );
  }

  /**
   * Provider Get Notifications Preferences
   */
  async providerNotificationPreferencesProviderGetNotificationsPreferences(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ProviderNotificationPreference>> {
    const response =
      await this.providerNotificationPreferencesProviderGetNotificationsPreferencesRaw(
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Provider Update Notifications Preferences
   */
  async providerNotificationPreferencesProviderUpdateNotificationsPreferencesRaw(
    requestParameters: ProviderNotificationPreferencesProviderUpdateNotificationsPreferencesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ProviderNotificationPreference>>> {
    if (
      requestParameters.providerNotificationCategory === null ||
      requestParameters.providerNotificationCategory === undefined
    ) {
      throw new runtime.RequiredError(
        "providerNotificationCategory",
        "Required parameter requestParameters.providerNotificationCategory was null or undefined when calling providerNotificationPreferencesProviderUpdateNotificationsPreferences.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/provider-api/notification-preferences`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.providerNotificationCategory.map(
          ProviderNotificationCategoryToJSON,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ProviderNotificationPreferenceFromJSON),
    );
  }

  /**
   * Provider Update Notifications Preferences
   */
  async providerNotificationPreferencesProviderUpdateNotificationsPreferences(
    requestParameters: ProviderNotificationPreferencesProviderUpdateNotificationsPreferencesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ProviderNotificationPreference>> {
    const response =
      await this.providerNotificationPreferencesProviderUpdateNotificationsPreferencesRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
