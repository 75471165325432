import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { UserRead } from "@vapaus/api-codegen";
import { Hero } from "@vapaus/common-v2";
import {
  BadgeIcon,
  Flex,
  OrderApproveIcon,
  PageSpinner,
  PersonIcon,
  TabRowItemProps,
  Tag,
  Typography,
} from "@vapaus/ui-v2";

import { Path } from "../../../../constants/paths";
import { useApprovalTag } from "./useApprovalTag";

interface EmployeeViewHeroProps {
  user: UserRead;
}

export function EmployeeViewHero({ user }: EmployeeViewHeroProps) {
  const { t } = useTranslation();

  const heroTabs: TabRowItemProps[] = [
    {
      icon: OrderApproveIcon,
      label: t("admin:employeeViewPage.hero.contracts"),
      to: Path.contracts,
    },
    {
      icon: BadgeIcon,
      label: t("admin:employeeViewPage.hero.permissions"),
      to: Path.employeePermissions,
    },
    {
      icon: PersonIcon,
      label: t("admin:employeeViewPage.hero.profile"),
      to: Path.profile,
    },
  ];

  const employeeNumberAndCityText = [user.employeeNumber, user.costCenter]
    .filter(Boolean)
    .join(" · ");

  const { approval: statusProps, isLoading } = useApprovalTag(user.id);

  if (isLoading) return <PageSpinner />;

  return (
    <Hero
      background="common.white"
      title={user.fullName ?? "-"}
      description={
        <Flex gap="xs" direction="column">
          <Typography>{user.email}</Typography>
          <Flex gap="xs" justify="center">
            <Typography weight="bold">{employeeNumberAndCityText}</Typography>
          </Flex>
        </Flex>
      }
      beforeContent={
        statusProps ? (
          <Tag
            variant={statusProps.variant}
            text={statusProps.text}
            size="large"
          />
        ) : null
      }
      afterContent={
        statusProps?.status === "approvedNoActivation" ? (
          <StyledDescription>
            <Typography variant="bodyLg" align="center">
              {t("admin:employeeViewPage.status.approvedNoActivationMessage")}
            </Typography>
          </StyledDescription>
        ) : null
      }
      tabs={heroTabs}
    />
  );
}

const StyledDescription = styled.div`
  max-width: 600px;
`;
