import { TFunction } from "i18next";

import { UserBenefitActivationRead } from "@vapaus/api-codegen";
import { TagVariant } from "@vapaus/ui-v2";

export type PermissionStatusType = {
  statusText: string;
  variant: TagVariant;
  status: "authorised" | "revoked" | "deactivated";
};

export function getPermissionStatus(
  t: TFunction,
  activation?: UserBenefitActivationRead,
  benefitActiveState?: boolean,
): PermissionStatusType | undefined {
  if (benefitActiveState === false) {
    return {
      statusText: t(
        "admin:employeeViewPage.permissions.permissionStatus.deactivated",
      ),
      variant: "done",
      status: "deactivated",
    };
  }
  if (activation?.isActive) {
    return {
      statusText: t(
        "admin:employeeViewPage.permissions.permissionStatus.authorised",
      ),
      variant: "accepted",
      status: "authorised",
    };
  } else if (activation?.revokedAt) {
    return {
      statusText: t(
        "admin:employeeViewPage.permissions.permissionStatus.revoked",
      ),
      variant: "cancelled",
      status: "revoked",
    };
  }
}
