import * as React from "react";

import { useTranslation } from "react-i18next";

import { Card, Flex, Typography, useBreakpoints } from "@vapaus/ui-v2";

import { EmployeeFaqInfoCard } from "./EmployeeFaqInfoCard";
import { EmployerFaqInfoCard } from "./EmployerFaqInfoCard";
import { EmployerPortalGuideInfoCard } from "./EmployerPortalGuideInfoCard";

export function GuidesAndFaqsCard() {
  const { t } = useTranslation();
  const { isSm, isMd } = useBreakpoints();

  return (
    <Card>
      <Flex direction="column" gap="lg">
        <Flex direction="column" gap="md">
          <Typography variant="h2">
            {t("admin:support.guidesAndFaqs.title")}
          </Typography>
          <Typography variant="bodyLg">
            {t("admin:support.guidesAndFaqs.description")}
          </Typography>
        </Flex>
        {isSm || isMd ? (
          <Flex direction="column">
            <Flex align="stretch">
              <EmployerPortalGuideInfoCard />
              <EmployerFaqInfoCard />
            </Flex>
            <EmployeeFaqInfoCard />
          </Flex>
        ) : (
          <Flex xsDirection="column" align="stretch">
            <EmployerPortalGuideInfoCard />
            <EmployerFaqInfoCard />
            <EmployeeFaqInfoCard />
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
