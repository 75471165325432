import { useMutation, useQuery } from "@tanstack/react-query";

import {
  type PaginatedEmployeeUserOut,
  ProviderUsersApi,
  ProviderUsersProviderSearchEmployeeUsersRequest,
  ProviderUsersProviderUpdateUserRequest,
  ResponseError,
  type UserRead,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const userApi = new ProviderUsersApi(apiConfiguration);

export const useGetUser = (
  userId?: string,
  options?: UseQueryOptions<UserRead>,
) =>
  useQuery<UserRead, ResponseError>({
    queryKey: ["user", userId],
    queryFn: () => userApi.providerUsersProviderGetUser({ userId: userId! }),
    enabled: !!userId,
    ...options,
  });

export const useUpdateUser = () =>
  useMutation<UserRead, ResponseError, ProviderUsersProviderUpdateUserRequest>({
    mutationFn: (data: ProviderUsersProviderUpdateUserRequest) =>
      userApi.providerUsersProviderUpdateUser(data),
  });

export const useSearchEmployees = (
  params: Omit<
    ProviderUsersProviderSearchEmployeeUsersRequest,
    "benefitDefinitionIds"
  > & { benefitDefinitionIds: Array<string> },
  options?: UseQueryOptions<PaginatedEmployeeUserOut>,
) =>
  useQuery<PaginatedEmployeeUserOut, ResponseError>({
    queryKey: ["employees", params],
    queryFn: () =>
      userApi.providerUsersProviderSearchEmployeeUsers({
        ...params,
        benefitDefinitionIds: new Set(params.benefitDefinitionIds),
      }),
    ...options,
  });
