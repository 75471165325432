import * as React from "react";
import { useMemo } from "react";

import { ColumnDef, Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { BenefitDefinitionAccessRequestStatus } from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import {
  BadgeIcon,
  KeyBox3DIcon,
  Lens3DIcon,
  Table,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { EmployeeCell } from "../../../../../../components/EmployeeCell";
import { renderBenefitName } from "../../../../../../utils/renderBenefitName";
import { DateCell } from "./components/DateCell";
import { StatusCell } from "./components/StatusCell";
import { useRequestsTable } from "./useRequestsTable";

export type Employee = {
  id: string;
  name: string;
  email: string;
  costCenter?: string;
  employeeId?: string;
};

export type RequestsTableDataType = {
  employee: Employee;
  createdAt: Date;
  status: BenefitDefinitionAccessRequestStatus;
  resolvedAt?: Date;
  resolvedByUserId?: string;
  accessRequestId: string;
  benefitDefinitionName: string;
  benefitDefinitionActive: boolean;
  benefitDefinitionId: string;
  organisationId: string;
};

type RequestsTableProps = {
  onRowClick: (row: RequestsTableDataType) => void;
};

export function RequestsTable({ onRowClick }: RequestsTableProps) {
  const { t } = useTranslation();
  const { isXs } = useBreakpoints();
  const {
    paginationOptions,
    sortingOptions,
    fetchedData: data,
    shouldShowNoPendingRequestsTableEmptyState,
  } = useRequestsTable();

  const getTableRows = (): RequestsTableDataType[] => {
    if (!data) {
      return [];
    }

    return data.items.map((item) => ({
      employee: {
        id: item.user.id,
        name: item.user.fullName ?? "-",
        email: item.user.email,
        costCenter: item.user.costCenter,
        employeeId: item.user.employeeNumber,
      },
      createdAt: item.createdAt ?? new Date(),
      status: item.status,
      resolvedAt: item.resolvedAt,
      resolvedByUserId: item.resolvedByUserId,
      accessRequestId: item.id,
      benefitDefinitionName: item.benefitDefinition?.name ?? "-",
      benefitDefinitionActive: item.benefitDefinition?.active ?? true,
      benefitDefinitionId: item.benefitDefinition?.id ?? "",
      organisationId: item.benefitDefinition?.organisation?.id ?? "",
    }));
  };

  const columns = useMemo<ColumnDef<RequestsTableDataType>[]>(
    () => [
      {
        id: "sender",
        header: t("admin:home.requests.table.sender"),
        cell: ({ row }) => (
          <EmployeeCell
            icon={BadgeIcon}
            employeeName={row.original.employee.name}
            employeeEmail={row.original.employee.email}
            additionalData={renderBenefitName(
              row.original.benefitDefinitionName,
              row.original.benefitDefinitionActive,
              t,
            )}
          />
        ),
        accessorFn: (row) => row.employee,
        enableSorting: true,
        size: 50,
      },
      ...(!isXs
        ? [
            {
              id: "date",
              header: t("admin:home.requests.table.date"),
              cell: ({ row }: { row: Row<RequestsTableDataType> }) => (
                <DateCell date={row.original.createdAt} />
              ),
              accessorFn: ({ row }: { row: Row<RequestsTableDataType> }) =>
                row.original.createdAt,
              enableSorting: true,
            },
          ]
        : []),
      {
        id: "status",
        header: t("admin:home.requests.table.status"),
        cell: ({ row }) => <StatusCell status={row.original.status} />,
        accessorFn: (row) => row.status,
        enableSorting: true,
        size: 15,
      },
    ],
    [t, isXs],
  );

  return (
    <Table<RequestsTableDataType>
      data={getTableRows()}
      columns={columns}
      paginationOptions={paginationOptions}
      sortingOptions={sortingOptions}
      emptyStateComponent={
        shouldShowNoPendingRequestsTableEmptyState ? (
          <TableEmptyState
            icon={<KeyBox3DIcon size="md" />}
            title={t(
              "admin:home.requests.table.noPendingRequestsEmptyState.title",
            )}
            subtitle={t(
              "admin:home.requests.table.noPendingRequestsEmptyState.subtitle",
            )}
          />
        ) : (
          <TableEmptyState
            icon={<Lens3DIcon size="md" />}
            title={t("admin:home.requests.table.emptyState.title")}
            subtitle={t("admin:home.requests.table.emptyState.subtitle")}
          />
        )
      }
      onRowClick={onRowClick}
    />
  );
}
