/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const EmployeeBenefitActivationStatus = {
  Approved: "approved",
  Revoked: "revoked",
  NoActivation: "no_activation",
} as const;
export type EmployeeBenefitActivationStatus =
  (typeof EmployeeBenefitActivationStatus)[keyof typeof EmployeeBenefitActivationStatus];

export function EmployeeBenefitActivationStatusFromJSON(
  json: any,
): EmployeeBenefitActivationStatus {
  return EmployeeBenefitActivationStatusFromJSONTyped(json, false);
}

export function EmployeeBenefitActivationStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EmployeeBenefitActivationStatus {
  return json as EmployeeBenefitActivationStatus;
}

export function EmployeeBenefitActivationStatusToJSON(
  value?: EmployeeBenefitActivationStatus | null,
): any {
  return value as any;
}
